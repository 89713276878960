import React from 'react';
import { FormLayout } from '../../containers';
import { SetPasswordForm } from '../../containers/SetPasswordForm';

import './SetPasswordPage.less';

/**
 * Страница установки пароля.
 */
const SetPasswordPage: React.FC = () => {
    return (
        <FormLayout>
            <div className="qpd-rstr-set-password-page">
                <SetPasswordForm />
            </div>
        </FormLayout>
    );
};

/**
 * Название компонента, используемое при отладке.
 */
SetPasswordPage.displayName = 'SetPasswordPage';

export default SetPasswordPage;
