import axios from './axiosService';
import {
    GetAuthenticationUrlRequestDto,
    GetAuthenticationUrlResponseDto,
    UserConfirmOldIdentitiesRequestDto,
    UserOldDataValidationResultDto,
    UserSignInRequestWithDataCahangeDto,
    UserSignInWithEsiaResponseDto,
} from '../models';
import { RootService } from './rootService';
import { logger } from '../utils';

/**
 * Сервис для работы с ЕСИА.
 */
export class EsiaService {
    /**
     * Корневой Web Api сервис.
     */
    private readonly rootService: RootService;

    /**
     * Конструктор.
     * @param rootService корневой Web Api сервис.
     */
    constructor(rootService: RootService) {
        this.rootService = rootService;
    }

    /**
     * Получить ссылку для аутентификации в ЕСИА.
     * @returns ссылка для аутентификации в ЕСИА.
     */
    public async getAuthenticationUrl(): Promise<GetAuthenticationUrlResponseDto> {
        try {
            return Promise.resolve(
                (await axios.post<GetAuthenticationUrlResponseDto>('esia/get_authentication_url', 
                {},
                { ...this.rootService.suspiciousActivityService.ensureSession() })).data,
            );
        } catch (error) {
            this.rootService.suspiciousActivityService.clearSession();
            logger.error(error);
            return Promise.reject(error);
        }
    }

    /**
     * Проверка старых данных пользователя.
     * @param body тело запроса.
     */
    async verifyOldData(body: UserConfirmOldIdentitiesRequestDto): Promise<UserOldDataValidationResultDto> {
        try {
            return Promise.resolve((await axios.post<UserOldDataValidationResultDto>('/esia/validate', body)).data);
        } catch (error: any) {
            this.rootService.suspiciousActivityService.clearSession();
            logger.error(error);
            return Promise.reject(error);
        }
    }

    /**
     * Подтверждение обновление данных и вход в ССО
     */
    async signInWithDataChagne(body: UserSignInRequestWithDataCahangeDto): Promise<void> {
        try {
            const response = await axios.post<UserSignInWithEsiaResponseDto>('/esia/sign_in_with_updated_data', 
                body,
                { ...this.rootService.suspiciousActivityService.ensureSession() }
            );

            const data = response.data;
            if ('accessToken' in data) {
                this.rootService.tokenService.setTokens(data.accessToken, data.refreshToken);
            }
            return Promise.resolve();
        } catch (error: any) {
            this.rootService.suspiciousActivityService.clearSession();
            logger.error(error);
            return Promise.reject(error);
        }
    }
}
