import { makeAutoObservable, runInAction } from 'mobx';

export class LoadingStore {
    private _loading: boolean = false;

    public get loading(): boolean {
        return this._loading;
    }

    public setLoading = (itsError: boolean) => {
        runInAction(() => {
            this._loading = itsError;
        });
    };

    constructor() {
        makeAutoObservable(this);
    }
}
