import React, { useEffect } from 'react';
import { useStore } from '../../hooks';
import { useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import { Button, Card, Input, notification } from '@qpd-reestr/ui-kit';
import { DocumentInput } from '@qpd-reestr/passport-input';
import useStyle from './ConfirmIdentityFormStyle';
import { IConfirmIdentityValues } from './IConfirmIdentityValues';
import { getValidationMessage, validationSchema } from './ConfirmIdentotyForm.validation';
import { services } from '../../services';

/**
 * Форма для подтверждения предыдущих данных пользователя.
 */
const ConfirmIdentityForm: React.FC = () => {
    const style = useStyle();
    const store = useStore();
    const navigate = useNavigate();

    useEffect(() => {
        if (!store.confirmIdentityStore.userId) {
            services.suspiciousActivityService.clearSession();
            navigate('/');
        }
    }, [navigate, store.confirmIdentityStore.userId]);

    const formik = useFormik<IConfirmIdentityValues>({
        initialValues: {
            lastName: '',
            document: { value: '', isPassport: true },
        },
        onSubmit: (values) => {
            store.confirmIdentityStore.onSubmitHandler(values as IConfirmIdentityValues);
        },
        validateOnChange: false,
        validationSchema,
    });

    /**
     * Обработчик кнопки "Назад"
     */
    const onCancelHandler = () => {
        services.suspiciousActivityService.clearSession();
        store.confirmIdentityStore.clear();
        navigate('/');
    };

    /**
     * Обработчик кнопки "Подтвердить"
     */
    const onSubmitHandler: React.FormEventHandler<HTMLFormElement> = async (event) => {
        event.preventDefault();

        await formik.validateForm(formik.values).then((errors) => {
            const message = getValidationMessage(errors);
            if (message) {
                notification.error(message);
                return;
            }
            formik.handleSubmit(event);
        });
    };

    /**
     * Обработчик изменения данных паспорта на форме
     * @param value Серия и номер паспорта
     * @param isPassport Флаг указывающий тип документа.
     */
    const handleChangeDocument = (value: string, isPassport: boolean) => {
        formik.setFieldValue('document', { value, isPassport });
    };

    /**
     * Обработчик изменения фамилии на форме
     */
    const handleChangeLastName: React.ChangeEventHandler<HTMLInputElement> = (event) => {
        formik.setFieldValue('lastName', event.target.value?.trim() ?? '');
    };

    return (
        <Card className={style.confirm_identity_form_container}>
            <div className={style.confirm_identity_form_text}>
                Кажется, мы нашли Вас, если у Вас изменились персональные данные, то для внесения изменений необходимо
                указать старые данные
            </div>
            <form className={style.confirm_identity_form} onSubmit={onSubmitHandler} noValidate>
                <Input
                    name="lastName"
                    placeholder="Фамилия"
                    onChange={handleChangeLastName}
                    value={formik.values.lastName}
                />

                <DocumentInput
                    className={style.confirm_identity_form__input}
                    name="document"
                    placeholder="Серия и номер паспорта"
                    showIcon={true}
                    onChange={handleChangeDocument}
                    value={formik.values.document}
                    AllowOnlyPassport={true}
                />

                <Button
                    className={style.confirm_identity_form__submit}
                    behaviour="submit"
                    fullWidth
                    text="Подтвердить"
                    type="primary"
                    uppercase
                />

                <Button
                    className={style.confirm_identity_form__cancel}
                    behaviour="button"
                    text="Назад"
                    type="link"
                    fullWidth
                    onClick={onCancelHandler}
                />
            </form>
        </Card>
    );
};

/**
 * Название компонента, используемое при отладке.
 */
ConfirmIdentityForm.displayName = 'ConfirmIdentityForm';

export default ConfirmIdentityForm;
