import axios from './axiosService';
import { SendMailConfirmationRequestDto } from '../models';
import { RootService } from './rootService';
import { logger } from '../utils';

/**
 * Сервис, отвечающий за подтверждение электронной почты пользователя.
 */
export class MailConfirmationService {
    /**
     * Корневой Web Api сервис.
     */
    rootService: RootService;

    /**
     * Конструктор.
     * @param rootService корневой Web Api сервис.
     */
    constructor(rootService: RootService) {
        this.rootService = rootService;
    }

    /**
     * Отправить письмо со ссылкой для подтверждения адреса эл. почты.
     * @param body тело запроса.
     */
    public async sendMailConfirmation(body: SendMailConfirmationRequestDto): Promise<void> {
        try {
            const response = await axios.post<void>('confirmmail/send', body);

            return Promise.resolve(response.data);
        } catch (error: any) {
            logger.error(error);
            return Promise.reject(error);
        }
    }
}
