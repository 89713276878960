import React, { useEffect, useState } from "react";
import { SuspiciousActivityForm, SuspiciousActivityInfo } from "@qpd-reestr/suspicious-activity-form";
import { Question } from "../../models/dto/profile";
import { useServices, useStore } from "../../hooks";
import { useNavigate } from "react-router-dom";
import { notification } from "@qpd-reestr/ui-kit";
import { ErrorCode, VerifactionStatus } from "../../models";
import { showErrorNotification } from "../../utils";

/**
 * Форма подозрительной активности
 */
const SuspiciousActivityFormContainer: React.FC = () => {
    //контрольные вопросы.
    const [controlQuestions, setControlQuestions] = useState<null | Question[]>(null);
    //количество оставшихся попыток
    const [attemptNumber, setAttemptNumber] = useState<number>(3);
    const navigate = useNavigate();
    const { suspiciousActivityService, authorizationService, tokenService, externalSystemAuthorizationHandlerService} = useServices();
    const { verificationFormStore, authorizationFormStore, userStore } = useStore();

    // получение контрольных вопросов
    useEffect(() => {
      (async () => {
        try {
          const { controlQuestions, retries } = await suspiciousActivityService.getControlQuestions();
          setControlQuestions(controlQuestions);
          setAttemptNumber(retries);
        } catch (error: any) {
          showErrorNotification(error);
        }
      })();
    }, []);

    //отправка формы подозрительной активности
    const onSubmitSuspiciousActivitForm = async (formData: Partial<SuspiciousActivityInfo>) => {
      try {
        const { retries, verificationRequestId } = await suspiciousActivityService.sendControlAnswers(formData);
        // если пользователь правильно ответил на вопросы 
        if (verificationRequestId) {
          verificationFormStore.verificationRequestId = verificationRequestId;
          verificationFormStore.action = VerifactionStatus.suspiciousActivityAuthorization;
          verificationFormStore.onSubmitHandler = async (verificationCode: string) => {
            try {
              await authorizationService.signInSuspiciousUser({
                  verificationRequestId: verificationFormStore.verificationRequestId,
                  verificationCode,
              });
              authorizationFormStore.clear();
              verificationFormStore.clear();

              userStore.isAuthorized = true;

              if(externalSystemAuthorizationHandlerService.redirectUri){
                (window as Window).location = externalSystemAuthorizationHandlerService.redirectUri;
              }
            } catch (error: any) {
              showErrorNotification(error);

              if (error.code === ErrorCode.UserNotFound) {
                authorizationFormStore.clear();
                verificationFormStore.clear();
                tokenService.removeTokens();
                navigate('/');
              }
            }
          };
          verificationFormStore.onSendCodeHandler = async () => {
            try {
              const { verificationRequestId } = await suspiciousActivityService.sendControlAnswers(formData);
              if (verificationRequestId) verificationFormStore.verificationRequestId = verificationRequestId;
            } catch (error: any) {
              showErrorNotification(error);
            }
          }

          verificationFormStore.onBackHandler = () => navigate(-1);   
          navigate('/suspicious-activity/verification');
          return
        }
        notification.error('Были введены неправильные данные');
        setAttemptNumber(retries);
      } catch(error: any) {
          // если все попытки исчерпаны 
          if (error.code === ErrorCode.UserIsNotActive) {
            tokenService.removeTokens();
            navigate('/');
          }
          showErrorNotification(error);
      }
    };

    if (!controlQuestions) return null;

    return (
          <SuspiciousActivityForm 
            controlQuestions={controlQuestions} 
            attemptNumber={attemptNumber} 
            onSubmit={onSubmitSuspiciousActivitForm}
          />
    )
}

export default SuspiciousActivityFormContainer;