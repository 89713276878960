import { forEach } from 'lodash';
import {getMessageByCode} from './ErrorUtils';

/**
 * Проверка версии сборки
 */
const isDev = () => 
    process.env.REACT_APP_BUILDING_MODE === 'development';

/**
 * Логер, позволяющий писать логи в консоль
 * в development версии сборки, методы log и error
 * принимают первым параметром сообщение любого типа, вторым (опционально)
 * флаг, указывающий необходимость логирования всегда (и в development, и в production)
 */
export const logger = {    
    log: (message: any, showAlways?: boolean) => {
        if (isDev() || showAlways) {
            console.log(message);
        }
    },
    error: (message: any, showAlways?: boolean) => {
        if (isDev() || showAlways) {
            if (!Array.isArray(message) && message.code){
                console.error('Сообщение: ' + getMessageByCode(message.code), '\nКод: ' + message.code);
            }else{
                for(let i=0; i<message.length; i++){
                    console.error('Сообщение: ' + getMessageByCode(message[i].code), '\nКод: ' + message[i].code);
                }
            }
            
        }
    }
};
