import { PropsWithChildren, useEffect } from "react";
import { useStore } from "../../../hooks/useStore";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useServices } from "../../../hooks";

/**
 * Обертка для неавторизованного перехода из ЛКА в ССО.
 */
const UnathorizedRedirectDecorator: React.FC<PropsWithChildren> = (props) => {
    const { userStore } = useStore();
    const { externalSystemAuthorizationHandlerService } = useServices();
    const [queryParams] = useSearchParams();
    const navigate = useNavigate();

    useEffect(() => {
        let returnUrl = queryParams.get("returnUrl") ?? "";

        // почему-то используя URLSearchParams напрямую не видит первый параметр.
        var searchParams = (new URL(returnUrl)).searchParams;

        let redirectUri = searchParams.get('redirect_uri') ?? "";

        if (redirectUri && searchParams) {
            externalSystemAuthorizationHandlerService.redirectUri = redirectUri;
            externalSystemAuthorizationHandlerService.redirectParams = searchParams;
        }
        // Если не авторизован.
        if (!userStore.isAuthorized) {
            navigate('/')
        }
    }, [])

    if (!userStore.isAuthorized) return null

    // Отдаем все вложенные роуты.
    return <>{props.children}</>;
}

/**
 * Название компонента, используемое при отладке.
 */
UnathorizedRedirectDecorator.displayName = 'UnathorizedRedirectDecorator';

export default UnathorizedRedirectDecorator;