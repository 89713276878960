import React from 'react';
import { FormLayout, SberIdAuthorizationCallbackHandler } from '../../containers';

/**
 * Страница "Авторизация через Сбер ID".
 */
const SberIdAuthorizationCallbackHandlerPage: React.FC = () => {
    return (
        <FormLayout>
            <SberIdAuthorizationCallbackHandler />
        </FormLayout>
    );
};

/**
 * Название компонента, используемое при отладке.
 */
SberIdAuthorizationCallbackHandlerPage.displayName = 'SberIdAuthorizationCallbackHandlerPage';

export default SberIdAuthorizationCallbackHandlerPage;
