import { CadesPluginUtils, CertificateInfo } from "@qpd-reestr/certificates-utils";
import { loadingNotification, notification } from "@qpd-reestr/ui-kit";
import React, { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { UkepAuthorizationForm as AuthorizationForm } from "../../components";
import { useStore } from "../../hooks";
import { ErrorCode, UkapUserRegistrationRouterModel, VerifactionStatus } from "../../models";
import { services } from "../../services";
import { logger, showErrorNotification } from "../../utils";
import { showErrorNotificationWithFallBack } from "../../utils/ErrorUtils";

/**
 * Дефолтное сообщение для ошибки входа по УКЭП
 */
const defaultErrorMessage
        = 'К сожалению, не удалось войти в систему по УКЭП, повторите попытку позднее. В случае повторной ошибки обратитесь к администратору.';

/**
 * Форма авторизации по УКЭП
 */
const UkepAuthorizationForm: React.FC = () => {
    const [certificates, setCertificates] = useState<CertificateInfo[]>([]);
    const [selectedCertificate, setSelectedCertificate] = useState<CertificateInfo>();
    const [ukepSetupLink, setUkepSetupLink] = useState<string>("#");
    const store = useStore();
    const navigate = useNavigate();

    /**
     * Экземпляр класса утилит для работы с сертификатами
     */
    const certificateUtils = useMemo(() => {
        //@ts-ignore
        window.cadespluginStart()

        return new CadesPluginUtils();
    }, []);

    /**
     * Эффект для получения списка сертификатов.
     */
     useEffect(() => {
        (async () => {
            certificateUtils.getAllValidCertificatesInfo().then(c=>{
                setCertificates(c.sort((a,b) => a.subjectNameFriendly > b.subjectNameFriendly
                                    ? 1 : a.subjectNameFriendly < b.subjectNameFriendly
                                        ? -1 : 0));
            }).catch((error)=>{
                logger.error(error);
                notification.error('Не удалось получить список сертификатов.');
            });
        })();
    }, [certificateUtils]);


    /**
     * Эффект подгрузки ссылки на настройку УКЭП
     */
    useEffect(() => {
        (async () => {
            try {
                const link = await services.commonService.getUkepSetupLink();
                setUkepSetupLink(link);
            } 
            catch (error: any) {
                logger.error(error);
                showErrorNotification(error);
            }
        })();
    }, []);

    /**
     * Обработчик смены сертификата в селекте.
     */
    const selectionChangedHandler = (serialNumber: string) => {
        const selectedCertificate = certificates.find(c=>c.serialNumber === serialNumber);
        if(selectedCertificate) {
            setSelectedCertificate(selectedCertificate);
        }
    };

    /**
     * Метод парсинга Ф.И.О.
     * @param fullName строка, содержащая Ф.И.О.
     * @returns объект с разбивкой Ф.И.О. по полям
     */
    const getUserNameParts = (fullName?: string) => {
        if(!fullName) {
            return null;
        }
        const [lastName, firstName, middleName] = fullName.split(' ');
        return {
            lastName,
            firstName,
            middleName
        };
    }

    /**
     * Метод получения модели данных регистрации пользователя
     * @returns объект UkapUserRegistrationRouterModel
     */
    const getUkapUserRegistrationRouterModel = () => {
        if(!selectedCertificate) {
            return null;
        }

        const fullName = getUserNameParts(selectedCertificate!.subjectFullName);
        return {
            email: selectedCertificate.subjectEmail ?? '',
            firstName: fullName?.firstName ?? '',
            inn: selectedCertificate.subjectInn ?? '',
            snils: selectedCertificate.subjectSnils ?? '',
            lastName: fullName?.lastName ?? '',
            middleName: fullName?.middleName ?? '',
            ukapSerialNumber: selectedCertificate.serialNumber,
        } as UkapUserRegistrationRouterModel;
    }

    /**
     * Функция, создающая объект loader
     * @param message - сообщение в loader'е
     * @returns Объект loader с методами start и stop
     */
    const loaderCreator = (message?: string) => {
        let loadingNotifyId: number | string | null = null;
        return {
            start: () => {
                store.overlayStore.setShowing(true);
                loadingNotifyId = loadingNotification.showLoadingNotification(message
                                    ?? "Пожалуйста, не перезагружайте страницу до окончания запроса");
            },
            stop: () => {
                if(loadingNotifyId) {
                    loadingNotification.closeLoadingNotification(loadingNotifyId);
                }
                store.overlayStore.setShowing(false);
            }
        };
    };

    /**
     * Метод обработки серверных ошибок авторизации
     * @param error - объект ошибки
     */
    const handleServerError = (error: any) => {
        switch(error.code) {
            case ErrorCode.UserNotFound:
                const model = getUkapUserRegistrationRouterModel();
                if(model) {
                    store.registrationFormStore.lastName = model.lastName;
                    store.registrationFormStore.firstName = model.firstName;
                    store.registrationFormStore.middleName = model.middleName;
                    store.registrationFormStore.inn = model.inn;
                    store.registrationFormStore.snils = model.snils;
                    store.registrationFormStore.email = model.email;
                    store.registrationFormStore.subjectId = model.ukapSerialNumber;

                    // Выставляем флаги блокировки полей
                    store.registrationFormStore.firstNameDisabled = !!model.firstName;
                    store.registrationFormStore.middleNameDisabled = !!model.middleName;
                    store.registrationFormStore.lastNameDisabled = !!model.lastName;
                    store.registrationFormStore.innDisabled = !!model.inn;
                    store.registrationFormStore.snilsDisabled = !!model.snils;
                }
                store.registrationFormStore.authType = "UKAP";
                navigate('../registration');
                break;
            case ErrorCode.UserFoundByFioOnly:
                navigate('../ukep/sign-in-error', {state: getUkapUserRegistrationRouterModel()});
                break;
            case ErrorCode.CertificateServiceError:
                notification.error(defaultErrorMessage);
                break;
            default:
                logger.error(error);
                showErrorNotificationWithFallBack(error, defaultErrorMessage);
                break;
        }
    }

    /**
     * Обработчик кнопки "Войти".
     */
    const onSubmitHandler = () => {
        if(selectedCertificate) {
            (async () => {
                const loader = loaderCreator();

                try {
                    loader.start();
                    // получение уникльного доверительного ключа
                    let trustKey = await services.authorizationService.getTrustKey();

                    if (!trustKey) {
                        notification.error('Не удалось подписать доверительный ключ. Пожалуйста, обратитесь к администратору.');
                        return;
                    }

                    store.registrationFormStore.state = trustKey;

                    let message: string | undefined = "";

                    try {
                        message = await certificateUtils.signMessage(selectedCertificate.serialNumber, trustKey);
                    }
                    catch (error) {
                        notification.error('Не удалось подписать доверительный ключ. Пожалуйста, обратитесь к администратору.');
                        return;
                    }

                    const dto = await services.authorizationService.trySignInWithUkep(trustKey, message!);

                    if ('verificationRequestId' in dto) {
                        store.verificationFormStore.action = VerifactionStatus.authorizationUKAP;
                        store.verificationFormStore.verificationRequestId = dto.verificationRequestId;
                        store.verificationFormStore.phone = dto.phone;
                        store.verificationFormStore.onSubmitHandler = async (verificationCode: string) => {
                            try {
                                await services.authorizationService.signInWithUkepVerificationCode({
                                    phone: dto.phone,
                                    verificationRequestId: dto.verificationRequestId,
                                    verificationCode,
                                    state: trustKey,
                                });
                                store.verificationFormStore.clear();
                                store.userStore.isAuthorized = true;
                            }
                            catch(error: any) {
                                logger.error(error);
                                showErrorNotificationWithFallBack(error, defaultErrorMessage);
                            }
                        };
                        store.verificationFormStore.onBackHandler = () => {
                            services.suspiciousActivityService.clearSession();
                            navigate(-1);
                        }
                      
                        navigate('../verification');
                    }

                    if ('accessToken' in dto) {
                        services.tokenService.setTokens(dto.accessToken, dto.refreshToken);
                        store.userStore.isAuthorized = true;
                    } else {
                        store.userStore.isAuthorized = false;
                    }
                } catch(error) {
                    store.userStore.isAuthorized = false;
                    handleServerError(error);
                } finally {
                    loader.stop();
                }
            })();
        }
    };

    /**
     * Обработчик кнопки "Отмена".
     */
    const onCancelHandler = React.useCallback(() => {
        store.registrationFormStore.clear();
        navigate('../');
    }, [navigate]);

    return (
        <AuthorizationForm
            certificates={certificates}
            onSelectionChanged={selectionChangedHandler}
            selectedCertificate={selectedCertificate}
            ukepSettingLink={ukepSetupLink}
            onSubmit={onSubmitHandler}
            onCancel={onCancelHandler}
        />
    )
}

export default UkepAuthorizationForm;
