export enum VerifactionStatus {
    confirmOldPhone = 'Смена номера телефона. Подтверждение старого номера телефона',
    confirmNewPhone = 'Смена номера телефона. Подтверждение нового номера телефона',
    recoveryPassword = 'Восстановление пароля',
    authorization = 'Авторизация с помощью логина и пароля',
    suspiciousActivityAuthorization = 'Авторизация после подозрительной активности',
    registration = 'Регистрация',
    confirmEmail = 'Подтверждение почты',
    authorizationESIA = 'Авторизация с помощью ЕСИА',
    authorizationWithEsiaConfirmOldData = 'Авторизация с помощью ЕСИА. Подтверждение старых данных',
    authorizationUKAP = 'Авторизация с помощью УКЭП',
    authorizationCPG = 'Авторизация с помощью ЦПГ',
    authorizationWithCpgConfirmOldData = 'Авторизация с помощью ЦПГ. Подтверждение старых данных',
    authorizationSberID = 'Авторизация с помощью Сбер ID',
    authorizationWithSberIdConfirmOldData = 'Авторизация с помощью Сбер ID. Подтверждение старых данных',
}

export type ActionTypes = `${VerifactionStatus}`;
