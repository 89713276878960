import { createUseStyles } from 'react-jss';

export default createUseStyles(
    () => ({
        confirm_identity_form_container: {
            maxWidth: '400px',
            padding: '35px 35px',
            display: 'flex',
            flexDirection: 'column',
            gap: '15px'

        },

        confirm_identity_form: {
            paddingLeft: '50px',
            paddingRight: '50px',
            paddingTop: '50px'
        },

        confirm_identity_form__input: {
            marginTop: '25px',
            marginBottom: '20px',
        },

        confirm_identity_form__submit: {
            marginTop: '30px',
        },

        confirm_identity_form__cancel: {
            marginTop: '15px',
        },
        confirm_identity_form_text: {
            color: '#4a4a4a',
            fontFamily: 'Open Sans, sans-serif',
            fontSize: '16px',
            fontWeight: 400,
            textAlign: 'center',
        },

    }),
    { name: 'qpd-rstr-' },
);