import React, { useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useServices, useStore } from '../../hooks';
import { showErrorNotification } from '../../utils';

const SignOutHandler: React.FC = () => {
    const navigate = useNavigate();
    const services = useServices();
    const { userStore } = useStore();

    const [queryParams] = useSearchParams();

    useEffect(() => {

        let clientId = queryParams.get("clientId") ?? "";
        let userId = queryParams.get("userId") ?? "";
        let logoutId = queryParams.get("logoutId") ?? "";

        if (!userStore.isAuthorized) {
            return navigate('/');
        }
     
        (async () => {
            try {
                // Если указан идентификатор выхода, то выходим полностью 
                if(logoutId) {
                    
                    // выходим из всех систем
                    await services.authorizationService.FederalSignOut();

                    // чистим сессию
                    await services.tokenService.removeTokens();

                    // устанавливаем флаг 
                    userStore.isAuthorized = false;
                }
                else {
                    // дергаем сервис для выхода из конкретной системы
                    await services.authorizationService.signOut({clientId, userId});
                }

                return navigate('/');

            } catch (error: any) {  
                showErrorNotification(error);
                navigate('/');
            }
        })();
    }, [
        navigate,
        queryParams,
        userStore.isAuthorized,
    ]);

    return null;
};

/**
 * Название компонента, используемое при отладке.
 */
SignOutHandler.displayName = 'SignOutHandler';

export default SignOutHandler;
