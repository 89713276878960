export enum ErrorCode {
    /**
     * Некорректные данные в запросе.
     */
    InvalidRequestData = 1,

    /**
     * Пользователь не найден.
     */
    UserNotFound = 10,

    /**
     * Пользователь уже существует.
     */
    UserAlreadyExists = 11,

    /**
     * Пользователь не активен.
     */
    UserIsNotActive = 12,

    /**
     * Введен некорректный пароль.
     */
    IncorrentPassword = 13,

    /**
     * Email пустой
     */
    UserEmailIsEmpty = 14,

    /**
     * Несоответствие данных пользователя в ССО с данными в сторонней системе
     */
    UserDataDiscrepancy = 15,

    /**
     *  разрешение на редактирование уже получено
     */
    CorrectionAlreadyAllowed = 17,

    /**
     * Неактивная сессия пользователя.
     */
    UserSessionNotFound = 20,

    /**
     * Пользовательская сессия истекла.
     */
    UserSessionIsExpired = 21,

    /**
     * Не прав на операции.
     */
    UserHasNoRights = 22,

    /**
     * Внешняя система не найдена.
     */
    ExternalSystemNotFound = 30,

    /**
     * Внешняя система не найдена.
     */
    ExternalSystemHasNoRights = 31,

    /**
     * Сессия приложения истекла.
     */
    ExternalSystemSessionIsExpired = 40,

    /**
     * Токен обновления не найден.
     */
    RefreshTokenNotFound = 50,

    /**
     * Истек срок действия токена обновления.
     */
    RefreshTokenIsExpired = 51,

    /**
     * Код верификации истек или имеет некорректное значение.
     */
    VerificationCodeIsInvalid = 70,

    /**
     * Пользователь с таким ИНН уже существует.
     */
    DuplicateInn = 71,

    /**
     * Пользователь с таким СНИЛС уже существует.
     */
    DuplicateSnils = 72,

    /**
     * Пользователь с таким документом уже существует.
     */
    DuplicateDocument = 73,

    /**
     * Пользователь с таким номером телефона уже существует.
     */
    DuplicatePhone = 74,

    /**
     * Не удалось получить действующий паспорт пользователя.
     */
    ActualPassportNotRetrieved = 75,

    /**
     *  Пользователь ранее уже был обезличен
     */
    AlreadyDepersonalized = 76,

    /**
     * Ошибка отправки email
     */
    ErrorEmailSending = 80,

    /**
     * Кука с идентификатором пользователя не найдена или имеет некорректное значение.
     */
    UserIdCookieIsInvalid = 90,

    /**
     * Кука с идентификатором сессии пользователя не найдена или имеет некорректное значение.
     */
    UserSessionIdCookieIsInvalid = 91,

    /**
     * Пользователь найден, но у него нет данных ИНН и СНИЛС.
     */
    UserFoundByFioOnly = 100,

    /**
     * Ошибка валидации подписи
     */
    CertificateServiceError = 101,

    /**
     * Ошибка генерации отчета о согласиях пользователя
     */
    UserConsentReportGenerationError = 112,

    /**
     * Введены неверные логин и пароль.
     */
    IncorrentLoginOrPassword = 113,

    /**
     * Ошибка данных из внешних систем авторизации
     */
    InvalidDataFromExternalSource = 114,

    /**
     * Внутренняя ошибка сервера.
     */
    InternalServerError = 500,

}
