import { createUseStyles } from 'react-jss';

export default createUseStyles(
    () => ({
        profile_page: {
            minWidth: '600px',
        },

        '@media only screen and (max-width: 599px)': {
            profile_page: {
                minWidth: '100%',
            },
        },
    }),
    { name: 'qpd-rstr-' },
);
