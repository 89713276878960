import { createUseStyles } from 'react-jss';

export default createUseStyles(
    () => ({
        form__text_info__old_phone: {
            color: '#e95721',
            fontWeight: 600,
        },
        form__text_info__new_phone: {
            color: '#0f467b',
            fontWeight: 600,
        },
    }),
    { name: 'qpd-rstr-' },
);
