import { ConfirmPassword } from '@qpd-reestr/password-confirmation-form';
import { observer } from 'mobx-react-lite';
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useStore } from '../../hooks';

/**
 * Контейнерный компонент формы установки пароля.
 */
const SetPasswordFormContainer: React.FC = () => {
    const navigate = useNavigate();
    const store = useStore();

    useEffect(() => {
        if (!store.setPasswordFormStore.phone) {
            navigate('/');
        }
    }, [navigate, store.setPasswordFormStore.phone]);

    return <ConfirmPassword onSubmit={store.setPasswordFormStore.onSubmitHandler} />;
};

SetPasswordFormContainer.displayName = 'SetPasswordFormContainer';

export default observer(SetPasswordFormContainer);
