import { createUseStyles } from 'react-jss';

export default createUseStyles(
    () => ({
        profile_menu_sso: {
            padding: '0 !important',
            '& i': {
                color: 'hsla(0,0%,100%,.8)'
            }
        },
        '@media (max-width: 399px)': {
            profile_menu_sso: {
                right: 10,
            },
        },
    }),
    { name: 'qpd-rstr-' },
);
