import axios from './axiosService';
import { OtherAuthorizationType } from '@qpd-reestr/authorization-form';
import {
    CreateUkepVerificationRequestResponseDto,
    CreateVerificationRequestResponseDto,
    UserSignInRequestDto,
    UserSignInResponseDto,
    UserSignInWithEsiaRequestDto,
    UserSignInWithEsiaResponseDto,
    UserSignInWithUkepRequestDto,
    UserSignInWithUkepResponseDto,
    UserSignInWithUkepVerificationRequestDto,
    UserSignInWithVerificationRequestDto,
    UserSignInWithVerificationResponseDto,
    UserSignInWithCpgRequestDto,
    UserSignInWithCpgResponseDto,
    UserSignInWithSuspiciousActivityDto,
    ErrorCode,
} from '../models';
import { RootService } from './rootService';
import { logger } from '../utils';

/**
 * Сервис авторизации.
 */
export class AuthorizationService {
    /**
     * Корневой Web Api сервис.
     */
    private readonly rootService: RootService;

    /**
     * Конструктор.
     * @param root корневой Web Api сервис.
     */
    constructor(root: RootService) {
        this.rootService = root;
    }

    /**
     * Авторизация пользователя.
     * @param body тело запроса.
     */
    public async signIn(
        body: UserSignInRequestDto,
    ): Promise<CreateVerificationRequestResponseDto | UserSignInResponseDto> {
        try {
            const response = await axios.post<CreateVerificationRequestResponseDto | UserSignInResponseDto>(
                'authorization/user/sign_in',
                body,
                { ...this.rootService.suspiciousActivityService.ensureSession() }
            );

            const data = response.data;
            if ('accessToken' in data) {
                this.rootService.tokenService.setTokens(data.accessToken, data.refreshToken);
            }

            return Promise.resolve(data);
        } catch (error: any) {
            this.rootService.suspiciousActivityService.clearSession();
            logger.error(error);
            return Promise.reject(error);
        }
    }

    /**
     * Авторизация пользователя с учетом второго фактора.
     * @param body тело запроса.
     */
    async signInWithVerificationCode(body: UserSignInWithVerificationRequestDto): Promise<void> {
        try {
            const response = await axios.post<UserSignInWithVerificationResponseDto>(
                'authorization/user/sign_in_with_verification_code',
                body,
                { ...this.rootService.suspiciousActivityService.ensureSession() }
            );

            const data = response.data;
            if ('accessToken' in data) {
                this.rootService.tokenService.setTokens(data.accessToken, data.refreshToken);
            }

            return Promise.resolve();
        } catch (error: any) {
            if (error.code !== ErrorCode.VerificationCodeIsInvalid) {
                this.rootService.suspiciousActivityService.clearSession();
            }
            logger.error(error);
            return Promise.reject(error);
        }
    }

    /**
     * Авторизация подозрителього пользователя.
     * @param body тело запроса.
     */
    async signInSuspiciousUser(body: UserSignInWithSuspiciousActivityDto): Promise<void> {
        try {
            const response = await axios.post<UserSignInWithVerificationResponseDto>(
                'authorization/user/sign_in_with_confirmation_token',
                body,
                { ...this.rootService.suspiciousActivityService.ensureSession() }
            );

            const data = response.data;
            if ('accessToken' in data) {
                this.rootService.tokenService.setTokens(data.accessToken, data.refreshToken);
            }

            return Promise.resolve();
        } catch (error: any) {
            if (error.code !== ErrorCode.VerificationCodeIsInvalid) {
                this.rootService.suspiciousActivityService.clearSession();
            }
            logger.error(error);
            return Promise.reject(error);
        }
    }
    /**
     * Авторизация пользователя через ЕСИА.
     * @param body тело запроса.
     */
    async signInWithEsia(body: UserSignInWithEsiaRequestDto): Promise<void> {
        try {
            const response = await axios.post<UserSignInWithEsiaResponseDto>(
                'authorization/user/sign_in_with_esia',
                body,
                { ...this.rootService.suspiciousActivityService.ensureSession() }
            );

            const data = response.data;
            if ('accessToken' in data) {
                this.rootService.tokenService.setTokens(data.accessToken, data.refreshToken);
            }

            return Promise.resolve();
        } catch (error: any) {
            this.rootService.suspiciousActivityService.clearSession();
            logger.error(error);
            return Promise.reject(error);
        }
    }

    /**
     * Авторизация пользователя через ЦПГ.
     * @param body тело запроса.
     */
    async signInWithCpg(body: UserSignInWithCpgRequestDto): Promise<void> {
        try {
            const response = await axios.post<UserSignInWithCpgResponseDto>(
                'authorization/user/sign_in_with_cpg',
                body,
                { ...this.rootService.suspiciousActivityService.ensureSession() }
            );

            const data = response.data;
            if ('accessToken' in data) {
                this.rootService.tokenService.setTokens(data.accessToken, data.refreshToken);
            }

            return Promise.resolve();
        } catch (error: any) {
            this.rootService.suspiciousActivityService.clearSession();
            logger.error(error);
            return Promise.reject(error);
        }
    }

    /**
     * Выход пользователя из системы.
     */
    async FederalSignOut(): Promise<void> {
        try {
            this.rootService.suspiciousActivityService.clearSession();

            await axios.post<void>('authorization/user/federal_sign_out');
        } catch (error: any) {
            logger.error(error);
        }
    }

    /**
     * Выход пользователя из системы.
     */
    async signOut({ clientId, userId }: { clientId: string; userId: string }): Promise<void> {
        try {
            this.rootService.suspiciousActivityService.clearSession();

            await axios.post<void>('authorization/user/sign_out', { clientId, userId });
        } catch (error: any) {
            logger.error(error);
        }
    }

    /**
     * Получение доступных методов авторизации.
     */
    async getAuthorizeMethods(): Promise<OtherAuthorizationType[]> {
        try {
            const response = await axios.get<OtherAuthorizationType[]>(`/authorization/user/get_authorize_methods`);
            const data = response.data;
            return Promise.resolve(data);
        } catch (error: any) {
            logger.error(error);
            return Promise.reject(error);
        }
    }

    /**
     * Получение уникального доверительного ключа
     */
    public async getTrustKey(): Promise<string> {
        try {
            var result = await axios.get<string>('ukep/get_trust_key');
            return result.data;
        } catch (error: any) {
            logger.error(error);
            return Promise.reject(error);
        }
    }

    /**
     * Аутентификация с помощью УКЭП. Метод осуществляет попытку аутентификации
     * с учетом однофакторной, или двухфакторной аутентификации
     */
    public async trySignInWithUkep(
        state: string,
        signedState: string,
    ): Promise<CreateUkepVerificationRequestResponseDto | UserSignInWithUkepResponseDto> {
        try {
            const body: UserSignInWithUkepRequestDto = {
                state,
                signedState,
            };
            const response = await axios.post<CreateUkepVerificationRequestResponseDto | UserSignInWithUkepResponseDto>(
                'authorization/user/try_sign_in_with_ukep',
                body,
                { ...this.rootService.suspiciousActivityService.ensureSession() }
            );

            return Promise.resolve(response.data);
        } catch (error: any) {
            this.rootService.suspiciousActivityService.clearSession();
            logger.error(error);
            return Promise.reject(error);
        }
    }

    /**
     * Верификация входа по УКЭП (второй фактор)
     */
    public async signInWithUkepVerificationCode(body: UserSignInWithUkepVerificationRequestDto): Promise<void> {
        try {
            const response = await axios.post<UserSignInWithUkepResponseDto>(
                'authorization/user/sign_in_with_ukep_verification_code',
                body,
                { ...this.rootService.suspiciousActivityService.ensureSession() }
            );

            const data = response.data;
            if ('accessToken' in data) {
                this.rootService.tokenService.setTokens(data.accessToken, data.refreshToken);
            }
            return Promise.resolve();
        } catch (error: any) {
            if (error.code !== ErrorCode.VerificationCodeIsInvalid) {
                this.rootService.suspiciousActivityService.clearSession();
            }
            logger.error(error);
            return Promise.reject(error);
        }
    }

    /**
     * Получение списка доверенных систем.
     */
    async getTrustedAuthSystems(): Promise<Array<string>> {
        try {
            const resposne = await axios.get<Array<string>>('authorization/user/get_trusted_auth_systems');
            const data = resposne.data;
            return Promise.resolve(data);
        } catch (error: any) {
            logger.error(error);
            return Promise.reject(error);
        }
    }
}
