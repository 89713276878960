import './Footer.less';

/**
 * Компонент подвала
 */
const Footer: React.FC = () => {
    return (
        <div className="qpd-rstr-footer">
            <p>+7 (495) 617-01-01 доб. 7770, online@aoreestr.ru</p>
            <a href={process.env.REACT_APP_REESTR_SSO_TERMS_OF_USE}>Правила использования</a>
        </div>
    );
};

export default Footer;
