import React from 'react';
import { Button, Card, Select } from '@qpd-reestr/ui-kit';
import { CertificateInfo } from './CertificateInfo';
import { UkepAuthorizationFormProps } from './UkepAuthorizationFormProps';
import './UkepAuthorizationForm.less';

/**
 * Компонент авторизации по УКЭП
 */
const UkepAuthorizationForm: React.FC<UkepAuthorizationFormProps> = (props) => {
    return (
        <Card className="qpd-rstr-ukep-auth-form-container">
            <div className="qpd-rstr-ukep-auth-form">
                <h1 className="qpd-rstr-ukep-auth-form__title">Авторизация с помощью УКЭП</h1>
                <a href={props.ukepSettingLink ?? '#'} rel="noreferrer" target="_blank">
                    Ключевые шаги настройки УКЭП
                </a>
                <div className="qpd-rstr-ukep-auth-form__select">
                    <Select
                        allowClear
                        onChange={props.onSelectionChanged}
                        options={props.certificates?.map((c) => ({ label: c.subjectNameFriendly, value: c.serialNumber }))}
                        placeholder="Выберите сертификат"
                        value={props.selectedCertificate?.serialNumber}
                    />
                    <CertificateInfo certificate={props.selectedCertificate}/>
                </div>
                <Button
                    behaviour="button"
                    className="qpd-rstr-ukep-auth-form__btn_submit"
                    fullWidth
                    text="Войти"
                    type="primary"
                    uppercase
                    disabled={!props.selectedCertificate}
                    onClick={props.onSubmit}
                />
                <Button
                    behaviour="button"
                    className="qpd-rstr-ukep-auth-form__btn_cancel"
                    fullWidth
                    text="Отмена"
                    type="link"
                    onClick={props.onCancel}
                />
            </div>
        </Card>
    );
};

export default UkepAuthorizationForm;
