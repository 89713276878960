import React from 'react';
import { Footer } from '../../components';
import { FormLayout as Layout } from '@qpd-reestr/layout';
import { FormLayoutProps } from './FormLayoutProps';
import { ProfileMenu } from '../ProfileMenu';
import { observer } from 'mobx-react-lite';
import { useStore } from '../../hooks';
import { useNavigate } from 'react-router-dom';

/**
 * Компонент Layout для форм.
 */
const FormLayout: React.FC<FormLayoutProps> = props => {
    const { loaderStore, userStore, overlayStore } = useStore();
    const navigate = useNavigate();

    /**
     * Компонент профиля пользователя.
     */
    const profileMenu = userStore.isAuthorized && !userStore.isSuspiciousActivity ? <ProfileMenu /> : undefined;

    return (
        <Layout footer={<Footer />}
            isLoading={loaderStore.loading}
            onLogoClick={() => navigate('/')}
            overlayIsShowing={overlayStore.isShowing}
            profileMenu={profileMenu}
        >
            {props.children}
        </Layout>
    );
};

/**
 * Название компонента, отображаемое при отладке.
 */
 FormLayout.displayName = 'FormLayout';

export default observer(FormLayout);
