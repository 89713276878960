import React, { useCallback } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { ChangePasswordForm } from '@qpd-reestr/change-password-form';
import { useServices } from '../../hooks';
import { showErrorNotification } from '../../utils';

/**
 * Контейнерный компонент "Форма изменения пароля".
 */
const ChangePasswordFormContainer: React.FC = () => {
    const navigate = useNavigate();
    const services = useServices();

    const [queryParams] = useSearchParams();

    /**
     * Обработчик события сабмита формы
     */
    const onSubmitHandler = useCallback(
        async (currentPassword: string, newPassword: string) => {
            try {
                await services.userService.editPassword(currentPassword, newPassword);

                const redirectUrl = queryParams.get('redirectUrl');

                // Если нет урла для редиректа, переходим на главную SSO
                if (!redirectUrl) {
                    return navigate('/');
                }

                const decodedRedirectUrl = services.base64.decodeBase64ToURIString(redirectUrl);
                window.location.assign(decodedRedirectUrl);
            } catch (error: any) {
                showErrorNotification(error);
            }
        },
        [navigate, queryParams, services.base64],
    );

    const onBackClick = () => {
        navigate(-1);
    };

    return <ChangePasswordForm onSubmit={onSubmitHandler} onBackClick={onBackClick} />;
};

/**
 * Название компонента, используемое при отладке.
 */
ChangePasswordFormContainer.displayName = 'ChangePasswordFormContainer';

export default ChangePasswordFormContainer;
