import React, { useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useServices, useStore } from '../../hooks';
import { showErrorNotification } from '../../utils';
import { IConfirmIdentityValues } from '../ConfirmIdentityForm/IConfirmIdentityValues';
import { VerifactionStatus } from '../../models';
/**
 * Контейнерный компонент "Обработчик входа ЦПГ".
 */
const CpgAuthorizationCallbackHandlerContainer: React.FC = () => {
    const navigate = useNavigate();
    const services = useServices();
    const store = useStore();

    const [queryParams, setQueryParams] = useSearchParams();

    useEffect(() => {
        const redirectParamsQuery = queryParams.get('redirectParams');
        if (!redirectParamsQuery) {            
            services.suspiciousActivityService.clearSession();
            return navigate('/');
        }

        let redirectParams = services.base64.decodeRedirectParams(redirectParamsQuery);

        switch (redirectParams.action) {
            case 'authentication':
                services.tokenService.setTokens(redirectParams.access_token, redirectParams.refresh_token);
                store.userStore.isAuthorized = true;
                return navigate('/');
            case 'error':
                services.suspiciousActivityService.clearSession();
                let errorCode = Number(redirectParams.errorCode);
                showErrorNotification({ code: errorCode }, redirectParams.correlationId);
                store.userStore.isAuthorized = false;
                return navigate('/');
            case 'registration':
                store.registrationFormStore.subjectId = redirectParams.oid;
                // Признак подтвержденной уз
                store.registrationFormStore.trusted = Boolean(redirectParams.trusted);
                store.registrationFormStore.firstName = redirectParams.first_name;
                store.registrationFormStore.lastName = redirectParams.last_name;
                store.registrationFormStore.middleName = redirectParams.middle_name;
                store.registrationFormStore.phone = redirectParams.phone;
                store.registrationFormStore.inn = redirectParams.inn;
                store.registrationFormStore.snils = redirectParams.snils;
                store.registrationFormStore.email = redirectParams.email;
                store.registrationFormStore.state = redirectParams.state;
                store.registrationFormStore.passportVerification = redirectParams.passport_verification;
                store.registrationFormStore.authType = 'CPG';

                // Из ЦПГ ID может прийти только паспорт РФ
                store.registrationFormStore.document = {
                    isPassport: true,
                    value: redirectParams.passport,
                };

                // Выставляем флаги блокировки полей
                store.registrationFormStore.firstNameDisabled =
                    store.registrationFormStore.trusted && !!redirectParams.first_name;
                store.registrationFormStore.lastNameDisabled =
                    store.registrationFormStore.trusted && !!redirectParams.last_name;
                store.registrationFormStore.middleNameDisabled =
                    store.registrationFormStore.trusted && !!redirectParams.middle_name;
                store.registrationFormStore.passportDisabled =
                    store.registrationFormStore.trusted && !!redirectParams.passport;
                store.registrationFormStore.innDisabled = store.registrationFormStore.trusted && !!redirectParams.inn;
                store.registrationFormStore.snilsDisabled =
                    store.registrationFormStore.trusted && !!redirectParams.snils;

                return navigate('/registration');
            case 'verification':
                store.verificationFormStore.action = VerifactionStatus.authorizationCPG;
                store.verificationFormStore.phone = redirectParams.phone;
                store.verificationFormStore.verificationRequestId = redirectParams.verification_request_id;
                store.verificationFormStore.onSubmitHandler = async (verificationCode: string) => {
                    try {
                        await services.authorizationService.signInWithCpg({
                            phone: redirectParams.phone,
                            state: redirectParams.state,
                            verificationRequestId: store.verificationFormStore.verificationRequestId,
                            verificationCode,
                        });

                        store.userStore.isAuthorized = true;
                    } catch (error: any) {
                        showErrorNotification(error);
                        store.userStore.isAuthorized = false;
                        return navigate('/');
                    }
                };
                store.verificationFormStore.onBackHandler = () => {
                    services.suspiciousActivityService.clearSession();
                    navigate('/');
                }

                return navigate('/verification');
            case 'confirm_outdated_info':
                store.confirmIdentityStore.userId = redirectParams.user_id;
                store.confirmIdentityStore.onSubmitHandler = async (values: IConfirmIdentityValues) => {
                    try {
                        const response = await services.cpgService.verifyOldData({
                            UserId: redirectParams.user_id,
                            State: redirectParams.state,
                            OutdatedUser: { LastName: values.lastName, Document: values.document.value },
                        });

                        store.verificationFormStore.action = VerifactionStatus.authorizationWithCpgConfirmOldData;
                        store.verificationFormStore.verificationRequestId = response.verificationRequestId;
                        store.verificationFormStore.phone = response.phoneNumber
                        store.verificationFormStore.onSubmitHandler = async (verificationCode: string) => {
                            try {
                                await services.cpgService.signInWithDataChagne({
                                    userId: redirectParams.user_id,
                                    state: response.validationState,
                                    CheckVerificationCode: {
                                        verificationRequestId: store.verificationFormStore.verificationRequestId,
                                        verificationCode: verificationCode,
                                    },
                                });
                                store.userStore.isAuthorized = true;
                            } catch (error: any) {
                                showErrorNotification(error);
                                store.userStore.isAuthorized = false;
                                store.confirmIdentityStore.clear();
                                store.verificationFormStore.clear();
                                return navigate('/');
                            }
                        };
                        store.verificationFormStore.onBackHandler = () => store.verificationFormStore.activeAuthModalVisible = true;

                        return navigate('/verification');
                    } catch (error: any) {
                        showErrorNotification(error);
                        store.confirmIdentityStore.clear();
                        store.verificationFormStore.clear();
                        return navigate('/');
                    }
                };
                return navigate('/confirm_outdated_info');
        }
    }, [
        navigate,
        queryParams,
        services.authorizationService,
        services.base64,
        services.tokenService,
        setQueryParams,
        store.registrationFormStore,
        store.userStore,
        store.verificationFormStore,
        store.confirmIdentityStore,
    ]);

    return null;
};

/**
 * Название компонента, используемое при отладке.
 */
CpgAuthorizationCallbackHandlerContainer.displayName = 'CpgAuthorizationCallbackHandlerContainer';

export default CpgAuthorizationCallbackHandlerContainer;
