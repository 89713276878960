import axios from './axiosService';
import {
    CheckVerificationCodeRecoveryPasswordRequestDto,
    CheckVerificationCodeRequestDto,
    CheckVerificationCodeResponseDto,
    CreateVerificationRequestNewPhoneRequestDto,
    CreateVerificationRequestRequestDto,
    CreateVerificationRequestResponseDto,
} from '../models';
import { RootService } from './rootService';
import { logger } from '../utils';

/**
 * Сервис верификации.
 */
export class VerificationService {
    /**
     * Корневой Web Api сервис.
     */
    rootService: RootService;

    /**
     * Конструктор.
     * @param rootService корневой Web Api сервис.
     */
    constructor(rootService: RootService) {
        this.rootService = rootService;
    }

    /**
     * Создать запрос на верификацию.
     * @returns - Идентификатор запроса на верификацию.
     */
    public async createVerificationRequest(
        body: CreateVerificationRequestRequestDto,
    ): Promise<CreateVerificationRequestResponseDto> {
        try {
            return (
                await axios.post<CreateVerificationRequestResponseDto>('verification/create_verification_request', body)
            ).data;
        } catch (error) {
            logger.error(error);
            return Promise.reject(error);
        }
    }

    /**
     * Создать запрос на верификацию для нового номера телефона.
     * @returns - Идентификатор запроса на верификацию.
     */
    public async createVerificationRequestNewPhone(
        body: CreateVerificationRequestNewPhoneRequestDto,
    ): Promise<CreateVerificationRequestResponseDto> {
        try {
            return (
                await axios.post<CreateVerificationRequestResponseDto>(
                    'verification/create_verification_request/change_phone/new_phone_number',
                    body,
                )
            ).data;
        } catch (error) {
            logger.error(error);
            return Promise.reject(error);
        }
    }

    /**
     * Создать запрос на верефикацию для старого номера телефона.
     * @returns - Идентификатор запроса на верификацию.
     */
    public async createVerificationRequestOldPhone(): Promise<CreateVerificationRequestResponseDto> {
        try {
            return (
                await axios.post<CreateVerificationRequestResponseDto>(
                    'verification/create_verification_request/change_phone/old_phone_number',
                )
            ).data;
        } catch (error) {
            logger.error(error);
            return Promise.reject(error);
        }
    }

    /**
     * Создать запрос на верификацию при восстановлении пароля.
     * @returns - Идентификатор запроса на верификацию.
     */
    public async createPasswordRecoveryVerificationRequest(
        body: CreateVerificationRequestRequestDto,
    ): Promise<CreateVerificationRequestResponseDto> {
        try {
            return (
                await axios.post<CreateVerificationRequestResponseDto>(
                    'verification/create_verification_request/recovery_password',
                    body,
                )
            ).data;
        } catch (error) {
            logger.error(error);
            return Promise.reject(error);
        }
    }

    /**
     * Проверить код верификации для смены пароля.
     * @returns - Результат проверки.
     */
    public async checkVerificationCodeRecoveryPassword(
        body: CheckVerificationCodeRecoveryPasswordRequestDto,
    ): Promise<CheckVerificationCodeResponseDto> {
        try {
            return (await axios.post<CheckVerificationCodeResponseDto>('verification/check_verification_code/recovery_password', body))
                .data;
        } catch (error) {
            logger.error(error);
            return Promise.reject(error);
        }
    }

    /**
     * Проверить код верификации для старого номера телефона.
     * @returns - Результат проверки.
     */
    public async checkVerificationCodeChangeNumber(
        body: CheckVerificationCodeRequestDto,
    ): Promise<CheckVerificationCodeResponseDto> {
        try {
            return (
                await axios.post<CheckVerificationCodeResponseDto>(
                    'verification/check_verification_code/change_phone',
                    body,
                )
            ).data;
        } catch (error) {
            logger.error(error);
            return Promise.reject(error);
        }
    }
}
