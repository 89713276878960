/**
 * Хранилище состояния внешней системы, которая пытается авторизоваться.
 */
export class ExternalSystemAuthorizationHandlerService {
    /**
     * Ссылка для перенаправления.
     */
    public get redirectUri() {
        return localStorage.getItem('redirectUri') ?? "";
    }
    public set redirectUri(value: string) {
        localStorage.setItem('redirectUri', value);
    }

    /**
     * Параметры ссылки для перенаправления.
     */
    public get redirectParams() {
        let value = new URLSearchParams();
        value.append('client_id', localStorage.getItem('client_id') ?? "");
        value.append('state', localStorage.getItem('state') ?? "");
        value.append('code_challenge', localStorage.getItem('code_challenge') ?? "");
        value.append('nonce', localStorage.getItem('nonce') ?? "");

        return value;
    }
    public set redirectParams(value: URLSearchParams) {
        localStorage.setItem('client_id', value.get('client_id') ?? "");
        localStorage.setItem('state', value.get("state") ?? "");
        localStorage.setItem('code_challenge', value.get("code_challenge") ?? "");
        localStorage.setItem('nonce', value.get("nonce") ?? "");
    }

    /**
     * Метод очистки состояния.
     */
    public clear() {
        // Очищаем localStorage от редиректа и параметров.
        localStorage.removeItem('redirectUri');
        localStorage.removeItem('client_id');
        localStorage.removeItem('state');
        localStorage.removeItem('nonce');
        localStorage.removeItem('code_challenge');
    }
}
