import axios from './axiosService';
import { 
    EventFiltersRequestDto, 
    EventsListItemDto, 
    EventTypeDto, 
    ExportEventsToExcelResponseDto, 
    PaginationModel 
} from '../models';
import { logger } from '../utils';

/**
 * Сервис, отвечающий за работу с журналом действий.
 */
export class EventsService {
    /**
     * Получить журнал действий
     * @param filters Фильтры
     * @returns Журнал действий
     */
    async getEventLogs(filters: EventFiltersRequestDto): Promise<PaginationModel<EventsListItemDto>> {
        try {
            const response = await axios.post<PaginationModel<EventsListItemDto>>(
                `eventLog/get_event_logs`,
                filters
            );
            return Promise.resolve(response.data);
        } catch (error: any) {
            logger.error(error);
            return Promise.reject();
        }
    }

    /**
     * Получить набор типов действий
     * @returns Набор типов действий
     */
    async getEventTypes(): Promise<Array<EventTypeDto>> {
        try {
            const response = await axios.get<Array<EventTypeDto>>(`eventLog/get_event_types`);
            return Promise.resolve(response.data);
        } catch (error: any) {
            logger.error(error);
            return Promise.reject();
        }
    }

    /**
     * Получить Excel-файл журнала действий для экспорта
     * @param filters Фильтры
     * @returns объект, содержащий имя Excel-файла журнала действий
     * и его контент в формате Base64
     */
     async getEventLogsExcelData(filters: EventFiltersRequestDto): Promise<ExportEventsToExcelResponseDto> {
        try {
            const response = await axios.post<ExportEventsToExcelResponseDto>(
                `eventLog/export_event_logs_to_excel`,
                filters
            );
            return Promise.resolve(response.data);
        } catch (error: any) {
            logger.error(error);
            return Promise.reject();
        }
    }
}
