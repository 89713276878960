import { RootService } from './rootService';

/**
 * Сервис, отвечающий за работу со строками Base64
 */
export class Base64Service {
    root: RootService;

    constructor(root: RootService) {
        this.root = root;
    }

    decodeRedirectParams(redirectParams: string): any {
        const decodedString = decodeURIComponent(window.atob(redirectParams));

        const queryParams = decodedString.split('&');

        const entries = queryParams.map((qp) => {
            const kvp = qp.split('=');
            return {
                [kvp[0]]: kvp[1],
            };
        });

        return entries.reduce((prev, cur) => ({ ...prev, ...cur }), {});
    }

    /**
     * Конвертируем URL-строку в base-64 представление
     */
    encodeURIStringToBase64(str: string): string {
        return window.btoa(encodeURIComponent(str));
    }

    /**
     * Расшифровываем base-64 представление URL-строки
     */
    decodeBase64ToURIString(str: string): string {
        return decodeURIComponent(window.atob(str));
    }

    /**
     * Конвертируем base-64-представление данных в объект Blob
     * @param base64 - строка
     * @param type - тип данных (по умолчанию - pdf)
     * @returns объект Blob
     */
    public async convertBase64ToBlob(base64: string, type: string = 'application/pdf'): Promise<Blob> {
        return fetch(`data:${type};base64,${base64}`)
            .then((res) => res.blob())
            .catch((error) => Promise.reject(error));
    }
}
