import React from 'react';
import { FormLayout, UkepAuthorizationForm } from "../../containers";

import './UkepAuthorizationPage.less'

/**
 * Страница входа по УКЭП
 */
const UkepAuthorizationPage: React.FC = () => {
    return (
        <FormLayout>
            <div className="qpd-rstr-ukep-auth-page">
                <UkepAuthorizationForm />
            </div>
        </FormLayout>
    );
}

export default UkepAuthorizationPage;