import { createUseStyles } from 'react-jss';

export default createUseStyles(
    () => ({
        modal_buttons: {
            display: 'flex',
            justifyContent: 'space-around',
        },
        modal_text: {
            fontWeight: '500',
        },
        modal_title: {
            padding: '0 0 24px',
            fontSize: '22px',
            color: 'rgba(0, 0, 0, 0.85)',
            fontWeight: '500',
            lineHeight: '22px'
        }
    })
);