import React from 'react';
import { AuthorizeRedirectDecorator } from '../../components/RoutesDecorator/AuthorizeRedirectDecorator';
import { ExternalSystems, FormLayout } from '../../containers';
import './ExternalSystemsPage.less';
import { useServices, useStore } from '../../hooks';
import { useExternalSystemRedirect } from '../../hooks/useExternalSystemRedirect';
import { UserService } from '../../services/userService';

/**
 * Страница с доступными сервисами.
 */
const ExternalSystemsPage: React.FC = () => {
    return (
        <AuthorizeRedirectDecorator>
            <FormLayout>
                <div className="qpd-rstr-external-systems-page">
                    <ExternalSystems />
                </div>
            </FormLayout>
        </AuthorizeRedirectDecorator>
    );
};

/**
 * Название компонента, испольуемое при отладке.
 */
ExternalSystemsPage.displayName = 'ExternalSystemsPage';

export default ExternalSystemsPage;
