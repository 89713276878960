import { makeAutoObservable } from 'mobx';
import { ActionTypes, ChangePhoneVerificationRequest } from '../models';
import { showErrorNotification } from '../utils';
import { services } from '../services';

/**
 * Хранилище состояния формы ввода кода верификации.
 */
export class VerificationFormStore {
    /**
     * Действие, для которого был создан запрос на верификацию.
     */
    private _action: ActionTypes | null = null;

    /**
     * Номер телефона, на который был отправлен код верификации.
     */
    private _phone: string = '';

    /**
     * Идентификатор запроса на верификацию.
     */
    private _verificationRequestId: number = 0;

    /**
     * Модель проверки кода верификации, отправленного на новый номер.
     */
    private _verificationRequestNewNumber: ChangePhoneVerificationRequest = {
        verificationRequestId: 0,
        verificationCode: '',
        action: null,
        phone: '',
    };

    /**
     * Модель проверки кода верификации, отправленного на старый номер.
     */
    private _verificationRequestOldNumber: ChangePhoneVerificationRequest = {
        verificationRequestId: 0,
        verificationCode: '',
        action: null,
        phone: '',
    };

    /**
     * Отображение экрана закгрузки.
     */
    private _showCommonLoad: boolean = false;

    /**
     * Обработчик события отправки формы.
     */
    private _onSubmitHandler: (verificationCode: string) => void = () => {};

    /**
     * Обработчик события закрытия формы.
     */
    private _onBackHandler: () => void = () => {};

    /**
     * Обработчик кнопки повторной отправки кода.
     */
    private defaultOnSendCodeHandler = async () => {
        if (!this._action) return;

        try {
            const dto = await services.verificationService.createVerificationRequest({
                action: this._action,
                phone: this.phone,
            });

            this._verificationRequestId = dto.verificationRequestId;
        } catch (error: any) {
            showErrorNotification(error);
        }
    };

    private _onSendCodeHandler: () => Promise<void> = this.defaultOnSendCodeHandler

    /**
     * Сосотояние для модального окна при смене номера телефона.
     */
    private _activeChangePhoneModalVisible: boolean = false;

    /**
     * Состояние для модального окна при подтверждении паспорта
     * вход через доверенную систему.
     */
    private _activeAuthModalVisible: boolean = false;

    /**
     * Конструктор.
     */
    constructor() {
        makeAutoObservable(this);
    }

    /**
     * Действие, для которого был создан запрос на верификацию.
     */
    public get action() {
        return this._action;
    }
    public set action(value: ActionTypes | null) {
        this._action = value;
    }

    /**
     * Номер телефона, на который был отправлен код верификации.
     */
    public get phone() {
        return this._phone;
    }
    public set phone(value: string) {
        this._phone = value;
    }

    /**
     * Идентификатор запроса на верификацию.
     */
    public get verificationRequestId() {
        return this._verificationRequestId;
    }
    public set verificationRequestId(value: number) {
        this._verificationRequestId = value;
    }

    /**
     * Модель проверки кода верификации, отправленного на старый номер.
     */
    public get verificationRequestOldNumber() {
        return this._verificationRequestOldNumber;
    }
    public set verificationActionOldNumber(value: ActionTypes) {
        this._verificationRequestOldNumber.action = value;
    }
    public set verificationRequestIdOldNumber(value: number) {
        this._verificationRequestOldNumber.verificationRequestId = value;
    }
    public set verificationPhoneOldNumber(value: string) {
        this._verificationRequestOldNumber.phone = value;
    }
    public set verificationCodeOldNumber(value: string) {
        this._verificationRequestOldNumber.verificationCode = value;
    }

    /**
     * Модель проверки кода верификации, отправленного на новый номер.
     */
    public get verificationRequestNewNumber() {
        return this._verificationRequestNewNumber;
    }
    public set verificationActionNewNumber(value: ActionTypes) {
        this._verificationRequestNewNumber.action = value;
    }
    public set verificationRequestIdNewNumber(value: number) {
        this._verificationRequestNewNumber.verificationRequestId = value;
    }
    public set verificationPhoneNewNumber(value: string) {
        this._verificationRequestNewNumber.phone = value;
    }
    public set verificationCodeNewNumber(value: string) {
        this.verificationRequestNewNumber.verificationCode = value;
    }

    /**
     * Отображение экрана закгрузки.
     */
    public get showCommonLoad() {
        return this._showCommonLoad;
    }
    public set showCommonLoad(value: boolean) {
        this._showCommonLoad = value;
    }

    /**
     * Обработчик события отправки формы.
     */
    public get onSubmitHandler() {
        return this._onSubmitHandler;
    }
    public set onSubmitHandler(value: (verificationCode: string) => void) {
        this._onSubmitHandler = value;
    }

    /**
     * Обработчик события закрытия формы.
     */
    public get onBackHandler() {
        return this._onBackHandler;
    }
    public set onBackHandler(value: () => void) {
        this._onBackHandler = value;
    }

    /**
     * Обработчик кнопки повторной отправки кода.
     */
    public get onSendCodeHandler() {
        return this._onSendCodeHandler;
    }
    public set onSendCodeHandler(value: () => Promise<void>) {
        this._onSendCodeHandler = value;
    }

    /**
     * Сосотояние для модального окна при смене номера телефона.
     */
    public get activeChangePhoneModalVisible() {
        return this._activeChangePhoneModalVisible;
    }
    public set activeChangePhoneModalVisible(value: boolean) {
        this._activeChangePhoneModalVisible = value;
    }

    /**
     * Состояние для модального окна при подтверждении паспорта
     * вход через доверенную систему.
     */
    public get activeAuthModalVisible() {
        return this._activeAuthModalVisible;
    }
    public set activeAuthModalVisible(value: boolean) {
        this._activeAuthModalVisible = value;
    }

    /**
     * Метод очистки состояния.
     */
    public clear() {
        this._action = null;
        this._phone = '';
        this._verificationRequestId = 0;
        this._onSubmitHandler = () => {};
        this._onBackHandler = () => {};
        this._verificationRequestNewNumber = {
            action: null,
            verificationRequestId: 0,
            verificationCode: '',
            phone: '',
        };
        this._verificationRequestOldNumber = {
            action: null,
            verificationRequestId: 0,
            verificationCode: '',
            phone: '',
        };
        this._showCommonLoad = false;
        this._activeChangePhoneModalVisible = false;
        this._activeAuthModalVisible = false;
        this._onSendCodeHandler = this.defaultOnSendCodeHandler;
    }
}
