import axios from './axiosService';
import { UserSignUpRequestDto, UserSignUpResponseDto } from '../models';
import { RootService } from './rootService';
import { logger } from '../utils';

/**
 * Сервис, отвечающий за авторизацию пользователя.
 */
export class RegistrationService {
    /**
     * Корневой Web Api сервис.
     */
    rootService: RootService;

    /**
     * Конструктор.
     * @param rootService корневой Web Api сервис.
     */
    constructor(rootService: RootService) {
        this.rootService = rootService;
    }

    /**
     * Регистрация пользователя.
     * @param body тело запроса.
     */
    public async signUp(body: UserSignUpRequestDto): Promise<void> {
        try {
            const response = await axios.post<UserSignUpResponseDto>('registration/user/sign_up', body);

            const data = response.data;
            if ('accessToken' in data) {
                this.rootService.tokenService.setTokens(data.accessToken, data.refreshToken);
            }

            return Promise.resolve();
        } catch (error: any) {
            logger.error(error);
            return Promise.reject(error);
        }
    }
}
