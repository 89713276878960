import { ProfileMenu } from '@qpd-reestr/profile-menu';
import { Icon } from '@qpd-reestr/ui-kit';
import clsx from 'clsx';
import { observer } from 'mobx-react-lite';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useServices, useStore } from '../../hooks';
import { showErrorNotification } from '../../utils';
import { ProfileMenuProps } from './ProfileMenuProps';

import useStyle from './ProfileMenuStyle';

/**
 * Контейнерный компонент иконки профиля пользователя.
 */
const ProfileMenuContainer: React.FC<ProfileMenuProps> = (props) => {
    const style = useStyle();
    const navigate = useNavigate();
    const { authorizationService, tokenService, userService } = useServices();
    const { userStore } = useStore();

    const decodedAccessToken = tokenService.decodeAccessToken(tokenService.accessToken);

    const [firstName, setFirstName] = useState<string>(decodedAccessToken?.given_name ?? '');
    const [lastName, setLastName] = useState<string>(decodedAccessToken?.family_name ?? '');

    const [openProfileMenu, setOpenProfileMenu] = useState(false);

    /**
     * Получаем информацию о текущем пользователе
     */
    useEffect(() => {
        (async () => {
            try {
                // Если есть согласие на обработку ПНД, и пользователь не является подозрительным
                // то получаем информацию о пользователе,
                // иначе оставляем это на попытку декодировать токен
                if (userStore.hasConsent) {
                    const userId = userStore.currentUserId;
                    const userInfo = await userService.getUserInfoById(Number(userId));
                    setFirstName(userInfo.firstName ?? '');
                    setLastName(userInfo.lastName ?? '');
                }
            } catch (error: any) {
                showErrorNotification(error);
            }
        })();
    }, [userService, userStore.currentUserId, userStore.hasConsent]);

    /**
     * Обработка перехода в админку
     */
    const onAdministrationClickHandler = React.useCallback(async () => {
        setOpenProfileMenu(false);
        navigate('/administration/users');
    }, [navigate]);

    /**
     * Обработка перехода на страницу редактирования профиля
     */
    const onEditProfileClickHandler = React.useCallback(() => {
        setOpenProfileMenu(false);
        navigate('/profile');
    }, [navigate]);

    /**
     * Обработка перехода на страницу смены пароля
     */
    const onChangePasswordClickHandler = React.useCallback(() => {
        setOpenProfileMenu(false);
        navigate('/change-password');
    }, [navigate]);

    /**
     * Обработчик выхода из системы
     */
    const onLogoutClickHandler = React.useCallback(async () => {
        setOpenProfileMenu(false);

        await authorizationService.FederalSignOut();
        tokenService.removeTokens();

        userStore.isAuthorized = false;
    }, [authorizationService, tokenService, userStore]);

    /**
     * Обработчик события изменения видимости всплывающего меню
     */
    const handleOnOpenChange = (newValue: boolean) => {
        setOpenProfileMenu(newValue);
    };

    /**
     * Пункты меню, доступные только администратору SSO
     */
    const adminMenuItems = [
        {
            icon: <Icon name="id-card" />,
            key: '1',
            label: 'Администрирование',
            onClick: onAdministrationClickHandler,
        },
    ];

    /**
     * Пункты меню, доступные всем пользователям.
     */
    const commonMenuItems = [
        {
            icon: <Icon name="pencil" />,
            key: '2',
            label: 'Редактировать профиль',
            onClick: onEditProfileClickHandler,
        },
        {
            icon: <Icon name="key" />,
            key: '3',
            label: 'Сменить пароль',
            onClick: onChangePasswordClickHandler,
        },
        {
            className: 'qpd-rstr-profile-menu__logout',
            icon: <Icon name="trash" />,
            key: '4',
            label: 'Выйти',
            onClick: onLogoutClickHandler,
        },
    ];

    /**
     * Пункты меню, доступные пользователям без согласия на обработку ПНД.
     */
    const shortMenuItems = [
        {
            className: 'qpd-rstr-profile-menu__logout',
            icon: <Icon name="trash" />,
            key: '2',
            label: 'Выйти',
            onClick: onLogoutClickHandler,
        },
    ];

    /**
     * Метод получения пунктов меню для текущего пользователя.
     * @returns Набор пунктов меню.
     */
    const getMenuItems = () => {
        if (userStore.isAdmin) {
            return [...adminMenuItems, ...commonMenuItems];
        }
        if (userStore.hasConsent) {
            return commonMenuItems;
        }
        return shortMenuItems;
    };

    return (
        <ProfileMenu
            className={clsx(style.profile_menu_sso, props.className)}
            title={firstName + ' ' + lastName}
            menuItems={getMenuItems()}
            onOpenChange={handleOnOpenChange}
            open={openProfileMenu}
        />
    );
};

export default observer(ProfileMenuContainer);
