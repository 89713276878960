import { ErrorUkapAuthorizationForm } from "@qpd-reestr/error-ukap-authorization-form";
import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useStore } from "../../hooks";
import { UkapUserRegistrationRouterModel } from "../../models";

/**
 * Форма вывода ошибки авторизации УКЭП
 */
const UkapAuthorizationErrorForm: React.FC = () => { 

    const navigate = useNavigate();
    const store = useStore();

    const registrationModel = useLocation().state as UkapUserRegistrationRouterModel; 

    /**
     * Обработчик нажатия "Войти"
     */
    const handleClickOnSignIn = () => {
        navigate('/');
    }

    /**
     * Обработчик нажатия "Регистрация"
     */
    const handleClickOnRegister = () => {
        if(registrationModel) {
            store.registrationFormStore.subjectId = registrationModel.ukapSerialNumber;
            store.registrationFormStore.firstName = registrationModel.firstName;
            store.registrationFormStore.lastName = registrationModel.lastName;
            store.registrationFormStore.middleName = registrationModel.middleName;
            store.registrationFormStore.email = registrationModel.email;
            store.registrationFormStore.inn = registrationModel.inn;
            store.registrationFormStore.snils = registrationModel.snils;
            store.registrationFormStore.authType = "UKAP";
            
            // Выставляем флаги блокировки полей
            store.registrationFormStore.firstNameDisabled = !!registrationModel.firstName;
            store.registrationFormStore.middleNameDisabled = !!registrationModel.middleName;
            store.registrationFormStore.lastNameDisabled = !!registrationModel.lastName;
        }
        
        return navigate('/registration');
    }

    return (
        <ErrorUkapAuthorizationForm 
            onClickSignIn={handleClickOnSignIn} 
            onClickRegister={handleClickOnRegister}/>
    )
}

export default UkapAuthorizationErrorForm;