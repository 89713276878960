import React from 'react';
import { AuthorizeRedirectDecorator } from '../../components/RoutesDecorator/AuthorizeRedirectDecorator';
import { FormLayout, SignOutHandler } from '../../containers';

const SignOutHandlerPage: React.FC = () => {
    return (
        <AuthorizeRedirectDecorator>
            <FormLayout>
                <SignOutHandler />
            </FormLayout>
        </AuthorizeRedirectDecorator>
    );
};

/**
 * Название компонента, используемое при отладке.
 */
SignOutHandlerPage.displayName = 'ExternalSystemAuthorizationHandlerPage';

export default SignOutHandlerPage;
