import { AuthorizationFormStore } from './authorizationFormStore';
import { ExternalSystemAuthorizationHandlerService } from '../services/externalSystemAuthorizationHandlerService';
import { LoadingStore } from './loadingStore';
import { OverlayStore } from './overlayStore';
import { PasswordRecoveryFormStore } from './passwordRecoveryFormStore';
import { RegistrationFormStore } from './registrationFormStore';
import { SetPasswordFormStore } from './setPasswordFormStore';
import { UserStore } from './userStore';
import { VerificationFormStore } from './verificationFormStore';
import { ConfirmIdentityStore } from './confirmIdentityStore';

export class RootStore {
    /**
     * Хранилище состояния формы авторизации.
     */
    public readonly authorizationFormStore: AuthorizationFormStore;

    /**
     * Хранилище состояния индикатора загрузки.
     */
    public readonly loaderStore: LoadingStore;

    /**
     * Хранилище состояния формы восстановления пароля.
     */
    public readonly passwordRecoveryFormStore: PasswordRecoveryFormStore;

    /**
     * Хранилище состояния формы регистрации.
     */
    public readonly registrationFormStore: RegistrationFormStore;

    /**
     * Хранилище состояния формы установки пароля.
     */
    public readonly setPasswordFormStore: SetPasswordFormStore;

    /**
     * Хранилище состояния информации о пользователе.
     */
    public readonly userStore: UserStore;

    /**
     * Хранилище состояния формы ввода кода верификации.
     */
    public readonly verificationFormStore: VerificationFormStore;

    /**
     * Хранилище состояния слоя затемнения.
     */
    public readonly overlayStore: OverlayStore;

    /**
     * Хранилище состояния формы подрвеждения личности при не совпадении данных в БД и внешней системы
     */
    public readonly confirmIdentityStore: ConfirmIdentityStore;
    
    /**
     * Конструктор.
     */
    constructor() {
        this.authorizationFormStore = new AuthorizationFormStore();
        this.loaderStore = new LoadingStore();
        this.overlayStore = new OverlayStore();
        this.passwordRecoveryFormStore = new PasswordRecoveryFormStore();
        this.registrationFormStore = new RegistrationFormStore();
        this.setPasswordFormStore = new SetPasswordFormStore();
        this.userStore = new UserStore();
        this.verificationFormStore = new VerificationFormStore();
        this.confirmIdentityStore = new ConfirmIdentityStore();
    }
}
