import React, { PropsWithChildren, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useExternalSystemRedirect, useServices, useStore } from '../../../hooks';
import { showErrorNotification } from '../../../utils';
import { store } from '../../../store';

/**
 * Обертка для редиректа. Проверяем наличие полного доступа и редиректов на внешнюю систему.
 */
const AuthorizeRedirectDecorator: React.FC<PropsWithChildren> = (props) => {    const { userStore } = useStore();
    const navigate = useNavigate();
    const { tokenService, consentService } = useServices();
    const [hasConsent, setConsent] = useState<boolean | null>(null);
    
    useEffect(() => {
        // если у пользователя урезанный токен и еще нет правильных ответов на коньтрольные вопросы 
        if (store.userStore.isSuspiciousActivity && !store.verificationFormStore.verificationRequestId) {
            navigate('../suspicious-activity');
        }
    }, [])

    useEffect(() => {
        (async () => {
            try {
                if (userStore.isAuthorized) {
                    const consent = await consentService.getUserConsent();
                    userStore.hasConsent = consent;
                    if (!consent) {
                        // если согласия нет, проверяем токен скоуп
                        if (userStore.hasFullAccess) {
                            //если найден скоуп полного доступа, значит токен неверный. Меняем токен
                            const { accessToken, refreshToken } = await consentService.getNoConsentToken();
                            await tokenService.setTokens(accessToken, refreshToken);
                        }
                    }
                    setConsent(consent);
                }
            } catch (error: any) {
                showErrorNotification(error);
            }
        })();
    }, [consentService, navigate, tokenService, userStore]);

    useExternalSystemRedirect();

    if (userStore.isAuthorized && hasConsent === null) return null;
    // проверяем наличие согласия
    if (userStore.isAuthorized && !hasConsent) {
        // редиректим на форму согласия
        (window as Window).location = window.location.origin + '/user_consent';
        return null;
    }
    
    // отдаем все вложенные роуты
    return <>{props.children}</>;
};

/**
 * Название компонента, используемое при отладке.
 */
AuthorizeRedirectDecorator.displayName = 'AuthorizeRedirectDecorator';

export default AuthorizeRedirectDecorator;