import React from 'react';
import { EsiaAuthorizationCallbackHandler, FormLayout } from '../../containers';

/**
 * Страница "Авторизация через Госуслуги".
 */
const EsiaAuthorizationCallbackHandlerPage: React.FC = () => {
    return (
        <FormLayout>
            <EsiaAuthorizationCallbackHandler />
        </FormLayout>
    );
};

/**
 * Название компонента, используемое при отладке.
 */
EsiaAuthorizationCallbackHandlerPage.displayName = 'EsiaAuthorizationCallbackHandlerPage';

export default EsiaAuthorizationCallbackHandlerPage;
