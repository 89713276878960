import { makeAutoObservable } from 'mobx';
import { services } from '../services';

export class UserStore {
    /**
     * Признак авторизации пользователя.
     */
    private _isAuthorized: boolean = !!localStorage.getItem('access_token');
     
    /**
     * Признак наличия согласия на обработку ПНД.
     */
    private _hasConsent: boolean = false;

    /**
     * Конструктор.
     */
    constructor() {
        makeAutoObservable(this);
    }

    /**
     * Идентификатор текущего пользователя
     */
    public get currentUserId() {
        return services.tokenService.decodeAccessToken(services.tokenService.accessToken).sub;
    }

    /**
     * Признак авторизации пользователя.
     */
    public get isAuthorized() {
        return this._isAuthorized;
    }
    public set isAuthorized(value: boolean) {
        this._isAuthorized = value;
    }

    /**
     * Признак, является ли пользователь админом.
     */
    public get isAdmin() {
        const roleOrRoles: string[] | string = services.tokenService.decodeAccessToken(services.tokenService.accessToken).role;
        const roles = [roleOrRoles].flat();
        return roles ? roles.some(el => el === 'Administrator') : false;
    }

    /**
     * Проверка наличия скоупа полного доступа.
     */
    public get hasFullAccess() {
        const scope : string[] = services.tokenService.decodeAccessToken(services.tokenService.accessToken).scope;
        return scope ? scope.some(el => el === 'ssoapi.hasconsent') : false
    }

    /**
     * Признак, является ли сессия подозрительной с ограниченным токеном.
     */
    public get isSuspiciousActivity() {
        const scope : string[] = services.tokenService.decodeAccessToken(services.tokenService.accessToken).scope;
        return scope ? scope.some(el => el === 'ssoapi.user_confirmation') : false
    }
    /**
     * Проверка наличия согласия на обработку ПНД.
     */
     public get hasConsent() {
        return this._hasConsent;
    }
    public set hasConsent(value: boolean) {
        this._hasConsent = value;
    }
}
