import { makeAutoObservable } from 'mobx';
import { IConfirmIdentityValues } from '../containers/ConfirmIdentityForm/IConfirmIdentityValues';

export class ConfirmIdentityStore {
    /**
     * Идентификатор пользователя.
     * @private
     */
    private _userId: string = '';

    /**
     * Идентификатор пользователя.
     * @private
     */
    private _lastName: string = '';

    /**
     * Идентификатор пользователя.
     * @private
     */
    private _document: string = '';

    /**
     * Конструктор.
     */
    constructor() {
        makeAutoObservable(this);
    }

    /**
     * Получить идентификатор пользователя.
     */
    public get userId() {
        return this._userId;
    }

    /**
     * Установить идентификатор пользователя.
     * @param value
     */
    public set userId(value: string) {
        this._userId = value;
    }

    /**
     * Получить идентификатор пользователя.
     */
    public get lastName() {
        return this._lastName;
    }

    /**
     * Установить идентификатор пользователя.
     * @param value
     */
    public set lastName(value: string) {
        this._lastName = value;
    }

    /**
     * Получить сериию и номер документа пользователя.
     */
    public get document() {
        return this._document;
    }

    /**
     * Установить сериию и номер документа пользователя.
     * @param value
     */
    public set document(value: string) {
        this._document = value;
    }

    /**
     * Обработчик события отправки формы.
     */
    private _onSubmitHandler: (values: IConfirmIdentityValues) => void = () => {};

    /**
     * Обработчик события отправки формы.
     */
    public get onSubmitHandler() {
        return this._onSubmitHandler;
    }
    public set onSubmitHandler(value: (values: IConfirmIdentityValues) => void) {
        this._onSubmitHandler = value;
    }

    /**
     * Метод очистки состояния.
     */
    public clear() {
        this._userId = '';
        this._onSubmitHandler = () => {};
    }
}
