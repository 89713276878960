import { makeAutoObservable } from 'mobx';

/**
 * Хранилище состояния формы установки пароля.
 */
export class SetPasswordFormStore {
    /**
     * Номер телефона.
     */
    private _phone: string = '';

    /**
     * Пароль.
     */
    private _password: string = '';

    /**
     * Идентификатор запроса на верификацию.
     */
    private _verificationRequestId: number = 0;

    /**
     * Код верификации.
     */
    private _verificationCode: string = '';

    /**
     * Обработчик события отправки формы.
     */
    private _onSubmitHander: (password: string) => void = () => {};

    /**
     * Конструктор.
     */
    constructor() {
        makeAutoObservable(this);
    }

    /**
     * Номер телефона.
     */
    public get phone() {
        return this._phone;
    }
    public set phone(value: string) {
        this._phone = value;
    }

    /**
     * Пароль.
     */
    public get password() {
        return this._password;
    }
    public set password(value: string) {
        this._password = value;
    }

    /**
     * Идентификатор запроса на верификацию.
     */
    public get verificationRequestId() {
        return this._verificationRequestId;
    }
    public set verificationRequestId(value: number) {
        this._verificationRequestId = value;
    }

    /**
     * Код верификации.
     */
    public get verificationCode() {
        return this._verificationCode;
    }
    public set verificationCode(value: string) {
        this._verificationCode = value;
    }

    /**
     * Обработчик события отправки формы.
     */
    public get onSubmitHandler() {
        return this._onSubmitHander;
    }
    public set onSubmitHandler(value: (password: string) => void) {
        this._onSubmitHander = value;
    }

    /**
     * Метод очистки состояния.
     */
    public clear() {
        this._phone = '';
        this._password = '';
        this._verificationRequestId = 0;
        this._verificationCode = '';
        this._onSubmitHander = () => {};
    }
}
