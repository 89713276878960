import { Button, Card } from '@qpd-reestr/ui-kit';
import React, { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { FormLayout } from '../../containers';
import { useServices, useStore } from '../../hooks';
import { ConfirmMailStatus } from '../../models';
import "./ConfirmMail.less";
/**
/**
 * Страница потдверждения почты.
 */
const ConfirmMail: React.FC = () => {
    const navigate = useNavigate();
    const services = useServices();
    const store = useStore();

    const [queryParams, setQueryParams] = useSearchParams();
    const [text, setText] = useState<string>();

    useEffect(() => {
        const redirectParamsQuery = queryParams.get('redirectParams');
        if (!redirectParamsQuery) {
            return navigate('/');
        }

        var redirectParams = services.base64.decodeRedirectParams(redirectParamsQuery);
        if(redirectParams.status) {
            let status = redirectParams.status as ConfirmMailStatus;
            
            switch(status){
                case 'Confirmed': {
                    setText(`Уважаемый ${redirectParams.firstName} ${redirectParams.lastName}, ваша почта (${redirectParams.mail}) успешно подтверждена`)
                    break;
                }
                case 'ConfirmedEarly': {
                    setText(`Уважаемый ${redirectParams.firstName} ${redirectParams.lastName}, ваша почта (${redirectParams.mail}) была успешно подтвреждена ранее`)
                    break;
                }
                case 'ConfirmedFailed': {
                    setText(`Ссылка для подтверждения адреса электронной почты истекла, запросите подтверждение еще раз. Необходимо подтвердить адрес в течение 48 часов с момента запроса`)
                    break;
                }
            }
        }
    }, [
        navigate,
        queryParams,
        services.authorizationService,
        services.base64,
        setQueryParams,
        store.userStore,
    ]);

    const handeClickOnMain = () => {
        return navigate('/');
    };

    return (
        <FormLayout>
            <div className="qpd-rstr-confirm-mail">
                <Card>
                    <span>{text}</span>
                    <div className="qpd-rstr-confirm-mail_btn-container">
                        <Button
                            onClick={handeClickOnMain}
                            behaviour="button"
                            className="qpd-rstr-confirm-mail_btn"
                            fullWidth
                            size="lg"
                            text="На главную"
                            type="primary"
                            uppercase
                        />
                    </div>
                </Card>
            </div>
        </FormLayout>
    );
};

/**
 * Название компонента, испольуемое при отладке.
 */
ConfirmMail.displayName = 'ConirmMail';

export default ConfirmMail;
