import { Button, Modal } from '@qpd-reestr/ui-kit';
import { ConfirmModalProps } from './ConfirmModalProps';

import './ConfirmModal.less';

/**
 * Модальное окно подтверждения
 * @returns
 */
const ApproveModal: React.FC<ConfirmModalProps> = ({ onAccept, onReject, opened, rejectButtonText, text }) => {
    return (
        <Modal className="qpd-rstr-approve-modal" title={text} visible={opened}>
            <div className="qpd-rstr-approve-modal__buttons">
                <Button onClick={onReject} text={rejectButtonText} type="cancel" />
                {onAccept ? <Button onClick={() => onAccept()} text="Да" /> : null}
            </div>
        </Modal>
    );
};

ApproveModal.defaultProps = {
    rejectButtonText: 'Отмена',
    text: 'Вы уверены что хотите выполнить действие?',
};

export default ApproveModal;
