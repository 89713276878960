import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { ProfileForm } from '@qpd-reestr/profile-form';
import { notification } from '@qpd-reestr/ui-kit';
import { observer } from 'mobx-react-lite';
import { useServices, useStore } from '../../hooks';
import { ErrorCode, VerifactionStatus } from '../../models';
import { CpgInfo, ProfileInfo } from '../../models/dto/profile';
import { showErrorNotification } from '../../utils';
import { store } from '../../store';

const initialProfile: ProfileInfo = {
    document: { value: '', isPassport: false },
    firstName: '',
    lastName: '',
    middleName: '',
    phone: '',
    email: '',
    inn: '',
    snils: '',
    emailConfirmed: false,
    isActive: true,
    isCorrectionAllowed: true,
    isTrustedAuthorization: true,
    hasTwoFactorAuth: false,
    consentUpdateDate: null,
    reestrId: 0,
    isPassportVerified: false,
};

/**
 * Контейнерный компонент "Форма редактирования профиля".
 */
const ProfileFormContainer: React.FC = () => {
    const navigate = useNavigate();
    const { mailConfirmationService, userService, base64, tokenService, authorizationService, verificationService } =
        useServices();
    const { userStore } = useStore();
    const { profileId } = useParams();
    const [queryParams] = useSearchParams();
    const [profileInfo, setProfileInfo] = useState<ProfileInfo>(initialProfile);
    const [cpgInfo, setCpgInfo] = useState<CpgInfo | null>(null);
    const [trustedAuthSystems, setTrustedAuthSystems] = useState<Array<string>>();

    const isAuthorizedUserProfile = useMemo(() => {
        return (profileId && profileId === userStore.currentUserId) || !profileId;
    }, [profileId, userStore.currentUserId]);

    /**
     * Получаем информацию о пользователе.
     * Если в url передан profileId, значит, пользователь-админ открывает профиль пользователя с инедтификатором {profileId}.
     * Если profileId нет, значит, это профиль самого пользователя - его id берем из стора.
     */
    useEffect(() => {
        (async () => {
            try {
                const userId = profileId ?? userStore.currentUserId;
                const userInfo = await userService.getUserInfoById(Number(userId));
                if (!userInfo.isTrustedAuthorization) {
                    setTrustedAuthSystems(await authorizationService.getTrustedAuthSystems());
                }
                setProfileInfo(userInfo);
            } catch (error: any) {
                showErrorNotification(error);
                if (error.code === ErrorCode.UserNotFound) {
                    navigate('/');
                }
            }
        })();
    }, [profileId, userService, userStore.currentUserId, navigate]);

    const onSubmitHandler = useCallback(
        async (userInfo: ProfileInfo) => {
            try {
                if (userStore.isAdmin) {
                    const userId = profileId ?? userStore.currentUserId;

                    await userService.updateUserProfile({
                        userId: Number(userId),
                        document: userInfo.document,
                        phone: userInfo.phone,
                        email: userInfo.email,
                        firstName: userInfo.firstName,
                        hasTwoFactorAuth: userInfo.hasTwoFactorAuth,
                        lastName: userInfo.lastName,
                        middleName: userInfo.middleName,
                        sendConfirmEmailDto: {
                            redirectUrl: `https://${window.location.host}/email_confirm`,
                        },
                        inn: userInfo.inn,
                        snils: userInfo.snils,
                    });
                } else {
                    if (userInfo.phone !== profileInfo.phone) {
                        store.verificationFormStore.clear();
                        return await onSubmitHandleVerificationCodeOldPhone(profileInfo.phone, userInfo);
                    }
                    await userService.updateMyProfile({
                        email: userInfo.email,
                        hasTwoFactorAuth: userInfo.hasTwoFactorAuth,
                        sendConfirmEmailDto: {
                            redirectUrl: `https://${window.location.host}/email_confirm`,
                        },
                        inn: userInfo.inn,
                        snils: userInfo.snils,
                        phone: userInfo.phone,
                    });
                }

                const redirectUrl = queryParams.get('redirectUrl');

                // Если нет урла для редиректа, переходим на главную SSO
                if (!redirectUrl) {
                    return navigate('/');
                }

                const decodedRedirectUrl = base64.decodeBase64ToURIString(redirectUrl);
                window.location.assign(decodedRedirectUrl);
            } catch (error: any) {
                showErrorNotification(error);
            }
        },
        [
            userStore.isAdmin,
            userStore.currentUserId,
            queryParams,
            base64,
            userService,
            profileId,
            navigate,
            profileInfo,
        ],
    );

    /**
     * Обработчик изменения данных пользователя
     * после успешного ввода кода верификации
     * для старого номера телефона
     * @param oldPhone Старый номер телефона
     * @param userInfo Обновленные данные пользователя
     */
    const onSubmitHandleVerificationCodeOldPhone = async (oldPhone: string, userInfo: ProfileInfo) => {
        try {
            await userService.canUpdateMyProfile({
                inn: userInfo.inn,
                phone: userInfo.phone,
                snils: userInfo.snils,
            });

            //Записываем в стор старый номер телефона и событие для получения кода верификации.
            store.verificationFormStore.action = VerifactionStatus.confirmOldPhone;
            store.verificationFormStore.verificationPhoneOldNumber = oldPhone;

            //Получаем код верификации на старый номер телефона.
            const dto = await verificationService.createVerificationRequestOldPhone();

            //Записываем в стор идентификатор запроса на верификацию для старого номера.
            store.verificationFormStore.verificationRequestIdOldNumber = dto.verificationRequestId;

            store.verificationFormStore.onSendCodeHandler = async () => {
                try {
                    const dto = await verificationService.createVerificationRequestOldPhone();
                    store.verificationFormStore.verificationRequestIdOldNumber = dto.verificationRequestId;
                } catch (error: any) {
                    showErrorNotification(error);
                }
            };

            store.verificationFormStore.onSubmitHandler = async (verificationCode: string) => {
                store.verificationFormStore.verificationCodeOldNumber = verificationCode;
                try {
                    const dto = await verificationService.checkVerificationCodeChangeNumber({
                        verificationCode: store.verificationFormStore.verificationRequestOldNumber.verificationCode,
                        verificationRequestId:
                            store.verificationFormStore.verificationRequestOldNumber.verificationRequestId,
                    });

                    if (dto.isCorrect) {
                        await onSubmitHandleVerificationCodeNewPhone(userInfo);
                    }
                } catch (error: any) {
                    showErrorNotification(error);
                }
            };
            store.verificationFormStore.onBackHandler = () =>
                (store.verificationFormStore.activeChangePhoneModalVisible = true);

            navigate('/profile/verification');
        } catch (error: any) {
            showErrorNotification(error);
        }
    };

    /**
     * Обработчик изменения данных пользователя
     * после успешного ввода кода верификации
     * для нового номера телефона
     * @param userInfo обновленные данные пользователя
     */
    const onSubmitHandleVerificationCodeNewPhone = async (userInfo: ProfileInfo) => {
        try {
            store.verificationFormStore.showCommonLoad = true;

            //Записываем в стор новый номер телефона и событие для получения кода верификации.
            store.verificationFormStore.action = VerifactionStatus.confirmNewPhone;
            store.verificationFormStore.verificationPhoneNewNumber = userInfo.phone;

            //Получаем код верификации на новый номер телефона.
            const dto = await verificationService.createVerificationRequestNewPhone({
                phone: userInfo.phone,
                verificationRequestIdOldNumber:
                    store.verificationFormStore.verificationRequestOldNumber.verificationRequestId,
            });

            //Записываем в стор идентификатор запроса на верификацию для нового номера.
            store.verificationFormStore.verificationRequestIdNewNumber = dto.verificationRequestId;

            store.verificationFormStore.onSendCodeHandler = async () => {
                try {
                    const dto = await verificationService.createVerificationRequestNewPhone({
                        verificationRequestIdOldNumber:
                            store.verificationFormStore.verificationRequestOldNumber.verificationRequestId,
                        phone: store.verificationFormStore.verificationRequestNewNumber.phone,
                    });
                    store.verificationFormStore.verificationRequestIdNewNumber = dto.verificationRequestId;
                } catch (error: any) {
                    showErrorNotification(error);
                }
            };

            store.verificationFormStore.onSubmitHandler = async (verificationCode: string) => {
                store.verificationFormStore.verificationRequestNewNumber.verificationCode = verificationCode;
                try {
                    await userService.updateMyProfile({
                        email: userInfo.email,
                        hasTwoFactorAuth: userInfo.hasTwoFactorAuth,
                        sendConfirmEmailDto: {
                            redirectUrl: `https://${window.location.host}/email_confirm`,
                        },
                        inn: userInfo.inn,
                        snils: userInfo.snils,
                        phone: userInfo.phone,
                        checkVerificationCodeNewPhone: {
                            verificationCode: store.verificationFormStore.verificationRequestNewNumber.verificationCode,
                            verificationRequestIdNewPhone:
                                store.verificationFormStore.verificationRequestNewNumber.verificationRequestId,
                            verificationRequestIdOldPhone:
                                store.verificationFormStore.verificationRequestOldNumber.verificationRequestId,
                        },
                    });
                    store.verificationFormStore.clear();
                } catch (error: any) {
                    showErrorNotification(error);
                }
            };
            store.verificationFormStore.onBackHandler = () =>
                (store.verificationFormStore.activeChangePhoneModalVisible = true);

            navigate('/profile/verification');
        } catch (error: any) {
            showErrorNotification(error);
        } finally {
            //Нужно для более плавного перехода на следующую страницу верификации
            //Тк запрос выполняется слишком быстро
            setTimeout(() => {
                store.verificationFormStore.showCommonLoad = false;
            }, 500);
        }
    };

    const onGetCpgInfoHandler = () => {
        alert('Не готов бэк');
    };

    const onRejectCpgInfoHandler = () => {
        setCpgInfo(null);
    };

    const onConfirmCpgInfo = (cpgInfo: CpgInfo) => {
        console.log(cpgInfo);
        setCpgInfo(null);
    };

    /**
     * Обработчик выхода из системы
     */
    const userLogOut = useCallback(async () => {
        await authorizationService.FederalSignOut();
        tokenService.removeTokens();
        userStore.isAuthorized = false;
    }, [tokenService, userStore, authorizationService]);

    /**
     * Обработчик блокировки профиля
     */
    const onChangeUserStatus = useCallback(
        async (newStatus: boolean) => {
            try {
                if (userStore.isAdmin) {
                    const userId = profileId ?? userStore.currentUserId;
                    const userInfo = await userService.changeAccountActiveStatusByAdmin(Number(userId), newStatus);
                    setProfileInfo({ ...profileInfo, isActive: userInfo.isActive });
                    // если администратор заблокировал сам себя
                    if (!profileId || profileId === userStore.currentUserId) {
                        userLogOut();
                    }
                } else {
                    await userService.blockUserAccount();
                    userLogOut();
                }
            } catch (error: any) {
                showErrorNotification(error);
            }
        },
        [userStore.isAdmin, userStore.currentUserId, userService, profileId, profileInfo, userLogOut],
    );

    /**
     * Обработчик изменения разрешения на редактирование
     */
    const onChangeCorrectionAllowedStatus = useCallback(
        async (isAllowedCorrection: boolean) => {
            try {
                if (userStore.isAdmin && isAllowedCorrection) {
                    const userId = profileId ?? userStore.currentUserId;
                    const userInfo = await userService.allowAccountCorrectionByAdmin(userId);
                    setProfileInfo({ ...profileInfo, isCorrectionAllowed: userInfo.isCorrectionAllowed });
                    return;
                }
                const userInfo = await userService.disallowUserAccountCorrection();
                setProfileInfo({ ...profileInfo, isCorrectionAllowed: userInfo.isCorrectionAllowed });
            } catch (error: any) {
                showErrorNotification(error);
            }
        },
        [userService, userStore.isAdmin, profileId, profileInfo, userStore.currentUserId],
    );

    const onBackClick = () => {
        navigate(-1);
    };

    const onConfirmClick = useCallback(async () => {
        try {
            // Подтверждать можно только свою почту. Кейс, когда админ перешел на профиль иного пользователя, игнорируем
            if (!profileId) {
                await mailConfirmationService.sendMailConfirmation({
                    redirectUrl: `https://${window.location.host}/email_confirm`,
                });
                notification.info('Запрос на подтверждение почты успешно отправлен!');
            } else {
                notification.warning('Запрос на подтверждение почты может быть отправлен только пользователем');
            }
        } catch (error: any) {
            showErrorNotification(error);
        }
    }, [mailConfirmationService, profileId]);

    /**
     * Обработчик нажатия кнопки "Обезличить"
     */
    const onDepersonalizeHandler = useCallback(async () => {
        try {
            await userService.depersonalizeUser(Number(profileId));
            setProfileInfo(await userService.getUserInfoById(Number(profileId)));
            notification.success('Данные пользователя успешно обезличены');
        } catch (error: any) {
            showErrorNotification(error);
        }
    }, [userService, profileId]);

    return (
        <ProfileForm
            onBackClick={onBackClick}
            onConfirmClick={onConfirmClick}
            onSubmit={onSubmitHandler}
            profileInfo={profileInfo}
            onChangeUserCorrectionAllowedStatus={onChangeCorrectionAllowedStatus}
            onConfirmCpgInfoClick={onConfirmCpgInfo}
            onRejectCpgInfoClick={onRejectCpgInfoHandler}
            onGetCpgInfoClick={onGetCpgInfoHandler}
            onDepersonalizeClick={onDepersonalizeHandler}
            isAdmin={userStore.isAdmin}
            onChangeUserActiveStatus={onChangeUserStatus}
            isAuthorizedUserProfile={isAuthorizedUserProfile}
            isPassportVerified={profileInfo.isPassportVerified}
            trustedAuthSystems={trustedAuthSystems}
            cpgUserInfo={cpgInfo}
        />
    );
};

/**
 * Название компонента, используемое при отладке.
 */
ProfileFormContainer.displayName = 'ProfileFormContainer';

export default observer(ProfileFormContainer);
