import { AuthorizeRedirectDecorator } from "../../../components/RoutesDecorator/AuthorizeRedirectDecorator";
import { ActionsList, AdministrationLayout } from "../../../containers";
import "./UserActionsPage.less";

const UserActionsPage: React.FC = () => {
    return (
        <AdministrationLayout title="Журнал действий">
            <AuthorizeRedirectDecorator>
                <ActionsList className="qpd-rstr-event-log-page"/>
            </AuthorizeRedirectDecorator>
        </AdministrationLayout>)
}

export default UserActionsPage;