import React from 'react';
import { AuthorizeRedirectDecorator } from '../../components/RoutesDecorator/AuthorizeRedirectDecorator';
import { ChangePasswordForm, FormLayout } from '../../containers';

const ChangePasswordPage: React.FC = () => {
    return (
        <AuthorizeRedirectDecorator>
            <FormLayout>
                <ChangePasswordForm />
            </FormLayout>
        </AuthorizeRedirectDecorator>
    );
};

export default ChangePasswordPage;
