import axios from './axiosService';
import {
    GetAuthorizationCodeRequestDto,
    GetAuthorizationCodeResponseDto,
    GetExternalSystemRequestDto,
    GetExternalSystemResponseDto,
    GetExternalSystemsResponseDto
} from '../models';
import { logger } from '../utils';

/**
 * Сервис для работы с внешними системами.
 */
export class ExternalSystemService {
    public async getExternalSystems(): Promise<GetExternalSystemsResponseDto> {
        try {
            return (await axios.post<GetExternalSystemsResponseDto>('external_system/get_external_systems')).data;
        } catch (error) {
            logger.error(error);
            return Promise.reject(error);
        }
    }

    public async getExternalSystem(body: GetExternalSystemRequestDto): Promise<GetExternalSystemResponseDto> {
        try {
            return Promise.resolve(
                (await axios.post<GetExternalSystemResponseDto>('external_system/get_external_system', body)).data,
            );
        } catch (error) {
            logger.error(error);
            return Promise.reject(error);
        }
    }

    public async getAuthorizationCode(body: GetAuthorizationCodeRequestDto): Promise<GetAuthorizationCodeResponseDto> {
        try {
            return Promise.resolve(
                (await axios.post<GetAuthorizationCodeResponseDto>('external_system/get_authorization_code', body))
                    .data,
            );
        } catch (error) {
            logger.error(error);
            return Promise.reject(error);
        }
    }
}
