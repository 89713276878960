import React, { ReactNode, useEffect } from 'react';
import { SmsCodeForm } from '@qpd-reestr/sms-code-form';
import { observer } from 'mobx-react-lite';
import { useNavigate } from 'react-router-dom';
import { useServices, useStore } from '../../hooks';
import { FullScreenLoader } from '../../components/FullScreenLoader';
import { NotificationModal } from '../../modals/NotificationModal';
import useStyle from './VerificationFormStyle';
import { VerifactionStatus } from '../../models';

/**
 * Контейнерный компонент "Форма ввода кода верификации".
 */
const VerificationFormContainer: React.FC = () => {
    const navigate = useNavigate();
    const services = useServices();
    const store = useStore();
    const style = useStyle();

    /**
     * Дополнительный текст на форму.
     */
    const [additionalText, setAdditionalText] = React.useState<ReactNode>();

    // очищаем данные после выхода из этой страницы
    useEffect(() => {
        return () => store.verificationFormStore.clear()
    }, [])

    useEffect(() => {
        if (
            !store.verificationFormStore.verificationRequestId &&
            !store.verificationFormStore.verificationRequestOldNumber.verificationRequestId
        ) {
            navigate('/');
        }
        if (store.verificationFormStore.action === VerifactionStatus.confirmNewPhone) {
            setAdditionalText(
                <>
                    Смс отправлено на <span className={style.form__text_info__new_phone}>новый номер</span> телефона
                    <span className={style.form__text_info__new_phone}> +{store.verificationFormStore.verificationRequestNewNumber.phone}</span>
                </>
            );
        } else if (store.verificationFormStore.action === VerifactionStatus.confirmOldPhone) {
            setAdditionalText(
                <>
                    Смс отправлено на <span className={style.form__text_info__old_phone}>старый номер</span> телефона
                    <span className={style.form__text_info__old_phone}> +{store.verificationFormStore.verificationRequestOldNumber.phone}</span>
                </>
            );
        }
        if (store.verificationFormStore.action === VerifactionStatus.recoveryPassword) {
            setAdditionalText(`Смс отправлено на номер +${store.verificationFormStore.phone}`);
        }
    }, [
        navigate,
        store.verificationFormStore.verificationRequestId,
        store.verificationFormStore.verificationRequestOldNumber.verificationRequestId,
        store.verificationFormStore.action,
    ]);

    /**
     * Обработчик клика по кнопке согласия на модальном окне при смене номера телефона.
     */
    const onConfirmChangePhoneModalAcceptHandler = () => {
        navigate('/profile');
        store.verificationFormStore.clear();
    };

    /**
     * Обработчик клика по кнопке согласия на модальном окне при подтверждении паспорта
     * вход черз доверенную систему.
     */
    const onConfirmAuthModalHandler = () => {
        services.suspiciousActivityService.clearSession();
        navigate('/');
        store.verificationFormStore.clear();
        store.confirmIdentityStore.clear();
    };

    return (
        <>
            <SmsCodeForm
                onBack={store.verificationFormStore.onBackHandler}
                onSubmit={store.verificationFormStore.onSubmitHandler}
                sendCode={store.verificationFormStore.onSendCodeHandler}
                additionalText={additionalText}
            />
            {store.verificationFormStore.showCommonLoad && <FullScreenLoader />}
            <NotificationModal
                confirmModalVisible={store.verificationFormStore.activeChangePhoneModalVisible}
                onConfirm={onConfirmChangePhoneModalAcceptHandler}
                setConfirmModalVisible={(value: boolean) => store.verificationFormStore.activeChangePhoneModalVisible = value}
                subTitle="Данные не будут сохранены. Вы точно хотите вернуться в профиль?"
            />
            <NotificationModal
                confirmModalVisible={store.verificationFormStore.activeAuthModalVisible}
                onConfirm={onConfirmAuthModalHandler}
                setConfirmModalVisible={(value: boolean) => store.verificationFormStore.activeAuthModalVisible = value}
                subTitle="Данные не будут сохранены, процесс входа необходимо будет повторить. Вы уверены, что хотите вернуться на форму входа?"
            />
        </>
    );
};

/**
 * Название компонента, используемое при отладке.
 */
VerificationFormContainer.displayName = 'VerificationFormContainer';

export default observer(VerificationFormContainer);
