import axios from './axiosService';
import {
    ControlQuestionsDto,
    ControlAnswersDto,
} from '../models/dto/profile';
import { RootService } from './rootService';
import { logger } from '../utils';
import { SuspiciousActivityInfo } from '@qpd-reestr/suspicious-activity-form';
import { v4 } from 'uuid';

/**
 * Сервис, отвечающий за подозрительную активность.
 */
export class SuspiciousActivityService {
    root: RootService;

    constructor(root: RootService) {
        this.root = root;
    }

    /**
     * Генерирует сессию
     * @returns 
     */
    ensureSession() {
        const sessionId = localStorage.getItem("sessionId");

        if (sessionId) {
            return { sessionId };
        }

        const guid = v4();
        localStorage.setItem("sessionId", guid)

        return {
            sessionId: guid
        };
    }

    // очищает сессию
    clearSession() {
        localStorage.removeItem("sessionId");
    }

    /**
     * Получение контрольных вопросов 
     */
    async getControlQuestions(): Promise<ControlQuestionsDto> {
        try {
            const response = await axios.get<ControlQuestionsDto>('suspiciousactivity/get_control_questions');
            const data = response.data;
            return Promise.resolve(data);
        } catch (error: any) {
            logger.error(error);
            return Promise.reject(error);
        }
    }

    /**
     * Отправка ответов на контрольные вопросы 
     */
    async sendControlAnswers(answers: Partial<SuspiciousActivityInfo>): Promise<ControlAnswersDto> {
        try {
            const response = await axios.post<ControlAnswersDto>('suspiciousactivity/validate_control_questions', answers,
            { ...this.root.suspiciousActivityService.ensureSession() });
            const data = response.data;
            return Promise.resolve(data);
        } catch (error: any) {
            this.clearSession();
            logger.error(error);
            return Promise.reject(error);
        }
    }

}
