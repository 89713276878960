import React, { useCallback, useEffect, useState } from 'react';
import { ServiceList } from '@qpd-reestr/service-list';
import { useServices, useStore } from '../../hooks';
import { showErrorNotification } from '../../utils';
import { useExternalSystemRedirect } from '../../hooks/useExternalSystemRedirect';

/**
 * Контейнерный компонент "Список внешних систем".
 */
const ExternalSystemsContainer: React.FC = () => {
    const services = useServices();
    const store = useStore();

    const [isLoaded, setIsLoaded] = useState<boolean>(false);
    const [externalSystems, setExternalSystems] = useState<any>([]);

    useEffect(() => {
        (async () => {
            try {
                const externalSystemsDto = await services.externalSystemService.getExternalSystems();
                setExternalSystems(externalSystemsDto.externalSystems);
                setIsLoaded(true);
            } catch (error) {
                showErrorNotification(error);
            }
        })();
    }, [services.externalSystemService]);

    const onLogoutClickHandler = useCallback(async () => {
        await services.authorizationService.FederalSignOut();
        services.tokenService.removeTokens();

        store.userStore.isAuthorized = false;
    }, [services.authorizationService, services.tokenService, store.userStore]);

    const onServiceClickHandler = useCallback(
        async (serviceUrl: string) => {
            try {
                const system = externalSystems.filter((es: any) => es.systemInfo.site === serviceUrl)[0];
                const clientId = system.clientId;

                const externalSystemDto = await services.externalSystemService.getExternalSystem({ clientId });
                if (!externalSystemDto.externalSystem.hasAccess) {
                    // TODO: сообщение об ошибке
                    return;
                }
                (window as Window).location = `${serviceUrl}`;
            } catch (error: any) {
                showErrorNotification(error);
            }
        },
        [externalSystems, services.externalSystemService],
    );

    if (!isLoaded) {
        return null;
    }

    const systems = externalSystems.map((es: any) => ({
        hasAccess: es.hasAccess,
        serviceInfo: {
            id: es.systemInfo.id,
            name: es.systemInfo.name,
            site: es.systemInfo.site,
        },
    }));

    return (
        <ServiceList
            onLogOutClickHandler={onLogoutClickHandler}
            onServiceClickHandler={onServiceClickHandler}
            services={systems}
        />
    );
};

/**
 * Название компонента, используемое при отладке.
 */
ExternalSystemsContainer.displayName = 'ExternalSystemsContainer';

export default ExternalSystemsContainer;
