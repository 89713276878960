import axios from './axiosService';
import { RootService } from './rootService';
import { 
    UserConsentDocument, 
    GetUserConsentResponseDto, 
    GetFullAccessTokenResponseDto 
} from '../models/dto/userConsent';
import { logger } from '../utils';

/**
 * Сервис для работы с токена доступа и обновления.
 */
export class UserConsentService {
    /**
     * Корневой Web Api сервис.
     */
    private readonly rootService: RootService;

    /**
     * Конструктор.
     * @param rootService корневой Web Api сервис.
     */
    constructor(rootService: RootService) {
        this.rootService = rootService;
    }

    /**
     *  Метод получение ссылок на документы для страницы согласия обработки ПДН.
     * @returns Модель информации о документах.
     */
    async getUserConsentDocuments(): Promise<UserConsentDocument[]> {
        try {
            const response = await axios.get<UserConsentDocument[]>(`consent/documents`);

            const data = response.data;
            return Promise.resolve(data);
        } catch (error: any) {
            logger.error(error);
            return Promise.reject(error);
        }
    }

    /**
     *  Метод установки признака согласия пользователя на обработку ПДН.
     * @returns Модель информации о документах.
     */
    async setUserConsent(): Promise<void> {
        try {
            await axios.post<void>('consent/set_consent');

            return Promise.resolve();
        } catch (error: any) {
            logger.error(error);
            return Promise.reject(error);
        }
    }

    /**
     *  Метод получения наличия согласия пользователя на обработку ПДН.
     * @returns Признак согласия.
     */
    async getUserConsent(): Promise<boolean> {
        try {
            var response = await axios.post<GetUserConsentResponseDto>('consent/has_consent');

            return Promise.resolve(response.data.hasConsent);
        } catch (error: any) {
            logger.error(error);
            return Promise.reject(error);
        }
    }

    /**
     * Метод получения полноценного токена
     * @returns Модель, содержащая токены доступа и обновления.
     */
    async getFullAccessToken(): Promise<GetFullAccessTokenResponseDto> {
        try {
            const response = await axios.post<GetFullAccessTokenResponseDto>(`token/get_full_access_token`);

            return Promise.resolve(response.data);
        }
        catch (error) {
            logger.error(error);
            return Promise.reject(error);
        }
    }

    /**
     * Метод получения токена ограниченного доступа
     * @returns Модель, содержащая токены доступа и обновления.
     */
    async getNoConsentToken(): Promise<GetFullAccessTokenResponseDto> {
        try {
            const response = await axios.post<GetFullAccessTokenResponseDto>(`token/get_no_consent_token`);

            return Promise.resolve(response.data);
        }
        catch (error) {
            logger.error(error);
            return Promise.reject(error);
        }
    }

    /**
     * Метод сброса всех согласий пользователей
     */
    async resetConsents(): Promise<void> {
        try {
            await axios.post(`consent/all/reset_consent`);
            return Promise.resolve();
        } catch (error: any) {
            logger.error(error);
            return Promise.reject(error);
        }
    }

}