import * as Yup from 'yup';
import { IConfirmIdentityValues } from './IConfirmIdentityValues';

export const validationSchema: Yup.SchemaOf<IConfirmIdentityValues> = Yup.object().shape({
    lastName: Yup.string()
        .max(50, '- длина поля Фамилия не должна превышать 50 символов')
        .required('- необходимо ввести фамилию'),
    document: Yup.object({
        isPassport: Yup.boolean().default(false).defined(),
        value: Yup.string().default('').defined(),
    }).test((value) => {
        if (!value.value)
            return new Yup.ValidationError('- необходимо ввести серию и номер паспорта', value, 'document');

        if (!value.value.match(/^\d{10}/))
            return new Yup.ValidationError('- длина паспорта должна быть 10 символов', value, 'document');

        return true;
    }),
});

export const getValidationMessage = (errors: any = {}) => {
    let message = '';

    if (errors.lastName) {
        message += (message ? '\n' : '') + errors.lastName;
    }

    if (errors.document) {
        message += (message ? '\n' : '') + errors.document;
    }

    if (message) {
        message = 'При проверке введенных значений обнаружены следующие ошибки: \n' + message;
    }

    return message;
};
