import { makeAutoObservable, runInAction } from 'mobx';

/**
 * Класс хранилища состояния слоя затемнения.
 */
export class OverlayStore {
    private _show: boolean = false;

    /**
     * Состояние отображения затемнения
     */
    public get isShowing(): boolean {
        return this._show;
    }

    /**
     * Установить состояние отображения затемнения
     * @param showing флаг состояния
     */
    public setShowing = (showing: boolean) => {
        runInAction(() => {
            this._show = showing;
        });
    };

    constructor() {
        makeAutoObservable(this);
    }
}
