import { makeAutoObservable } from 'mobx';

/**
 * Хранилище состояния формы авторизации.
 */
export class AuthorizationFormStore {
    /**
     * Номер телефона.
     */
    private _phone: string = '';

    /**
     * Пароль.
     */
    private _password: string = '';

    /**
     * Конструктор.
     */
    constructor() {
        makeAutoObservable(this);
    }

    /**
     * Номер телефона.
     */
    public get phone() {
        return this._phone;
    }
    public set phone(value: string) {
        this._phone = value;
    }

    /**
     * Пароль.
     */
    public get password() {
        return this._password;
    }
    public set password(value: string) {
        this._password = value;
    }

    /**
     * Метод очистки состояния.
     */
    public clear() {
        this._phone = '';
        this._password = '';
    }
}
