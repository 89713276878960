import React from 'react';
import { FormLayout, ProfileForm } from '../../containers';
import { AuthorizeRedirectDecorator } from '../../components/RoutesDecorator/AuthorizeRedirectDecorator';
import useStyle from './ProfilePageStyle';

const ProfilePage: React.FC = () => {
    const style = useStyle();

    return (
        <AuthorizeRedirectDecorator>
            <FormLayout>
                <div className={style.profile_page}>
                    <ProfileForm />
                </div>
            </FormLayout>
        </AuthorizeRedirectDecorator>
    );
};

export default ProfilePage;
