import { Feed, Filter, FiltersState, Templates } from '@qpd-reestr/feed';
import { Button, Card } from '@qpd-reestr/ui-kit';
import { observer } from 'mobx-react-lite';
import React, { useEffect, useState } from 'react';
import { ActionTemplate } from '../../components';
import { EventTypeDto, EventsListItemDto, FeedItemWithEvents } from '../../models';
import { services } from '../../services';
import { store } from '../../store';
import { showErrorNotification } from '../../utils';
import './ActionsList.less';
import { ActionsListProps } from './ActionsListProps';


/**
 * Набор статичных фильтров
 */
const filtersStatic: Array<Filter> = [
    {
        dataType: 'string',
        field: 'logDate',
        label: 'Поиск по дате:',
        searchType: 'date',
    },
    {
        dataType: 'string',
        field: 'userFullName',
        label: 'Поиск по Ф.И.О.:',
        searchType: 'substring',
    },
    {
        dataType: 'string',
        field: 'userLogin',
        label: 'Поиск по логину:',
        searchType: 'substring',
    },
];

/**
 * Получить параметры фильтрации для запроса
 * @param filtering объект, сформированный компонентом фильтров
 * @returns объект, соответсвующий модели EventFiltersRequestDto
 */
 const getFilteringValues = (filtering: any) => {
    let applyingFiltering = {}; 
    if(filtering && filtering.userLogin) {
        applyingFiltering = {
            ...applyingFiltering,
            userLogin: filtering.userLogin
        }
    }
    if(filtering && filtering.userFullName) {
        applyingFiltering = {
            ...applyingFiltering,
            userFullName: filtering.userFullName
        }
    }
    if(filtering && filtering.logDate?.length >= 1) {
        applyingFiltering = {
            ...applyingFiltering,
            startDate: filtering.logDate[0]?.startOf("day")
        }
    }
    if(filtering && filtering.logDate?.length === 2) {
        applyingFiltering = {
            ...applyingFiltering,
            endDate: filtering.logDate[1]?.endOf('day')
        }
    }
    if(filtering && filtering.eventTypeCode) {
        applyingFiltering = {
            ...applyingFiltering,
            eventTypeCodes: [filtering.eventTypeCode]
        }
    }
    return applyingFiltering;
};

/**
 * Кол-во элементов списка на странице
 */
const pageSize = 20;

/**
 * Контейнерный компонент "Журнал действий".
 */
const ActionsListContainer: React.FC<ActionsListProps> = (props) => {
    const [pageNumber, setPageNumber] = useState(1);
    const [totalItems, setTotalItems] = useState(0);
    const [filtering, setFiltering] = useState<any>();
    const [filters, setFilters] = useState(filtersStatic);
    const [eventTypes, setEventTypes] = useState<Array<EventTypeDto>>([]);
    const [eventsByPage, setEventsByPage] = useState<Array<EventsListItemDto>>([]);
    const {setLoading} = store.loaderStore;

    /**
     * Эффект получения списка типов 
     * событий для фильтрации
     */
    useEffect(() => {
        (async () => {
            try{
                const data = await services.eventsService.getEventTypes();
                if(data) {
                    setEventTypes(data);
                    setFilters([...filtersStatic, {
                        dataType: 'string',
                        field: 'eventType',
                        label: 'Поиск по типу события:',
                        options: data.map(et=>et.name || '-'),
                        searchType: 'select',
                    }]);
                }
            } catch (error: any) {
                showErrorNotification(error);
            }
        })();
    }, []);

    /**
     * Эффект, подгружающий элементы журнала действий
     */
    useEffect(()=>{
        (async () => {
            try{
                setLoading(true);
                const data = await services.eventsService
                    .getEventLogs({
                        page: pageNumber, 
                        itemsPerPage: pageSize,
                        ...getFilteringValues(filtering)
                    });
                setEventsByPage(data.items);
                setTotalItems(data.totalItems);
            } catch (error: any) {
                showErrorNotification(error);
            } finally {
                setLoading(false);
            }
        })();
    }, [pageNumber, filtering, setLoading])

    /**
     * Шаблон элемента списка для компонента ленты
     */
    const templates = {
        DefaultTemplate: (props: FeedItemWithEvents) => (
            <ActionTemplate {...props} key={props.id} />
        ),
    };

    /**
     * Метод конвертации списка действий к модели ленты активности
     * @param eventsList Список действий
     * @returns Объект FeedItemWithEvents
     */
    const mapEventsToFeeds = (eventsList: Array<EventsListItemDto>) => {
        return eventsList.map(ei => {
            return {
                id: ei.id.toString(),
                date: ei.logDate?.toDateString(),
                eventType: ei.eventType,
                filterFlag: ei.filterFlag,
                ipAddress: ei.ipAddress,
                logDate: ei.logDate,
                userFullName: ei.userFullName,
                userId: ei.userId,
                userLogin: ei.userLogin,
                type: 'EventListItem',
            } as FeedItemWithEvents;
        });
    }

    /**
     * Обработчик кнопки "Применить фильтры"
     */
    const handleApplyFilters = (filtersState: FiltersState) => {
        setPageNumber(1);
        let applyingFiltering = filtersState;
        const eventTypeName = filtersState['eventType'];
        if(eventTypeName){
            const eventTypeCode 
                = eventTypes?.find(et => et.name === eventTypeName)?.code;
            if(eventTypeCode) {
                applyingFiltering = {
                    ...applyingFiltering,
                    eventTypeCode
                }
            }
        }
        setFiltering(applyingFiltering);
    }

    /**
     * Метод экспорта в Excel
     */
    const exportToExcelHandler = async() => {
        try {
            const excelExportData = await services.eventsService
                .getEventLogsExcelData(getFilteringValues(filtering));

            await services.fileService.downloadFileWithBase64Content(
                excelExportData.name,
                excelExportData.content, 
                'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
            );
        } catch(error: any) {
            showErrorNotification(error);
        }
    };

    return (
        <Card className={props.className}>
            <div className="qpd-rstr-actions-list__tool-panel">
                <Button className="qpd-rstr-actions-list__excel-btn" 
                    type="primary" 
                    text="Выгрузить в Excel" 
                    onClick={exportToExcelHandler}
                />
            </div>
            <Feed
                mode="server"
                divider
                filters={filters}
                items={mapEventsToFeeds(eventsByPage)}
                templateClassName={'qpd-rstr-archive-list__item'}
                templates={templates as unknown as Templates}
                showSizeChanger={false}
                onChangePage={pageNumber => setPageNumber(pageNumber)}
                total={totalItems}
                pageSize={pageSize}
                page={pageNumber}
                showApplyFiltersBtn={true}
                onApplyFilters={handleApplyFilters}
                showComponentIfCollectionIsEmpty={true}
            />
            <a href='/' className="qpd-rstr-actions-list__back-btn">
                На главную
            </a>
        </Card>
    );
};

/**
 * Название компонента, используемое при отладке.
 */
 ActionsListContainer.displayName = 'ActionsListContainer';

export default observer(ActionsListContainer);
 