import React from 'react';

const ExternalSystemAuthorizationHandlerContainer: React.FC = () => { 
    return null;
};

/**
 * Название компонента, используемое при отладке.
 */
ExternalSystemAuthorizationHandlerContainer.displayName = 'ExternalSystemAuthorizationHandlerContainer';

export default ExternalSystemAuthorizationHandlerContainer;
