import React from 'react';
import { AuthorizationForm, FormLayout } from '../../containers';

import './AuthorizationPage.less';

/**
 * Страница авторизации.
 */
const AuthorizationPage: React.FC = () => {
    return (
        <FormLayout>
            <div className="qpd-rstr-authorization-page">
                <AuthorizationForm />
            </div>
        </FormLayout>
    );
};

/**
 * Название компонента, используемое при отладке.
 */
AuthorizationPage.displayName = 'AuthorizationPage';

export default AuthorizationPage;
