import { AuthorizationForm, OtherAuthorizationType } from '@qpd-reestr/authorization-form';
import { CadesPluginUtils } from "@qpd-reestr/certificates-utils";
import { notification } from '@qpd-reestr/ui-kit';
import { observer } from 'mobx-react-lite';
import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { FullScreenLoader } from '../../components/FullScreenLoader';
import { useServices, useStore } from '../../hooks';
import { ConfirmModal } from '../../modals';
import { ConfirmModalProps } from '../../modals/ConfirmModal/ConfirmModalProps';
import { ErrorCode, VerifactionStatus } from '../../models';
import { showErrorNotification } from '../../utils';


/**
 * Состояние модального окна подтверждения по умолчанию.
 */
const defaultConfirmModalState: Omit<ConfirmModalProps, 'onReject'> = {
    onAccept: () => {},
    opened: false,
    text: '',
};


/**
 * Контейнерный компонент "Форма авторизации".
 */
const AuthorizationFormContainer: React.FC = () => {
    const navigate = useNavigate();
    const services = useServices();
    const store = useStore();

    const [allowedAuthMethods, setAllowedAuthMethods] = useState<Array<OtherAuthorizationType>>([]);
    const [confirmModalParams, setConfirmModalParams] = useState(defaultConfirmModalState);
    const [showCommonLoad, setShowCommonLoad] = useState(true);
    
    useEffect(() => {
        (async () => {
            try{
                const authMethods = await services.authorizationService.getAuthorizeMethods();
                setAllowedAuthMethods(authMethods); 
            } catch (error: any) {
                notification.error("К сожалению, в данный момент доступен вход только по логину и паролю");
            }
            finally {
                setTimeout(() => {
                    setShowCommonLoad(false);
                }, (500));
            }
        })();
    }, [services.authorizationService]);

    /**
     * Обработчик события клика по кнопке "Забыли пароль?".
     * @param phone номер телефона.
     */
    const onForgotPasswordClickHandler = useCallback(
        (phone: string) => {
            store.authorizationFormStore.clear();
            store.passwordRecoveryFormStore.phone = phone;

            navigate('../password-recovery');
        },
        [navigate, store.authorizationFormStore, store.passwordRecoveryFormStore],
    );

    /**
     * Обработчик события клика по кнопке "Зарегистрироваться".
     */
    const onRegisterClickHandler = useCallback(() => {
        store.authorizationFormStore.clear();
        navigate('../registration');
    }, [navigate, store.authorizationFormStore]);

    /**
     * Обработчик клика по кнопке "Войти с помощью Госуслуг".
     */
    const onEsiaAuthClickHandler = useCallback(async () => {
        try {
            const dto = await services.esiaService.getAuthenticationUrl();

            if(!dto) {
                notification.error("Невозможно войти через госуслуги, пожалуйтса, обратитесь к администратору");
                return;
            }

            if (dto.authenticationUrl) {
                (window as Window).location = dto.authenticationUrl;
            }
        } catch (error) {
            showErrorNotification(error);
        }
    }, [services.esiaService]);

    /**
     * Обработчик клика по кнопке "Войти по УКЭП".
     */
    const onUkepAuthClickHandler = useCallback(async () => {

        try {

            //@ts-ignore
            window.cadespluginStart();
            
            const pluginUtils = new CadesPluginUtils();
            
            if (!(await pluginUtils.isCryptoProBrowserExtensionAvailable())) {
                setConfirmModalParams({
                    opened: true,
                    text: (
                        <span>
                            Для входа с помощью УКЭП, установите{' '}
                            <a href="https://www.cryptopro.ru/products/cades/plugin">
                                КриптоПро ЭЦП Browser plug-in
                            </a>
                        </span>
                    ),
                });
            } else if (!(await pluginUtils.isCryptoProCSPInstalled())) {
                setConfirmModalParams({
                    opened: true,
                    text: (
                        <span>
                            Для входа с помощью УКЭП, установите{' '}
                            <a href="https://www.cryptopro.ru/products/csp">КриптоПро CSP</a>
                        </span>
                    ),
                });
            } else {
                navigate('../ukep/authorization');
            }           
        } 
        catch (error: any) {
            showErrorNotification(error?.code);
        }

    }, [navigate]);

    /**
     * Обработчик клика по кнопке "Войти по Сбер ID".
     */
    const onSberAuthClickHandler = useCallback(async () => {
        try {
            const dto = await services.sberService.getAuthenticationUrl();

            if(!dto) {
                notification.error("Невозможно войти через Сбер ID, пожалуйтса, обратитесь к администратору");
                return;
            }

            if (dto.authenticationUrl) {
                (window as Window).location = dto.authenticationUrl;
            }
        } catch (error) {
            showErrorNotification(error);
        }        
    }, [services.sberService])

    /**
     * Обработчик события отправки формы авторизации.
     * @param phone номер телефона.
     * @param password пароль.
     */
    const onSubmitHandler = useCallback(
        async (phone: string, password: string) => {
            try {
                store.authorizationFormStore.phone = phone;
                store.authorizationFormStore.password = password;
                const dto = await services.authorizationService.signIn({ phone, password });
                if ('verificationRequestId' in dto) {
                    store.verificationFormStore.action = VerifactionStatus.authorization;
                    store.verificationFormStore.phone = phone;
                    store.verificationFormStore.verificationRequestId = dto.verificationRequestId;
                    store.verificationFormStore.onSubmitHandler = async (verificationCode: string) => {
                        try {
                            await services.authorizationService.signInWithVerificationCode({
                                phone: store.authorizationFormStore.phone,
                                password: store.authorizationFormStore.password,
                                verificationRequestId: store.verificationFormStore.verificationRequestId,
                                verificationCode,
                            });
                            store.authorizationFormStore.clear();
                            store.verificationFormStore.clear();

                            store.userStore.isAuthorized = true;
                        } catch (error: any) {
                            showErrorNotification(error);
                            
                            if (error.code === ErrorCode.UserNotFound) {
                                store.authorizationFormStore.clear();
                                store.verificationFormStore.clear();
                                navigate('/');
                            }
                        }
                    };
                    store.verificationFormStore.onBackHandler = () => {
                        services.suspiciousActivityService.clearSession();
                        navigate(-1); 
                    }                

                    navigate('../verification');
                } else if ('accessToken' in dto) {
                    store.authorizationFormStore.clear();
                    store.userStore.isAuthorized = true;
                }
            } catch (error: any) {
                showErrorNotification(error);
            }
        },
        [
            navigate, 
            services.authorizationService, 
            store.authorizationFormStore, 
            store.userStore, 
            store.verificationFormStore,
        ],
    );

    /**
     * Обработчик события отмены действия, для которого было открыто модальное окно.
     */
    const onConfirmModalRejectedHandler = () => {
        setConfirmModalParams(defaultConfirmModalState);
    };

    /**
     * Обработчик события клика действия авторизации по ЦПГ
     */
    const onCpgAuthClickHandler = useCallback(async () => {
        try {
            const dto = await services.cpgService.getAuthenticationUrl();

            if(!dto) {
                notification.error("Невозможно войти через ЦПГ, пожалуйтса, обратитесь к администратору");
                return;
            }

            if (dto.authenticationUrl) {
                (window as Window).location = dto.authenticationUrl;
            }
            
        } catch (error) {
            showErrorNotification(error);
        }
    }, [services.cpgService]);

    return (
        <>
            <AuthorizationForm
                onForgotPassword={onForgotPasswordClickHandler}
                onRegister={onRegisterClickHandler}
                onEsiaAuthClick={onEsiaAuthClickHandler}
                onUkepAuthClick={onUkepAuthClickHandler}
                onSberAuthClick={onSberAuthClickHandler}
                onCpgAuthClick={onCpgAuthClickHandler}
                onSubmit={onSubmitHandler}
                allowedOtherAuthTypes={allowedAuthMethods}
            />
            {confirmModalParams.opened && (
                <ConfirmModal
                    onAccept={confirmModalParams.onAccept}
                    onReject={onConfirmModalRejectedHandler}
                    opened={confirmModalParams.opened}
                    rejectButtonText={confirmModalParams.rejectButtonText}
                    text={confirmModalParams.text}
                />
            )}
            {
                showCommonLoad && <FullScreenLoader />
            }
        </>
    );
};

/**
 * Название компонента, используемое при отладке.
 */
AuthorizationFormContainer.displayName = 'AuthorizationFormContainer';

export default observer(AuthorizationFormContainer);
