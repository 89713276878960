import React, { useEffect, useRef } from 'react';
import { Button, Modal } from '@qpd-reestr/ui-kit';
import { NotificationModalProps } from './NotificationModalProps';
import useStyle from './NotificationModalStyle';

/**
 * Компонент модального окна для подтверждения действий.
 */

const NotificationModal: React.FC<NotificationModalProps> = ({
    onConfirm,
    setConfirmModalVisible,
    confirmModalVisible,
    title,
    subTitle,
}) => {
    const style = useStyle();

    const onConfirmClick = () => {
        onConfirm();
        setConfirmModalVisible(false);
    };

    const onCancelClick = () => {
        setConfirmModalVisible(false);
    };

    const buttonReference = useRef<HTMLButtonElement | null>(null);

    useEffect(() => {
        if (buttonReference.current) {
            buttonReference.current.focus();
        }
    }, [buttonReference]);

    return (
        <Modal visible={confirmModalVisible} onCancel={() => setConfirmModalVisible(false)}>
            <div className={style.modal_title}>{title}</div>
            <p className={style.modal_text}>{subTitle}</p>
            <div className={style.modal_buttons}>
                <Button onClick={onCancelClick} ref={buttonReference} text="Нет" type="cancel" />
                <Button type={'link'} onClick={onConfirmClick} text="Да" />
            </div>
        </Modal>
    );
};

/**
 * Свойства по умолчанию
 */
NotificationModal.defaultProps = {
    title: 'Подтвердите ваши действия',
};

/**
 * Название компонента, используемое при отладке.
 */
NotificationModal.displayName = 'ConfirmModal';

export default NotificationModal;
