/**
 * Получить день.
 * @param date дата.
 * @returns день.
 */
const getDay = (date: Date) => {
    const day = date.getDate();
    if (day > 9) {
        return day;
    }

    return `0${day}`;
};

const getMonth = (date: Date) => {
    const month = date.getMonth() + 1;
    if (month > 9) {
        return month;
    }

    return `0${month}`;
};

/**
 * Получить год.
 * @param date дата.
 * @returns год.
 */
const getYear = (date: Date) => {
    return date.getFullYear() % 100;
};

/**
 * Получить форматированную дату.
 * @param date дата
 * @returns форматированная дата.
 */
export const getFormattedDate = (date?: Date) => {
    if (!date) {
        return '';
    }

    return `${getDay(date)}.${getMonth(date)}.${getYear(date)}`;
};
