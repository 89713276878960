import React from 'react';
import { ConfirmIdentityForm, FormLayout } from '../../containers';

/**
 * Страница подтверждения личности для обновления данных из внешнего источника.
 */
const ConfirmIdentityPage: React.FC = () => {
    return (
        <FormLayout>
            <ConfirmIdentityForm />
        </FormLayout>
    );
};

/**
 * Название компонента, используемое при отладке.
 */
ConfirmIdentityPage.displayName = 'ConfirmIdentityPage';

export default ConfirmIdentityPage;
