import { format, getSuitableMaskByLength } from '@qpd-reestr/mask';

/**
 * Функция, форматирующая логин пользователя
 */
export const formatUserLogin = (login?: string) => {
    if (!login) {
        return '-';
    }
    // Проверяем, если логин состоит 
    // только из цифр, то это телефон
    if(!(/^\d+$/.test(login))) {
        return login;
    }

    // Выбираем нужную маску в зависимости от длины строки
    const mask = getSuitableMaskByLength(login, [
        { mask: '+0 (000) 000-00-00', regex: /[^\d]/g },
        { mask: '+0 (000) 000-000-00', regex: /[^\d]/g },
    ]);

    return format(login, mask);
};