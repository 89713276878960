import { AuthorizeRedirectDecorator } from "../../../components/RoutesDecorator/AuthorizeRedirectDecorator";
import { AdministrationLayout, UserList } from "../../../containers";
import "./UsersPage.less";

const UsersPage: React.FC = () => {
    return (
        <AdministrationLayout title="Пользователи">
            <AuthorizeRedirectDecorator>
                <UserList className="qpd-rstr-users-page" showBackBtn={true}/>
            </AuthorizeRedirectDecorator>
        </AdministrationLayout>)
}

export default UsersPage;