import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useServices } from "./useServices";
import { useStore } from "./useStore";
import { store } from "../store";
import { showErrorNotification } from "../utils";

/**
 * Вызывает обработку редиректа на внешнюю систему
 */
export const useExternalSystemRedirect = () => {
    const navigate = useNavigate();
    const services = useServices();
    const { userStore } = useStore();
    const { redirectUri, redirectParams } = services.externalSystemAuthorizationHandlerService;

    useEffect(() => {
        if (redirectUri) {
            // Получаем идентификатор клиента
            let clientId = redirectParams.get('client_id') ?? "";

            // флаг, что приходим авторизовваться под тем же пользвателем
            const userId = redirectParams.get('userId') ?? "";
            let isSameUser = userId ? userId === store.userStore.currentUserId : true;

            // если не указан идентификатор клиента
            // Если не авторизован
            // если другой польователь
            if (!clientId || !userStore.isAuthorized || !isSameUser) {
                return navigate('/');
            }

            // эти параметры были сфомированы клиентом(внешней системой)
            const state = redirectParams.get("state") ?? "";
            const codeChallenge = redirectParams.get("code_challenge") ?? "";
            const nonce = redirectParams.get("nonce") ?? "";

            (async () => {
                try {
                    const externalSystemDto = await services.externalSystemService.getExternalSystem({ clientId });
                    if (!externalSystemDto.externalSystem.hasAccess) {
                        // TODO: добавить сообщение об ошибке.
                        return navigate('/');
                    }

                    const authorizationCodeDto = await services.externalSystemService.getAuthorizationCode({
                        clientId,
                        state: externalSystemDto.state,
                        codeChallenge,
                        nonce
                    });
                    
                    const redirect = redirectUri ?? externalSystemDto.externalSystem.systemInfo.site;

                    var url = new URL(redirect);
                    url.searchParams.append("code", authorizationCodeDto.authorizationCode);
                    url.searchParams.append("state", state);


                    services.externalSystemAuthorizationHandlerService.clear();
                    
                    (window as Window).location = url.toString();
                } catch (error: any) {
                    showErrorNotification(error);
                    navigate('/');
                }
            })();
        }
    }, [
        userStore.isAuthorized,
        userStore.hasConsent
    ]);
}