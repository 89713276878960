import React from 'react';
import { FormLayout, PasswordRecoveryForm } from '../../containers';

import './PasswordRecoveryPage.less';
/**
 * Страница восстановления пароля.
 */
const PasswordRecoveryPage: React.FC = () => {
    return (
        <FormLayout>
            <div className="qpd-rstr-password-recovery-page">
                <PasswordRecoveryForm />
            </div>
        </FormLayout>
    );
};

/**
 * Название компонента, используемое при отладке.
 */
PasswordRecoveryPage.displayName = 'PasswordRecoveryPage';

export default PasswordRecoveryPage;
