import React from 'react';
import { FeedItemWithEvents } from '../../models';
import { formatUserLogin } from '../../utils';
import './ActionTemplate.less';

/**
 * Компонент-шаблон для элемента списка "Журнал действий".
 */
const ActionTemplate: React.FC<FeedItemWithEvents> = (props) => {
    /**
     * Дата события
     */
    const eventDate = props.logDate ? props.logDate.toLocaleString('ru-Ru') : '-';
    /**
     * IP-адрес события
     */
    const eventIp = !props.ipAddress || props.ipAddress === 'Unknown' ? '-' : props.ipAddress;

    return (
        <div className="qpd-rstr-action">
            <div className="qpd-rstr-action__event-info">
                <div>{eventDate}</div>
                <div className="qpd-rstr-action__event-info_name">
                    {props.eventType?.name ?? 'Неизвестный тип события'}
                </div>
            </div>
            <div className="qpd-rstr-action__user-info">
                <div className="qpd-rstr-action__user-info_fio">{props.userFullName ?? '-'}</div>
                <div className="qpd-rstr-action__user-info_phone">{formatUserLogin(props.userLogin)}</div>
                <div className="qpd-rstr-action__user-info_ip">
                    <span>IP: </span>
                    <span>{eventIp}</span>
                </div>
            </div>
        </div>
    );
};

/**
 * Название компонента, используемое при отладке.
 */
ActionTemplate.displayName = 'ActionTemplate';

export default ActionTemplate;
