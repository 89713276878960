import React from 'react';
import { CertificateInfoProps } from './CertificateInfoProps';
import { getFormattedDate } from './CertificateInfoUtils';
import './CertificateInfo.less';

/**
 * Компонент "Информация о выбранном сертификате".
 */
const CertificateInfo: React.FC<CertificateInfoProps> = ({ certificate }) => {
    return (
        <>
            <div className="qpd-rstr-cert_header">Информация о сертификате:</div>
            <div className="qpd-rstr-cert_info">
                <div className="qpd-rstr-cert_info__row">
                    <div className="qpd-rstr-cert_info__row_label">Владелец:</div>
                    <div className="qpd-rstr-cert_info__row_value" title={certificate?.subjectName}>
                        {certificate?.subjectName}
                    </div>
                </div>
                <div className="qpd-rstr-cert_info__row">
                <div className="qpd-rstr-cert_info__row_label">Издатель:</div>
                    <div className="qpd-rstr-cert_info__row_value" title={certificate?.issuerName}>
                        {certificate?.issuerName}
                    </div>
                </div>
                <div className="qpd-rstr-cert_info__row">
                <div className="qpd-rstr-cert_info__row_label">Выдан:</div>
                    <div
                        className={`qpd-rstr-cert_info__row_value ${
                            certificate &&
                            certificate.validFromDate > new Date() &&
                            'qpd-rstr-cert_info__row_value_error'
                        }`}
                        title={getFormattedDate(certificate?.validFromDate)}
                    >
                        {getFormattedDate(certificate?.validFromDate)}
                    </div>
                </div>
                <div className="qpd-rstr-cert_info__row">
                <div className="qpd-rstr-cert_info__row_label">Действителен до:</div>
                    <div
                        className={`qpd-rstr-cert_info__row_value ${
                            certificate &&
                            certificate.validToDate < new Date() &&
                            'qpd-rstr-cert_info__row_value_error'
                        }`}
                        title={getFormattedDate(certificate?.validToDate)}
                    >
                        {getFormattedDate(certificate?.validToDate)}
                    </div>
                </div>
                <div className="qpd-rstr-cert_info__row">
                <div className="qpd-rstr-cert_info__row_label">Криптопровайдер:</div>
                    <div className="qpd-rstr-cert_info__row_value" title={certificate?.privateKey?.provider}>
                        {certificate?.privateKey?.provider}
                    </div>
                </div>
                <div className="qpd-rstr-cert_info__row">
                <div className="qpd-rstr-cert_info__row_label">Статус:</div>
                    <div
                        className={`qpd-rstr-cert_info__row_value ${
                            (certificate && !certificate.isValid && 'qpd-rstr-cert_info__row_value_error') || ''
                        }`}
                        title={
                            certificate &&
                            (certificate.isValid ? 'Действительный сертификат' : 'Недействительный сертификат')
                        }
                    >
                        {certificate &&
                            (certificate.isValid ? 'Действительный сертификат' : 'Недействительный сертификат')}
                    </div>
                </div>
            </div>
        </>
    );
};

/**
 * Название компонента, используемое при отладке.
 */

CertificateInfo.displayName = 'CertificateInfo';

export default CertificateInfo;
