import './FullScreenLoader.less';

const FullScreenLoader: React.FC = () => {
    return (
        <div className="qpd-rstr-fullscreen-loader">
            <img src="/reestr-logo.svg" />
            <div>Загружаем...</div>
        </div>
    );
};

export default FullScreenLoader;
