import React from 'react';
import { FormLayout, VerificationForm } from '../../containers';

import './VerificationPage.less';
/**
 * Страница ввода кода верификации.
 */
const VerificationPage: React.FC = () => {
    return (
        <FormLayout>
            <div className="qpd-rstr-verification-page">
                <VerificationForm />
            </div>
        </FormLayout>
    );
};

/**
 * Название компонента, используемое при отладке.
 */
VerificationPage.displayName = 'SmsCodePage';

export default VerificationPage;
