import React from 'react';
import { FormLayout, UkapAuthorizationErrorForm } from "../../containers";

/**
 * Страница ошибки входа по укэп
 */
const UkapAuthorizationErrorPage: React.FC = () => {
    return (
        <FormLayout>
            <UkapAuthorizationErrorForm />
        </FormLayout>
    );
}

export default UkapAuthorizationErrorPage;