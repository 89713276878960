import { Button, Card } from '@qpd-reestr/ui-kit';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { FormLayout } from '../../containers';
import './ErrorPage.less';

/**
 * Страница ошибки.
 */
const ErrorPage: React.FC = () => {
    const navigate = useNavigate();
        
    const [queryParams] = useSearchParams();

    // Ошибка из урл
    const error = queryParams.get("errorText");
    
    /**
     * Обработчик нажатия "на главную"
     * @returns 
     */
    const handeClickOnMain = () => {
        return navigate('/');
    }

    return (
        <FormLayout>
            <div className="qpd-rstr-error-page">
                <Card className="qpd-rstr-error-page_card">
                    <span>{error}</span>
                    <div className="qpd-rstr-error-page_card_btn-container">
                        <Button
                            onClick={handeClickOnMain}
                            behaviour="button"
                            className="qpd-rstr-error-page_btn"
                            fullWidth
                            size="lg"
                            text="На главную"
                            type="primary"
                            uppercase
                        />
                    </div>
                </Card>
            </div>
        </FormLayout>
    );
};

export default ErrorPage;
