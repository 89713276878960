import { makeAutoObservable } from 'mobx';

/**
 * Хранилище состояния формы восстановления пароля.
 */
export class PasswordRecoveryFormStore {
    /**
     * Номер телефона.
     */
    private _phone: string = '';

    /**
     * Конструктор.
     */
    constructor() {
        makeAutoObservable(this);
    }

    /**
     * Номер телефона.
     */
    public get phone() {
        return this._phone;
    }
    public set phone(value: string) {
        this._phone = value;
    }

    /**
     * Метод очистки состояния.
     */
    public clear() {
        this._phone = '';
    }
}
