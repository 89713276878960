import React from 'react';
import { FormLayout, RegistrationForm } from '../../containers';

import './RegistrationPage.less'

/**
 * Страница регистрации.
 */
const RegistrationPage: React.FC = () => {
    return (
        <FormLayout>
            <div className="qpd-rstr-registration-page">
                <RegistrationForm />
            </div>
        </FormLayout>
    );
};

/**
 * Название компонента, используемое при отладке.
 */
RegistrationPage.displayName = 'RegistrationPage';

export default RegistrationPage;
