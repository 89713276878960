import React from 'react';
import { FormLayout, UserConsentForm } from '../../containers';
import './UserConsentPage.less';

/**
 * Страница согласия пользователя с обработкой ПДН.
 */
const UserConsentPage: React.FC = () => {
    return (
        <FormLayout>
            <div className="qpd-rstr-user-consent-page">
                <UserConsentForm />
            </div>
        </FormLayout>
    );
};

/**
 * Название компонента, используемое при отладке.
 */
UserConsentPage.displayName = 'UserConsentPage';

export default UserConsentPage;
