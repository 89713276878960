import React from 'react';
import { FormLayout } from '../../containers';
import { UnathorizedRedirectDecorator } from '../../components/RoutesDecorator/UnathorizedRedirectDecorator';
import { AuthorizeRedirectDecorator } from '../../components/RoutesDecorator/AuthorizeRedirectDecorator';

const ExternalSystemAuthorizationHandlerPage: React.FC = () => {
    return (
        <UnathorizedRedirectDecorator>
            <AuthorizeRedirectDecorator>
                <FormLayout></FormLayout>
            </AuthorizeRedirectDecorator>
        </UnathorizedRedirectDecorator>
    );
};

/**
 * Название компонента, используемое при отладке.
 */
ExternalSystemAuthorizationHandlerPage.displayName = 'ExternalSystemAuthorizationHandlerPage';

export default ExternalSystemAuthorizationHandlerPage;
