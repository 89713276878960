import React from 'react';
import { FormLayout, SuspiciousActivityFormContainer } from '../../containers';

const SuspiciousActivityPage: React.FC = () => {
    return (
      <FormLayout>
        <SuspiciousActivityFormContainer />
      </FormLayout>
    );
};

/**
 * Название компонента, используемое при отладке.
 */
SuspiciousActivityPage.displayName = 'SuspiciousActivityPage';

export default SuspiciousActivityPage;