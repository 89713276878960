import { RegistrationForm, RegistrationFormValues } from '@qpd-reestr/registration-form';
import { observer } from 'mobx-react-lite';
import React, { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { useServices, useStore } from '../../hooks';
import { ErrorCode, VerifactionStatus } from '../../models';
import { showErrorNotification } from '../../utils';

/**
 * Контейнерный компонент "Форма регистрации".
 */
const RegistrationFormContainer: React.FC = () => {
    const navigate = useNavigate();
    const services = useServices();
    const store = useStore();

    /**
     * Обработчик события отправки формы регистрации.
     * @param values Значения формы регистрации.
     */
    const onSubmitHandler = useCallback(
        async (values: RegistrationFormValues) => {
            try {
                store.registrationFormStore.firstName = values.firstName;
                store.registrationFormStore.lastName = values.lastName;

                // Из формы отчество приходит объектом (значение и флаг наличия).
                // В стор записываем только значение.
                store.registrationFormStore.middleName = values.middleName.value;
                store.registrationFormStore.phone = values.phone;
                store.registrationFormStore.password = values.password;
                store.registrationFormStore.document = values.document;
                store.registrationFormStore.email = values.email;
                store.registrationFormStore.inn = values.inn;
                store.registrationFormStore.snils = values.snils;
                store.registrationFormStore.state = values.state;
                store.registrationFormStore.passportVerification = values.passportVerification;

                // Отправляем запрос на проверку существования пользователя
                // если ок, то продолжаем регистрацию
                await services.userService.isValidUser({
                    firstName: store.registrationFormStore.firstName,
                    lastName: store.registrationFormStore.lastName,
                    middleName: store.registrationFormStore.middleName,
                    inn: store.registrationFormStore.inn,
                    snils: store.registrationFormStore.snils,
                    phone: store.registrationFormStore.phone,
                    document: store.registrationFormStore.document,
                    email: store.registrationFormStore.email,
                    password: store.registrationFormStore.password,
                });

                // Создаем запрос верификации на регистрацию
                const dto = await services.verificationService.createVerificationRequest({
                    action: VerifactionStatus.registration,
                    phone: values.phone,
                });

                store.verificationFormStore.action = VerifactionStatus.registration;
                store.verificationFormStore.phone = values.phone;
                store.verificationFormStore.verificationRequestId = dto.verificationRequestId;
                store.verificationFormStore.onSubmitHandler = async (verificationCode: string) => {
                    try {
                        await services.registrationService.signUp({
                            subjectId: store.registrationFormStore.subjectId || null,
                            firstName: store.registrationFormStore.firstName,
                            lastName: store.registrationFormStore.lastName,
                            middleName: store.registrationFormStore.middleName,
                            phone: store.registrationFormStore.phone,
                            password: store.registrationFormStore.password,
                            document: store.registrationFormStore.document,
                            inn: store.registrationFormStore.inn,
                            snils: store.registrationFormStore.snils,
                            email: store.registrationFormStore.email,
                            verificationRequestId: store.verificationFormStore.verificationRequestId,
                            authType: store.registrationFormStore.authType || null,
                            verificationCode,
                            sendConfirmEmailDto: {
                                redirectUrl: `https://${window.location.host}/email_confirm`,
                            },
                            state: store.registrationFormStore.state,
                            passportVerification: store.registrationFormStore.passportVerification,
                        });

                        store.authorizationFormStore.clear();
                        store.passwordRecoveryFormStore.clear();
                        store.registrationFormStore.clear();
                        store.verificationFormStore.clear();

                        store.userStore.isAuthorized = true;
                    } catch (error: any) {
                        showErrorNotification(error);
                        if (error.code === ErrorCode.UserAlreadyExists) {
                            navigate('/');
                        }
                    }
                };
                store.verificationFormStore.onBackHandler = () => navigate(-1);

                navigate('../verification');
            } catch (error: any) {
                showErrorNotification(error);
            }
        },
        [
            navigate,
            services.registrationService,
            services.userService,
            services.verificationService,
            store.authorizationFormStore,
            store.passwordRecoveryFormStore,
            store.registrationFormStore,
            store.userStore,
            store.verificationFormStore,
        ],
    );

    /**
     * Обработчик клика по кнопке "Авторизация".
     */
    const handleCancelClick = useCallback(() => {
        store.registrationFormStore.clear();
        navigate('/');
    }, [navigate]);

    const disabled = {
        firstName: store.registrationFormStore.firstNameDisabled,
        lastName: store.registrationFormStore.lastNameDisabled,
        middleName: store.registrationFormStore.middleNameDisabled,
        passport: store.registrationFormStore.passportDisabled,
        phone: store.registrationFormStore.phoneDisabled,
        inn: store.registrationFormStore.innDisabled,
        snils: store.registrationFormStore.snilsDisabled,
    };

    const initialValues = {
        firstName: store.registrationFormStore.firstName,
        lastName: store.registrationFormStore.lastName,
        middleName: { value: store.registrationFormStore.middleName, isMiddleName: true },
        phone: store.registrationFormStore.phone,
        password: store.registrationFormStore.password,
        document: store.registrationFormStore.document,
        email: store.registrationFormStore.email,
        inn: store.registrationFormStore.inn,
        snils: store.registrationFormStore.snils,
        state: store.registrationFormStore.state,
        passportVerification: store.registrationFormStore.passportVerification
    };

    return (
        <RegistrationForm
            disabled={disabled}
            initialValues={initialValues}
            onCancelClick={handleCancelClick}
            onSubmit={onSubmitHandler}
        />
    );
};

/**
 * Название компонента, используемое при отладке.
 */
RegistrationFormContainer.displayName = 'RegistrationFormContainer';

export default observer(RegistrationFormContainer);
