import { AuthorizationService } from './authorizationService';
import { Base64Service } from './base64Service';
import { CommonService } from './commonService';
import { CpgService } from './cpgService';
import { EsiaService } from './esiaService';
import { EventsService } from './eventsService';
import { ExternalSystemAuthorizationHandlerService } from './externalSystemAuthorizationHandlerService';
import { ExternalSystemService } from './externalSystemService';
import { FileService } from './fileService';
import { MailConfirmationService } from './mailConfirmationService';
import { RegistrationService } from './registrationService';
import { SberService } from './sberService';
import { SuspiciousActivityService } from './suspiciousActivityService';
import { TokenService } from './tokenService';
import { UserConsentService } from './userConsentService';
import { UserService } from './userService';
import { VerificationService } from './verificationService';

export class RootService {
    /**
     * Сервис авторизации.
     */
    public readonly authorizationService: AuthorizationService;

    public readonly base64: Base64Service;

    /**
     * Сервис для работы с ЕСИА.
     */
    public readonly esiaService: EsiaService;

    /**
     * Сервис для работы с ЦПГ.
     */
    public readonly cpgService: CpgService;

    /**
     * Сервис для работы со Сбер ID.
     */
    public readonly sberService: SberService;

    /**
     * Сервис для работы с внешними системами.
     */
    public readonly externalSystemService: ExternalSystemService;

    /**
     * Сервис подтверждения адреса эл. почты.
     */
    public readonly mailConfirmationService: MailConfirmationService;

    /**
     * Сервис регистрации.
     */
    public readonly registrationService: RegistrationService;

    /**
     * Сервис для работы с токенами доступа и обновления.
     */
    public readonly tokenService: TokenService;

    /**
     * Сервис для работы с информацией о пользователе.
     */
    public readonly userService: UserService;

    /**
     * Сервис верификации.
     */
    public readonly verificationService: VerificationService;

    /**
     * Сервис обработки согласия с ПДН.
     */
    public readonly consentService: UserConsentService;
    
    /**
     * Сервис для работы с журналом действий.
     */
    public readonly eventsService: EventsService;

    /**
     * Сервис для работы с файлами
     */
    public readonly fileService: FileService;

    /**
     * Сервис подозрительной активности
     */
    public readonly suspiciousActivityService: SuspiciousActivityService;

    /**
     * Сервис получения состояния внешней системы, которая пытается авторизоваться.
     */
    public readonly externalSystemAuthorizationHandlerService: ExternalSystemAuthorizationHandlerService;
    
    /**
     * Общий сервис
     */
    public readonly commonService: CommonService
    /**
     * Конструктор.
     */
    constructor() {
        this.authorizationService = new AuthorizationService(this);
        this.base64 = new Base64Service(this);
        this.esiaService = new EsiaService(this);
        this.cpgService = new CpgService(this);
        this.sberService = new SberService(this);
        this.externalSystemService = new ExternalSystemService();
        this.mailConfirmationService = new MailConfirmationService(this);
        this.registrationService = new RegistrationService(this);
        this.tokenService = new TokenService(this);
        this.userService = new UserService(this);
        this.verificationService = new VerificationService(this);
        this.consentService = new UserConsentService(this);
        this.eventsService = new EventsService();
        this.fileService = new FileService(this);
        this.commonService = new CommonService(this);
        this.suspiciousActivityService = new SuspiciousActivityService(this)
        this.externalSystemAuthorizationHandlerService = new ExternalSystemAuthorizationHandlerService()
    }
}
