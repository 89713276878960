import React from 'react';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import {
    AuthorizationPage,
    ChangePasswordPage,
    ConfirmMail,
    ErrorPage,
    EsiaAuthorizationCallbackHandlerPage,
    SberIdAuthorizationCallbackHandlerPage,
    CpgAuthorizationCallbackHandlerPage,
    ExternalSystemAuthorizationHandlerPage,
    ExternalSystemsPage,
    PasswordRecoveryPage,
    ProfilePage,
    RegistrationPage,
    SetPasswordPage,
    SignOutHandlerPage,
    UkapAuthorizationErrorPage,
    UkepAuthorizationPage,
    UserConsentPage,
    VerificationPage,
    ConfirmIdentityPage,
    SuspiciousActivityPage
} from './pages';
import { Notification } from '@qpd-reestr/ui-kit';
import { observer } from 'mobx-react-lite';
import { StoreContext, store } from './store';
import { UserActionsPage, UsersPage } from './pages/Administration';
import './App.less';
import 'antd/dist/antd.css';

const App: React.FC = () => {
    return (
        <>
            <BrowserRouter>
                <StoreContext.Provider value={store}>
                    <Routes>
                        {!store.userStore.isAuthorized ? (
                            <>
                                <Route path="/" element={<AuthorizationPage />} />
                                <Route
                                    path="esia/authorization_callback"
                                    element={<EsiaAuthorizationCallbackHandlerPage />}
                                />
                                <Route
                                    path="sberid/authorization_callback"
                                    element={<SberIdAuthorizationCallbackHandlerPage />}
                                />
                                <Route
                                    path="cpg/authorization_callback"
                                    element={<CpgAuthorizationCallbackHandlerPage />}
                                />
                                <Route path="verification" element={<VerificationPage />} />
                                <Route path="ukep/authorization" element={<UkepAuthorizationPage />} />
                                <Route path="ukep/sign-in-error" element={<UkapAuthorizationErrorPage />} />
                                <Route path="password-recovery" element={<PasswordRecoveryPage />} />
                                <Route path="registration" element={<RegistrationPage />} />
                                <Route path="set-password" element={<SetPasswordPage />} />
                                <Route path="confirm_outdated_info" element={<ConfirmIdentityPage />} />
                                <Route path="*" element={<Navigate to="/" replace />} />
                            </>
                        ) : (
                            <>
                                <Route path="user_consent" element={<UserConsentPage />} />
                                <Route path="/" element={<ExternalSystemsPage />} />
                                <Route path="change-password" element={<ChangePasswordPage />} />
                                <Route path="es/sign-out" element={<SignOutHandlerPage />} />
                                <Route path="profile" element={<ProfilePage />} />
                                <Route path="suspicious-activity/verification" element={<VerificationPage />} />
                                <Route path="suspicious-activity" element={<SuspiciousActivityPage />} />
                                {!store.userStore.isAdmin && (
                                    <>
                                        <Route path="profile/verification" element={<VerificationPage />} />
                                    </>
                                )}
                                {store.userStore.isAdmin && (
                                    <>
                                        <Route path="profile/:profileId" element={<ProfilePage />} />
                                        <Route path="administration/users" element={<UsersPage />} />
                                        <Route path="administration/user-event-log" element={<UserActionsPage />} />
                                    </>
                                )}
                                <Route path="*" element={<Navigate to="/" replace />} />
                            </>
                        )}
                        <Route path="es/authorization" element={<ExternalSystemAuthorizationHandlerPage />} />
                        <Route path="email_confirm" element={<ConfirmMail />} />
                        <Route path="error" element={<ErrorPage />} />
                    </Routes>
                </StoreContext.Provider>
            </BrowserRouter>
            <Notification />
        </>
    );
};

export default observer(App);
