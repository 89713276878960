import axios from './axiosService';
import { notification } from '@qpd-reestr/ui-kit';
import { UserListItem } from '@qpd-reestr/user-list';
import {
    CheckUserRequestDto,
    EditPasswordRequestDto,
    PaginationModel,
    RecoveryPasswordRequestDto,
    UserSortField,
} from '../models';
import {
    ProfileInfo,
    UpdateAdminProfileRequestDto,
    UpdateAdminProfileResponseDto as UpdateUserProfileResponseDto,
    UpdateProfileRequestDto as UpdateMyProfileRequestDto,
    ChangeActiveStatusResponse,
    CheckUserInfoRequestDto,
} from '../models/dto/profile';
import { GetBase64ReportResponseModel } from '../models/dto/user';
import { UpdateProfileRequestResponseDto as UpdateMyProfileResponseDto } from '../models/dto/profile/UpdateProfileRequestResponseDto';
import { RootService } from './rootService';
import { logger } from '../utils';
import { ChangeCorrectionAllowedStatusResponse } from '../models/dto/profile/ChangeAccountCorrectionResponseDto';

/**
 * Сервис, отвечающий за авторизацию пользователя.
 */
export class UserService {
    root: RootService;

    constructor(root: RootService) {
        this.root = root;
    }

    /**
     * Восстановить пароль.
     */
    async recoveryPassword(data: RecoveryPasswordRequestDto): Promise<void> {
        try {
            await axios.post<void>('user/recovery_password', data);
            notification.info('Ваш пароль успешно обновлен, авторизуйтесь в системе');
        } catch (error: any) {
            logger.error(error);
            return Promise.reject(error);
        }
    }

    /**
     * Редактирование профиля
     * @param body тело запроса.
     */
    async updateMyProfile(body: UpdateMyProfileRequestDto): Promise<UpdateMyProfileResponseDto> {
        try {
            const response = await axios.post<UpdateMyProfileResponseDto>('user/update_my_profile', body);
            const data = response.data;

            return Promise.resolve(data);
        } catch (error: any) {
            logger.error(error);
            return Promise.reject(error);
        }
    }

    /**
     * Изменить профиль(для администратора)
     * @param body  тело запроса.
     */
    async updateUserProfile(body: UpdateAdminProfileRequestDto): Promise<UpdateUserProfileResponseDto> {
        try {
            const response = await axios.post<UpdateUserProfileResponseDto>('user/update_user_profile', body);
            const data = response.data;

            return Promise.resolve(data);
        } catch (error: any) {
            logger.error(error);
            return Promise.reject(error);
        }
    }

    /**
     * Метод проверки валидности пользователя
     * @param body тело запроса.
     */
    async isValidUser(body: CheckUserRequestDto): Promise<void> {
        try {
            await axios.post<void>('user/is_valid_user', body);
            return Promise.resolve();
        } catch (error: any) {
            logger.error(error);
            return Promise.reject(error);
        }
    }

    /**
     * Метод получения списка пользователей (с пагинацией, поиском и сортировкой).
     * @param page Номер текущий страницы.
     * @param take Кол-во элементов на одно странцы.
     * @param searchText Текст поиска.
     * @param sortColumn название параметра для сортировки
     * @param sortDescending направление сортировки
     * @returns  Модель отображения списка пользователей.
     */
    async getUsersWithPaging(
        page?: number,
        take?: number,
        searchText?: string,
        sortDescending: boolean = true,
        sortColumn: UserSortField = 'FullName',
    ): Promise<PaginationModel<UserListItem>> {
        try {
            const response = await axios.get<PaginationModel<UserListItem>>(
                `user/user_list?searchText=${searchText}&page=${page}&take=${take}&sortDescending=${sortDescending}&sortColumn=${sortColumn}`,
            );
            const data = response.data;

            return Promise.resolve(data);
        } catch (error: any) {
            logger.error(error);
            return Promise.reject();
        }
    }

    /**
     * Метод получения достуsпных параметров сортировки.
     * @returns  Модель доступных методов сортировки.
     */
    async getAviableSortingsMethods():Promise<Record<string, UserSortField>> {
        try {
            const response = await axios.get<Record<string, UserSortField>>(
                '/user/get_available_sorting_methods',
            );
            const data = response.data;

            return Promise.resolve(data);
        } catch (error: any) {
            logger.error(error);
            return Promise.reject();
        }
    }
    /**
     *  Метод получение информации о пользователе по его идентификатору.
     * @param userId идентификатор пользователя.
     * @returns Модель информации о пользователе.
     */
    async getUserInfoById(userId: number): Promise<ProfileInfo> {
        try {
            const response = await axios.get<ProfileInfo>(`user/${userId}`);
            const data = response.data;
            return Promise.resolve(data);
        } catch (error: any) {
            logger.error(error);
            return Promise.reject(error);
        }
    }

    /**
     * Метод изменения пароля.
     * @returns
     */
    async editPassword(currentPassword: string, newPassword: string): Promise<void> {
        try {
            await axios.post<EditPasswordRequestDto>('user/edit_password', {
                currentPassword: currentPassword,
                newPassword: newPassword,
            });

            return Promise.resolve();
        } catch (error: any) {
            logger.error(error);
            return Promise.reject(error);
        }
    }

    /**
     * Метод запрета корректировки данных аккаунта пользователем
     * @returns
     */
    async disallowUserAccountCorrection(): Promise<ChangeCorrectionAllowedStatusResponse> {
        try {
            const response = await axios.post<ChangeCorrectionAllowedStatusResponse>(
                '/user/disallow_my_profile_correction',
            );
            const data = response.data;
            return Promise.resolve(data);
        } catch (error: any) {
            logger.error(error);
            return Promise.reject(error);
        }
    }

    /**
     * Метод разрешения корректировки данных аккаунта администратором
     * @param userId идентификатор пользователя.
     * @returns
     */
    async allowAccountCorrectionByAdmin(userId: number): Promise<ChangeCorrectionAllowedStatusResponse> {
        try {
            const response = await axios.post<ChangeCorrectionAllowedStatusResponse>(
                `/user/allow_profile_correction/${userId}`,
            );
            const data = response.data;
            return Promise.resolve(data);
        } catch (error: any) {
            logger.error(error);
            return Promise.reject(error);
        }
    }

    /**
     * Метод изменения статуса аккаунта пользователя администратором
     * @param userId идентификатор пользователя.
     * @param newStatus новы статус.
     * @returns
     */
    async changeAccountActiveStatusByAdmin(userId: number, newStatus: boolean): Promise<ChangeActiveStatusResponse> {
        try {
            const response = await axios.post<ChangeActiveStatusResponse>(
                `/user/set_active_user_profile/${userId}?isActive=${newStatus}`,
            );
            const data = response.data;
            return Promise.resolve(data);
        } catch (error: any) {
            logger.error(error);
            return Promise.reject(error);
        }
    }

    /**
     * Метод заблокирования собственного аккаунта пользователем
     * @param userId идентификатор пользователя.
     * @returns
     */
    async blockUserAccount(): Promise<ChangeActiveStatusResponse> {
        try {
            const response = await axios.post<ChangeActiveStatusResponse>('/user/block_my_profile');
            const data = response.data;
            return Promise.resolve(data);
        } catch (error: any) {
            logger.error(error);
            return Promise.reject(error);
        }
    }
    /**
     * Метод обновления расширенной информации о пользователе
     * @param userId
     * @returns
     */
    async updateExtendedInfoByUserId(userId: number): Promise<void> {
        try {
            await axios.post(`user/update_extended_info/${userId}`);
            return Promise.resolve();
        } catch (error: any) {
            logger.error(error);
            return Promise.reject(error);
        }
    }

    /**
     * Метод обновления списка доступных систем пользователя
     * @param userId
     * @returns
     */
    async updateAvalibleSytemsByUserId(userId: number): Promise<void> {
        try {
            await axios.post(`user/update_avalible_systems/${userId}`);
            return Promise.resolve();
        } catch (error: any) {
            logger.error(error);
            return Promise.reject(error);
        }
    }

    /**
     * Метод получения отчета о согласиях по id профиля
     * @param userId идентификатор пользователя
     * @returns Модель ответа на запрос получения отчета о согласиях
     */
    async getConsentReportByUserId(userId: number): Promise<GetBase64ReportResponseModel> {
        try {
            const response = await axios.post<GetBase64ReportResponseModel>(`consent/create_consent_report/${userId}`);
            const data = response.data;
            return Promise.resolve(data);
        } catch (error: any) {
            logger.error(error);
            return Promise.reject(error);
        }
    }

    /**
     * Метод проверки валидности данных пользователя.
     * @param body Тело запроса.
     * @returns
     */
    async canUpdateMyProfile(body: CheckUserInfoRequestDto): Promise<void> {
        try {
            await axios.post<void>('user/can_update_my_profile', body);
            return Promise.resolve();
        } catch (error: any) {
            logger.error(error);
            return Promise.reject(error);
        }
    }

    /**
     * Метод для обезличивания профиля пользователя (для администраторов)
     * @param userId Идентификатор пользователя
     */
    async depersonalizeUser(userId: number): Promise<void>{
            try {
                await axios.post(`user/depersonalize/${userId}`)
                return Promise.resolve();
            } catch (error:any){
                logger.error(error);
                return Promise.reject(error);
            }
    }
}
