import React from 'react';
import { CpgAuthorizationCallbackHandlerContainer, FormLayout } from '../../containers';

/**
 * Страница "Авторизация через ЦПГ".
 */
const CpgAuthorizationCallbackHandlerPage: React.FC = () => {
    return (
        <FormLayout>
            <CpgAuthorizationCallbackHandlerContainer />
        </FormLayout>
    );
};

/**
 * Название компонента, используемое при отладке.
 */
CpgAuthorizationCallbackHandlerPage.displayName = 'CpgAuthorizationCallbackHandlerPage';

export default CpgAuthorizationCallbackHandlerPage;
