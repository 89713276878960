import { logger } from "../utils";
import { RootService } from "./rootService";

/**
 * Сервис для работы с файлами.
 */
export class FileService {
    /**
     * Корневой сервис.
     */
    private readonly rootService: RootService;

     /**
      * Конструктор.
      * @param root корневой сервис.
      */
    constructor(root: RootService) {
         this.rootService = root;
    }

    /**
     * Скачать файл на основе строки в формате Base64
     * @param name Имя файла
     * @param content Контент в формате Base64
     * @param contentType Тип контента
     */
    public async downloadFileWithBase64Content(name: string, content: string, contentType = 'application/pdf'): Promise<void> {
        try {
            const blob = await this.rootService.base64
                .convertBase64ToBlob(content, contentType);

                if(blob) {
                // Создание ссылки на Blob
                const blobURL = URL.createObjectURL(blob);
                // Создание ссылки для скачивания файла
                const link = document.createElement("a");
                link.href = blobURL;
                link.download = name;

                // Добавление ссылки на страницу и эмуляция клика для скачивания
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
                
                // Очистка ссылки и освобождение ресурсов Blob
                URL.revokeObjectURL(blobURL);
            }
        } catch (error) {
            logger.error(error);
            return Promise.reject(error);
        }
    }
}