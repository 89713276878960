import { notification } from '@qpd-reestr/ui-kit';
import { AxiosError } from 'axios';
import { ErrorCode } from '../models';
/**
 * Получаем текст ошибки по ее коду для последующего вывода
 * @param code - код ошибки
 * @returns текст ошибки
 */
export const getMessageByCode = (code: number) => {
    switch (code) {
        case ErrorCode.InvalidRequestData:
            return 'Некорректные данные в запросе.';

        case ErrorCode.ErrorEmailSending:
            return 'Произошла ошибка при отправке запроса на подтверждение электронной почты. Пожалуйста, обратитесь к администратору.';

        case ErrorCode.ExternalSystemHasNoRights:
            return 'К сожалению, у вас нет прав на запрашиваемую систему. Для решения проблемы свяжитесь со службой технической поддержки.';

        case ErrorCode.ExternalSystemNotFound:
            return 'Запрашиваемая система не найдена. Для решения проблемы свяжитесь со службой технической поддержки.';

        case ErrorCode.ExternalSystemSessionIsExpired:
            return 'Сессия запрашиваемой системы истекла.';

        case ErrorCode.IncorrentPassword:
            return 'Введен неверный пароль.';

        case ErrorCode.RefreshTokenIsExpired:
            return 'Истек срок действия токена обновления.';

        case ErrorCode.RefreshTokenNotFound:
            return 'Токен обновления не найден.';

        case ErrorCode.UserAlreadyExists:
            return 'Пользователь с такими идентификационными данными уже существует в системе, пожалуйста, авторизуйтесь.';

        case ErrorCode.DuplicateInn:
            return 'Пользователь с таким ИНН уже существует.';

        case ErrorCode.DuplicateSnils:
            return 'Пользователь с таким СНИЛС уже существует.';

        case ErrorCode.DuplicateDocument:
            return 'Пользователь с таким документом уже существует.';

        case ErrorCode.DuplicatePhone:
            return 'Пользователь с таким номером телефона уже существует.';

        case ErrorCode.UserDataDiscrepancy:
            return 'Кажется, мы нашли Вас, но у Вас изменились персональные данные. Обратитесь к администратору для внесения изменений в систему или проверьте данные сервиса, которым Вы пользуетесь для входа.';

        case ErrorCode.UserEmailIsEmpty:
            return 'Не найден адрес электронной почты для отправки подтверждения. Попробуйте ввести новый адрес, или обратитесь к администратору.';

        case ErrorCode.UserFoundByFioOnly:
            return 'Пользователь найден, но у него нет данных ИНН и СНИЛС.';

        case ErrorCode.UserHasNoRights:
            return 'У вас нет прав на данную операцию.';

        case ErrorCode.UserIdCookieIsInvalid:
            return 'Cookie с идентификатором пользователя не найден или имеет некорректное значение.';

        case ErrorCode.UserIsNotActive:
            return 'Доступ в систему запрещен, пожалуйста, обратитесь к администратору.';

        case ErrorCode.UserNotFound:
            return 'Пользователь не найден.';

        case ErrorCode.UserSessionIdCookieIsInvalid:
            return 'Cookie с идентификатором сессии пользователя не найден или имеет некорректное значение.';

        case ErrorCode.UserSessionIsExpired:
            return 'Пользовательская сессия истекла';

        case ErrorCode.UserSessionNotFound:
            return 'Сессия пользователя неактивна';

        case ErrorCode.VerificationCodeIsInvalid:
            return 'Введен некорректный код верификации.';

        case ErrorCode.UserConsentReportGenerationError:
            return 'Ошибка формирования отчета о выражении согласия. Пожалуйста, обратитесь к администратору.';
        
        case ErrorCode.IncorrentLoginOrPassword:
            return 'Неверные логин и пароль.';

        case ErrorCode.InvalidDataFromExternalSource:
            return 'Произошла ошибка получения данных из внешней системы авторизации. Пожалуйста, воспользуйтесь другим способом входа или обратитесь к администратору.';

        case ErrorCode.CorrectionAlreadyAllowed:
            return 'Разрешение на редактирование уже получено';

        case ErrorCode.ActualPassportNotRetrieved:
                return 'Не удалось получить актуальный паспорт.';
                
        case ErrorCode.AlreadyDepersonalized:
                return 'Пользователь уже обезличен';
        default:
            return;
    }
};

/**
 * Получаем объединенную ошибку по списку кодов
 * @param errors - ошибки
 * @returns текст ошибки
 */
export const getMessagesByCodes = (errors: Array<{ message: string; code: number }>): string => {
    return errors.map((error) => getMessageByCode(error.code)).join('\n');
};

/**
 * Выводим уведомление об ошибке
 * @param error - код ошибки
 * @param correlationId - корреляционный идентификатор, необходим для отладки
 * @returns уведомление об ошибке
 */
export const showErrorNotification = (error: any, correlationId?: string | null) => {
    return notification.error(getErrorNotificationMessage(error, null, correlationId));
};

/**
 * Выводим уведомление об ошибке
 * @param error - код ошибки
 * @param fallbackMessage - текст сообщения (опционально) на случай, если код ошибки не передан или не найден в enum
 * @param correlationId - корреляционный идентификатор, необходим для отладки
 * @returns уведомление об ошибке
 */
export const showErrorNotificationWithFallBack = (error: any, fallbackMessage?: string | null, correlationId?: string | null) => {
    return notification.error(getErrorNotificationMessage(error, fallbackMessage, correlationId));
}

/**
 * Метод формирует ошибку на основе входных данных
 * @param error - код ошибки
 * @param fallbackMessage - текст сообщения(опциональный) на случай, если код ошибки не передан или не найден в enum
 * @param correlationId - идентификатор ошибки для отладки
 * @returns возвращает текстовую ошибку
 */
const getErrorNotificationMessage = (error: any, fallbackMessage?: string | null, correlationId?: string | null) => {
    // Если код ошибки 401, она уже обработана на уровне axios сервиса, поэтому ничего не возвращаем
    if (error.code === AxiosError.ERR_BAD_REQUEST && error.response?.status === 401) {
        return;
    }

    // 403 всегда ошибка авторизации. Ответ системы стандарнтый
    if(error.response?.status === 403){
        return getMessageByCode(ErrorCode.UserHasNoRights) 
            ?? "К сожалению, у вас нет прав на данную операцию";
    }

    if (error.code === ErrorCode.InternalServerError) {
        return 'Произошла внутренняя ошибка сервера. Для решения проблемы свяжитесь со службой технической поддержки';
    }

    if (error.code === AxiosError.ERR_BAD_REQUEST && error.response?.data?.errors) {
        const keys = error.response.data.errors;
        for (let key in keys) {
            let messages = error.response.data.errors[key];
            //check if each error string in errors' array has multiple errors with ';' divider
            // if there are no dividers, string will be the same
            messages = messages.map((message: string) => message.split(';').join('\n'));

            return messages.join('\n');
        }
    }

    if (error.code === AxiosError.ERR_NETWORK) {
        return 'Сервер временно недоступен. Попробуйте выполнить операцию позднее';
    }

    let message;

    if (!Array.isArray(error) && error.code) {
        message = getMessageByCode(error.code);
    } else {
        message = getMessagesByCodes(error);
    }

    // Дефолтное сообщение используем, если не удалось определить сообщение по коду
    const defaultMessage =
        fallbackMessage ?? 'Произошла непредвиденная ошибка. Пожалуйста, обратитесь к администратору';

    // сообщение с данными корреляции
    var correlationErrorMessage = correlationId ? 
        `Идентификатор ошибки - ${correlationId}` : '';

    return (
        message ? 
            `${message}\n${correlationErrorMessage}` : 
            `${defaultMessage}\n${correlationErrorMessage}`
    );
}