import { ApiResult, AxiosBase } from '@qpd-reestr/axios';
import { AxiosRequestConfig, AxiosRequestHeaders } from 'axios';
import { services } from '.';
import { store } from '../store';
/**
 * Сервис, отвечающий за обращение к API.
 */
class AxiosService {
    /**
     * Базовый экзмепляр Axios
     */
    private axios: AxiosBase;

    /**
     * Колбэк для установки состояния загрузки
     * @param loading Возвращает флаг загрузки
     */
    private onLoading = (loading: boolean) => {
        store.loaderStore.setLoading(loading);
    };

    /**
     * Колбэк для возврата токена доступа
     * @returns Возвращает токен доступа
     */
    private getAccessToken = () => {
        return services.tokenService.accessToken;
    };

    /**
     * Колбэк для возврата токена обновления
     * @returns возвращает токен обновления
     */
    private getRefreshToken = () => {
        return services.tokenService.refreshToken;
    };

    /**
     * Колбэк срабатывает при успешной аутентификации
     * @param access_token токен доступа
     * @param refresh_token токен обновления
     */
    private onSignInSuccess = (access_token: string, refresh_token: string) => {
        services.tokenService.setTokens(access_token, refresh_token);
        store.userStore.isAuthorized = true;
    };

    /**
     * Колбэк срабатывает при ошибке аутентификации
     */
    private onSignInFailed = () => {
        services.suspiciousActivityService.clearSession();
        services.tokenService.removeTokens();
        store.userStore.isAuthorized = false;
    };

    constructor() {
        this.axios = new AxiosBase(
            `${process.env.REACT_APP_REESTR_SSO_API}/api/v1/internal`,
            this.onSignInSuccess,
            this.onSignInFailed,
            this.getRefreshToken,
            this.getAccessToken,
            this.onLoading,
        );
    }

    post<T>(url: string, body?: any, headers?: AxiosRequestHeaders): Promise<ApiResult<T>> {
        return this.axios.post(url, body, headers);
    }

    get<T>(url: string, queryParams: any = undefined, headers?: AxiosRequestHeaders): Promise<ApiResult<T>> {
        const config: AxiosRequestConfig = {
            params: queryParams,
            headers: headers
        };

        return this.axios.get(url, config);
    }
}

export default new AxiosService();
