import { observer } from 'mobx-react-lite';
import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useServices } from '../../hooks';
import { UserConsentDocument } from '../../models/dto/userConsent';
import { ConsentForm } from '@qpd-reestr/consent-form';
import { notification } from '@qpd-reestr/ui-kit';
import { showErrorNotification } from "../../utils";
/**
 * Контейнерный компонент "Форма согласия с обработкой ПДН".
 */
const UserConsentFormContainer: React.FC = () => {
    const navigate = useNavigate();
    const { consentService, tokenService } = useServices();

    const [documentList, setDocumentList] = useState<UserConsentDocument[]>([]);

    useEffect(() => {
        (async () => {
            const hasConsent = await consentService.getUserConsent();

            if (hasConsent) {
                return navigate('/');
            }

            try {
                const list = await consentService.getUserConsentDocuments();
                setDocumentList(list);
            } catch (error: any) {
                showErrorNotification(error);
            }
        })();
    }, [navigate, consentService]);

    /**
     * Обработчик клика по кнопке согласия.
     */
    const onAcceptClickHandler = useCallback(async () => {
        try {
            await consentService.setUserConsent();

            const { accessToken, refreshToken } = await consentService.getFullAccessToken();

            await tokenService.setTokens(accessToken, refreshToken);

            navigate('/');
        } catch (error: any) {
            notification.error('Не удалось обработать согласие. Попробуйте позже или обратитесь к администратору.');
        }
    }, [navigate, consentService, tokenService]);

    return <ConsentForm onSubmit={onAcceptClickHandler} documents={documentList} />;
};

/**
 * Название компонента, используемое при отладке.
 */
UserConsentFormContainer.displayName = 'UserConsentFormContainer';

export default observer(UserConsentFormContainer);
