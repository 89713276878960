import React from 'react';
import { AdministrationLayoutProps } from "./AdministrationLayoutProps";
import { Footer } from '../../components';
import { observer } from 'mobx-react-lite';
import { PageLayout } from '@qpd-reestr/layout';
import { ProfileMenu } from '../ProfileMenu';
import { useNavigate } from 'react-router-dom';
import { useStore } from '../../hooks';
import './AdministrationLayout.less';

/**
 * Компонент лейаута администрации.
 */
const AdministrationLayout: React.FC<AdministrationLayoutProps> = props => {
    const { loaderStore, overlayStore, userStore } = useStore();
    const navigate = useNavigate();
    
    /**
     * Список элементов меню лейаута администрирования.
     */
    const MenuItems = [
        {
            label: 'Пользователи',
            key: '/administration/users',
        },
        {
            label: 'Журнал действий',
            key: '/administration/user-event-log',
        },
        {
            label: 'На главную',
            key: '/',
        },
    ];

    /**
     * Обработчик клика по меню.
     * @param itemKey Ключ элемента меню.
     */
    const onMenuItemClick = (itemKey: string) => {
        navigate(itemKey);
    };

    /**
     * Компонент профиля пользователя.
     */
    const profileMenu = userStore.isAuthorized && !userStore.isSuspiciousActivity ? <ProfileMenu /> : undefined;

    return (
        <PageLayout className="qpd-rstr-administration-layout"
            bodyStyles="qpd-rstr-administration-layout__body"
            footer={<Footer />}
            isLoading={loaderStore.loading}
            menuItems={MenuItems} 
            onLogoClick={() => navigate('/')}
            onMenuItemClick={onMenuItemClick}
            overlayIsShowing={overlayStore.isShowing}
            pageTitle={props.title}
            profileMenu={profileMenu}
        >
            {props.children}
        </PageLayout>
    )
}

/**
 * Название компонента, отображаемое при отладке.
 */
 AdministrationLayout.displayName = 'AdministrationLayout';

export default observer(AdministrationLayout);