import { DocumentValue } from '@qpd-reestr/registration-form/dist/components/RegistrationForm/RegistrationFormProps';
import { makeAutoObservable } from 'mobx';
import { AuthSystemName } from '../models';

/**
 * Хранилище состояния формы авторизации.
 */
export class RegistrationFormStore {
    /**
     * Идентификатор пользователя во внешней системе.
     */
    private _subjectId: string = '';

    /**
     * Имя.
     */
    private _firstName: string = '';

    /**
     * Признак активности поля "Имя".
     */
    private _firstNameDisabled: boolean = false;

    /**
     * Фамилия.
     */
    private _lastName: string = '';

    /**
     * Признак активности поля "Фамилия".
     */
    private _lastNameDisabled: boolean = false;

    /**
     * Отчество.
     */
    private _middleName: string | null = null;

    /**
     * Признак активности поля "Отчество".
     */
    private _middleNameDisabled: boolean = false;

    /**
     * Номер телефона.
     */
    private _phone: string = '';

    /**
     * Признак активности поля "Номер телефона".
     */
    private _phoneDisabled: boolean = false;

    /**
     * Пароль.
     */
    private _password: string = '';

    /**
     * Инн
     */
    private _inn: string | null = null;

    /**
     * признак блокировки поля инн
     */
    private _innDisabled: boolean = false;
    
    /**
     * Снилс
     */
    private _snils: string | null = null;

    /**
     * Признак блокировки поля снился
     */
    private _snilsDisabled: boolean = false;

    /**
     * Признак подтвержденной уз.
     */
    private _trusted: boolean = false;

    /**
     * Серия и номер паспорта.
     */
    private _document: DocumentValue = {
        value: '',
        isPassport: true,
    };

    /**
     * Признак активности поля "Серия и номер паспорта".
     */
    private _passportDisabled: boolean = false;

    /**
     * Адрес электронной почты.
     */
    private _email: string | null = null;

    /**
     * Идентификатор запроса на верификацию.
     */
    private _verificationRequestId: number = 0;

    /**
     * Тип аутентификации
     */
    private _authType: AuthSystemName | null = null;

    /**
     * Параметр state
     */
    private _state: string | null = null;

    /**
     * Параметр верификации паспорта (для ЦПГ)
     */
    private _passportVerification: string | null = null;

    /**
     * Конструктор.
     */
    constructor() {
        makeAutoObservable(this);
    }

    /**
     * Идентификатор пользователя во внешней системе.
     */
    public get subjectId() {
        return this._subjectId;
    }
    public set subjectId(value: string) {
        this._subjectId = value;
    }

    /**
     * Имя.
     */
    public get firstName() {
        return this._firstName;
    }
    public set firstName(value: string) {
        this._firstName = value;
    }

    /**
     * Признак активности поля "Имя".
     */
    public get firstNameDisabled() {
        return this._firstNameDisabled;
    }
    public set firstNameDisabled(value: boolean) {
        this._firstNameDisabled = value;
    }

    /**
     * Фаиилия.
     */
    public get lastName() {
        return this._lastName;
    }
    public set lastName(value: string) {
        this._lastName = value;
    }

    /**
     * Признак активности поля "Фамилия".
     */
    public get lastNameDisabled() {
        return this._lastNameDisabled;
    }
    public set lastNameDisabled(value: boolean) {
        this._lastNameDisabled = value;
    }

    /**
     * Отчество.
     */
    public get middleName() {
        return this._middleName;
    }
    public set middleName(value: string | null) {
        this._middleName = value;
    }

    /**
     * Признак активности поля "Отчество".
     */
    public get middleNameDisabled() {
        return this._middleNameDisabled;
    }
    public set middleNameDisabled(value: boolean) {
        this._middleNameDisabled = value;
    }

    /**
     * Номер телефона.
     */
    public get phone() {
        return this._phone;
    }
    public set phone(value: string) {
        this._phone = value;
    }

    /**
     * Признак активности поля "Номер телефона".
     */
    public get phoneDisabled() {
        return this._phoneDisabled;
    }
    public set phoneDisabled(value: boolean) {
        this._phoneDisabled = value;
    }

    /**
     * Пароль.
     */
    public get password() {
        return this._password;
    }
    public set password(value: string) {
        this._password = value;
    }

    /**
     * Признак подтвержденной УЗ.
     */
    public get trusted() {
        return this._trusted;
    }
    public set trusted(value: boolean) {
        this._trusted = value;
    }

    /**
     * Серия и номер паспорта.
     */
    public get document() {
        return this._document;
    }
    public set document(value: DocumentValue) {
        this._document = value;
    }

    /**
     * Признак активности поля "Серия и номер паспорта".
     */
    public get passportDisabled() {
        return this._passportDisabled;
    }
    public set passportDisabled(value: boolean) {
        this._passportDisabled = value;
    }

    /**
     * Адрес электронной почты.
     */
    public get email() {
        return this._email;
    }
    public set email(value: string | null) {
        this._email = value;
    }

    /**
     * инн
     */
    public get inn() {
        return this._inn;
    }
    public set inn(value: string | null) {
        this._inn = value;
    }

    /**
     * Признак активности поля "Номер телефона".
     */
    public get innDisabled() {
        return this._innDisabled;
    }
    public set innDisabled(value: boolean) {
        this._innDisabled = value;
    }

    /**
     * снилс
     */
    public get snils() {
        return this._snils;
    }
    public set snils(value: string | null) {
        this._snils = value;
    }

    /**
     * Признак активности поля "Номер телефона".
     */
    public get snilsDisabled() {
        return this._snilsDisabled;
    }
    public set snilsDisabled(value: boolean) {
        this._snilsDisabled = value;
    }


    /**
     * Тип аутентификации
     */
    public get authType() {
        return this._authType;
    }

    public set authType(value: AuthSystemName | null) {
        this._authType = value;
    }

    /**
     * параметр state
     */
    public get state() {
        return this._state;
    }

    public set state(value: string | null) {
        this._state = value;
    }

    /**
     * параметр верификации паспорта (для ЦПГ)
     */
    public get passportVerification() {
        return this._passportVerification;
    }

    public set passportVerification(value: string | null) {
        this._passportVerification = value;
    }

    /**
     * Идентификатор запроса на верификацию.
     */
    public get verificationRequestId(): number {
        return this._verificationRequestId;
    }
    public set verificationRequestId(value: number) {
        this._verificationRequestId = value;
    }

    /**
     * Метод очистки состояния.
     */
    public clear() {
        this._subjectId = '';
        this._firstName = '';
        this._firstNameDisabled = false;
        this._lastName = '';
        this._lastNameDisabled = false;
        this._middleName = null;
        this._middleNameDisabled = false;
        this._phone = '';
        this._phoneDisabled = false;
        this._password = '';
        this._document = {
            value: '',
            isPassport: true,
        };
        this._snils = null;
        this._inn = null;
        this._passportDisabled = false;
        this._trusted = false;
        this._email = null;
        this._state = null;

        this._verificationRequestId = 0;
    }
}
