import { notification } from '@qpd-reestr/ui-kit';
import { UserList, UserListItem, SortingParams } from '@qpd-reestr/user-list';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useServices } from '../../hooks';
import { showErrorNotification } from '../../utils';
import { UserListProps } from './UserListProps';
import { store } from '../../store';
import { UserSortField } from '../../models';

const pageSize = 20;

/**
 * Контейнерный компонент "Список профилей пользователей SSO".
 */
const UserListContainer: React.FC<UserListProps> = (props) => {
    const services = useServices();

    const [users, setUsers] = React.useState<UserListItem[]>([]);
    const [page, setPage] = React.useState<number>(1);
    const [sortingMethods, setSortingMethods] = React.useState<Record<string, UserSortField> | null>(null);
    const [totalPage, setTotalPage] = React.useState<number>();
    const [searchValue, setSearchValue] = React.useState<string>(' ');
    const [sortingParams, setSortingParams] = React.useState<SortingParams>({ sortColumn: '', sortDescending: false });
    const { setLoading } = store.loaderStore;

    React.useEffect(() => {
        (async () => {
            try {
                const sortingMethods = await services.userService.getAviableSortingsMethods();
                setSortingMethods(sortingMethods);
            } catch (error: any) {
                showErrorNotification(error);
            }
        })();
    }, []);

    React.useEffect(() => {
        (async () => {
            try {
                setLoading(true);
                const pagingUsers = await services.userService.getUsersWithPaging(
                    page,
                    pageSize,
                    searchValue,
                    sortingParams.sortDescending,
                    sortingParams.sortColumn as UserSortField,
                );
                setUsers(pagingUsers.items);
                setTotalPage(pagingUsers.totalItems);
                setLoading(false);
            } catch (error: any) {
                setLoading(false);
                showErrorNotification(error);
            }
        })();
    }, [page, searchValue, services.userService, sortingParams.sortDescending, sortingParams.sortColumn]);

    const navigate = useNavigate();

    const onBackClick = React.useCallback(() => {
        navigate('/');
    }, [navigate]);

    const onNavigateToProfileClick = React.useCallback(
        (profileId: number) => {
            navigate(
                `/profile/${profileId}?redirectUrl=${services.base64.encodeURIStringToBase64('/administration/users')}`,
            );
        },
        [navigate],
    );

    const onUpdateClick = React.useCallback(
        async (profileId: number) => {
            try {
                // Обновляем расширенную информацию о пользователе
                // и его список доступных систем
                await Promise.all([
                    services.userService.updateAvalibleSytemsByUserId(profileId),
                    services.userService.updateExtendedInfoByUserId(profileId),
                ]);

                notification.success('Расширенная информация о пользователе успешно обновлена');

                const userInfo = await services.userService.getUserInfoById(profileId);

                const updateUser: Partial<UserListItem> = {
                    id: profileId,
                    fullName: [userInfo.lastName, userInfo.firstName, userInfo.middleName].join(' '),
                    phone: userInfo.phone,
                    authSystems: userInfo.authSystems,
                };

                const updatedUsers = users.map((user) => (user.id === profileId ? { ...user, ...updateUser } : user));

                setUsers(updatedUsers);
            } catch (error: any) {
                showErrorNotification(error);
            }
        },
        [services.userService, users],
    );

    //изенение параметров сортировки
    const onChangeSortingParams = (sortingParams: SortingParams) => {
        setSortingParams(sortingParams);
        setPage(1);
    };

    const onSearchValueChanged = React.useCallback((searchValue: string) => {
        let queryText = searchValue || '';

        queryText = queryText.trim();

        if (queryText.length >= 1 && queryText.length < 3) {
            return;
        }

        setPage(1);
        setSearchValue(queryText);
    }, []);

    /**
     * Генерации отчета о согласиях
     * @param profileId идентификатор пользователя
     */
    const onGenerateConsentReportClick = async (profileId: number) => {
        try {
            const report = await services.userService.getConsentReportByUserId(profileId);

            await services.fileService.downloadFileWithBase64Content(
                `${profileId}_consent_report.pdf`,
                report.fileContents,
                report.contentType,
            );
        } catch (error: any) {
            showErrorNotification(error);
        }
    };

    /**
     * Сброс согласий всех пользователей.
     */
    const onAcceptResetConsents = React.useCallback(async () => {
        try {
            await services.consentService.resetConsents();
            notification.success('Все согласия успешно сброшены');
        } catch (error: any) {
            showErrorNotification(error);
        }
    }, [services.consentService]);

    return (
        <UserList
            onGenerateReportClick={onGenerateConsentReportClick}
            onResetConsents={onAcceptResetConsents}
            onBackClick={onBackClick}
            onChangePage={(page) => setPage(page)}
            onNavigateToProfileClick={onNavigateToProfileClick}
            onSearch={onSearchValueChanged}
            onUpdateClick={onUpdateClick}
            page={page}
            pageSize={pageSize}
            total={totalPage}
            users={users}
            className={props.className}
            showBackBtn={props.showBackBtn}
            backBtnText="На главную"
            sortingParams={sortingParams}
            onChangeSortingParams={onChangeSortingParams}
            sortingMethods={sortingMethods}
        />
    );
};

/**
 * Название компонента, используемое при отладке.
 */
UserListContainer.displayName = 'UserListContainer';

export default observer(UserListContainer);
