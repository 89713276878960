import { logger } from '../utils';
import axios from './axiosService';
import { RootService } from './rootService';

/**
 * Общий сервис для работы ссо
 */
export class CommonService {
    /**
     * Корневой Web Api сервис.
     */
    private readonly rootService: RootService;

    /**
     * Конструктор.
     * @param rootService корневой Web Api сервис.
     */
    constructor(rootService: RootService) {
        this.rootService = rootService;
    }

    /**
     * Получить ссылку на страницу настройки криптопро
     * @returns ссылка на страницу настройки криптопро
     */
    public async getUkepSetupLink(): Promise<string> {
        try {

            return Promise.resolve(
                (await axios.get<string>('ukep/get_ukep_setup_link')).data,
            );
        } catch (error) {
            logger.error(error);
            return Promise.reject(error);
        }
    }
}
