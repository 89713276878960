import React, { useCallback } from 'react';
import { ForgotPasswordForm } from '@qpd-reestr/forgot-password-form';
import { notification } from '@qpd-reestr/ui-kit';
import { observer } from 'mobx-react-lite';
import { useNavigate } from 'react-router-dom';
import { useServices, useStore } from '../../hooks';
import { ErrorCode, VerifactionStatus } from '../../models';
import { showErrorNotification } from '../../utils';

/**
 * Контейнерный компонент "Форма восстановления пароля".
 */
const PasswordRecoveryFormContainer: React.FC = () => {
    const navigate = useNavigate();
    const services = useServices();
    const store = useStore();

    const onBackHandler = () => {
        navigate(-1);
    };

    const onSetPasswordFormSubmitHandler = useCallback(
        async (password: string) => {
            try {
                await services.userService.recoveryPassword({
                    phone: store.setPasswordFormStore.phone,
                    password,
                    verificationRequestId: store.setPasswordFormStore.verificationRequestId,
                    verificationCode: store.setPasswordFormStore.verificationCode,
                });

                store.passwordRecoveryFormStore.clear();
                store.setPasswordFormStore.clear();
                store.verificationFormStore.clear();

                navigate('/');
            } catch (error: any) {
                showErrorNotification(error);
                if (error.code === ErrorCode.UserNotFound || error.code === ErrorCode.UserIsNotActive) {
                    navigate('/');
                }
            }
        },
        [
            navigate,
            services.userService,
            store.passwordRecoveryFormStore,
            store.setPasswordFormStore,
            store.verificationFormStore,
        ],
    );

    /**
     * Обработчик события отправки формы ввода кода верификации.
     */
    const onVerificationFormSubmitHandler = useCallback(
        async (verificationCode: string) => {
            store.setPasswordFormStore.phone = store.verificationFormStore.phone;
            store.setPasswordFormStore.verificationCode = verificationCode;
            store.setPasswordFormStore.verificationRequestId = store.verificationFormStore.verificationRequestId;
            store.setPasswordFormStore.onSubmitHandler = onSetPasswordFormSubmitHandler;

            try {
                const response = await services.verificationService.checkVerificationCodeRecoveryPassword({
                    verificationRequestId: store.setPasswordFormStore.verificationRequestId,
                    verificationCode: store.setPasswordFormStore.verificationCode,
                    phone: store.verificationFormStore.phone
                });

                if (response?.isCorrect) {
                    navigate('../set-password');
                } else {
                    notification.error('Введен некорректный код верификации');
                }
            } catch (error: any) {
                showErrorNotification(error);
            }
        },
        [
            navigate,
            onSetPasswordFormSubmitHandler,
            services.verificationService,
            store.setPasswordFormStore,
            store.verificationFormStore.phone,
            store.verificationFormStore.verificationRequestId,
        ],
    );

    /**
     * Обработчик события отправки формы ввода кода верификации в случаи ошибки создания кода верификации.
     */
    const onVerificationFormSubmitHandlerEmitation = useCallback(() => {
        notification.error('Введен некорректный код верификации');
    }, [notification]);

    /**
     * Обработчик события отправки формы восстановления пароля.
     * @param phone номер телефона.
     */
    const onSubmitHandler = useCallback(
        async (phone: string) => {
            try {
                const dto = await services.verificationService.createPasswordRecoveryVerificationRequest({
                    action: VerifactionStatus.recoveryPassword,
                    phone,
                });

                store.verificationFormStore.action = VerifactionStatus.recoveryPassword;
                store.verificationFormStore.phone = phone;
                store.verificationFormStore.verificationRequestId = dto.verificationRequestId;
                store.verificationFormStore.onSubmitHandler = onVerificationFormSubmitHandler;
                store.verificationFormStore.onBackHandler = () => navigate(-1);
                store.verificationFormStore.onSendCodeHandler = async () => {
                    try {
                        const dto = await services.verificationService.createPasswordRecoveryVerificationRequest({
                            action: VerifactionStatus.recoveryPassword,
                            phone: store.verificationFormStore.phone,
                        });
                        store.verificationFormStore.verificationRequestId = dto.verificationRequestId;
                    } catch (error: any) {
                        showErrorNotification(error);
                    }
                };

                navigate('../verification');
            } catch (error: any) {
                if (error.code === ErrorCode.VerificationCodeIsInvalid) {
                    store.verificationFormStore.action = VerifactionStatus.recoveryPassword;
                    store.verificationFormStore.phone = phone;
                    store.verificationFormStore.onSubmitHandler = onVerificationFormSubmitHandlerEmitation;
                    store.verificationFormStore.onBackHandler = () => navigate(-1);
                    store.verificationFormStore.verificationRequestId = -1;

                    navigate('../verification');
                } else {
                    showErrorNotification(error);
                }
            }
        },
        [navigate, onVerificationFormSubmitHandler, services.verificationService, store.verificationFormStore],
    );

    const initialValues = {
        phone: store.passwordRecoveryFormStore.phone,
    };

    return <ForgotPasswordForm initialValues={initialValues} onBack={onBackHandler} onSubmit={onSubmitHandler} />;
};

/**
 * Название компонента, используемое при отладке.
 */
PasswordRecoveryFormContainer.displayName = 'PasswordRecoveryFormContainer';

export default observer(PasswordRecoveryFormContainer);
